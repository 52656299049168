.png-logoseacc {
    width: 10.4px;
    height: 60.8px;
    border-radius: '4px 0px 0px 4px';
    display: 'flex';
    align-self: 'start';
    justify-content: 'start';
}

.ic {
    width: 30px;
}

.MuiBox-root.css-0 {
    margin-top: 80px;
}

.div1bgcolor {
    background-color: #fff;
}
.right {
    width: 772.888px;
    height: 479.03px;
    line-height: normal;
    /* 130px - 20% */
}

.btn-text-active {
    color: #000;
    font-family: "Playfair Display";
    font-size: 18px;
    margin-left: 15px;

}

.btn-text-inactive {
    color: #000;
    font-family: "Playfair Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

margin-left: 15px;
    margin-left: 15px;
}


/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1209px) and (max-width: 5500px) {

    .ic {
        width: 25px;
    }
    .png-logoseacc {
        width: 15.4px;
        height: 75.24px;
        height: 48.64px;
    }


    .btn-text-active {
        color: #000;
        font-family: "Playfair Display";
        font-size: 18px;
        text-align: start;
        position: absolute;
        margin-left: 20px;
    
    }

    .btn-text-inactive {
        color: #000;
        font-family: "Playfair Display";
        font-size: 18px;
    }

}
@media screen and (max-width: 1208px) {
    .MuiBox-root {
        display: flex;
        flex-direction: column;
        top: 20px;
    }

    .ic {
        width: 25px;
    }
    .png-logoseacc {
        width: 15.4px;
        height: 75.24px;
        height: 48.64px;
    }


    .btn-text-active {
        color: #000;
        font-family: "Playfair Display";
        font-size: 18px;
        text-align: start;
        position: absolute;
        margin-left: 20px;
    
    }

    .btn-text-inactive {
        color: #000;
        font-family: "Playfair Display";
        font-size: 18px;
    }
}
/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {

    .ic {
        width: 20px;
    }
    .png-logoseacc {
        width: 7.28px;
        /* 10.4px - 30% */
    }
    .btn:hover {
        background-color: #f0f0f0;
    }

    .svg2img {
        margin-top: 3px;
        width: 20px;
    }

    .btn-text-active {
        color: #000;
        font-family: "Poppins";
        font-size: 18px;
        line-height: normal;
        position: absolute;
        margin-left: 10px;
    
    }

    .btn-text-inactive {
        color: #000;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
        
    }
    }

/* Tablets (481px to 768px) */
@media (min-width: 600px) and (max-width: 768px) {

    .ic {
        width: 22px;
    }
    .png-logoseacc {
        width: 4.16px;
        /* 5.2px - 20% */
    }

    .right {
        width: 300px;
        /* 309.1552px - 20% */
        height: 153.2896px;
        width: 398.776px;
        height: 585.206px;
        /* 191.612px - 20% */
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-items: start;
        flex-direction: column-reverse;
        fill: #FFF;
        stroke: #D4D1CD;
        border-radius: 5px;
        /* No change */
        border: 0.4px solid #ccc;
        /* Reduced border */
    }
    .right1 {
        width: 398.776px;
        height: 585.206px;
        /* 191.612px - 20% */
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
        align-items: start;
        flex-direction: column-reverse;
        fill: #FFF;
        stroke: #D4D1CD;
        border-radius: 5px;
        /* No change */
        border: 0.4px solid #ccc;
        /* Reduced border */
    }
    .MuiBox-root.css-0 {
        margin-top: 30px;
    }
    .dd {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        width: 64px;
        width: 100px;
        /* 80px - 20% */
    }

    .logo-icon-1 {
        margin-bottom: 4px;
        margin-left: 5%;
        /* 5px - 20% */
        width: 20.098px;
        /* 25.1224px - 20% */
        height: 20.098px;
        width: 54.199px;
        height: 54.199px;
        flex-shrink: 0;
        /* 25.1224px - 20% */
        align-self: flex-start;
        display: flex;
    }

    .h3ofdiv {
        width: 100%;
        color: #000;
        font-family: "Playfair Display";
        font-size: 10.88px;
        /* 13.6px - 20% */
        font-size: 29.34px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 8px;
        /* 10px - 20% */
        text-align: start;
        margin-left: 5%;
        margin-bottom: 16px;
        /* 20px - 20% */
    }

    .p3 {
        width: 107.32064px;
        /* 134.1508px - 20% */
        height: 41.6px;
        /* 52px - 20% */
        flex-shrink: 0;
        width: 335.997px;
        height: 88px;
        color: #000;
        text-align: start;
        font-family: Poppins;
        font-size: 5.12px;
        /* 6.4px - 20% */
        font-size: 13.807px;
        font-style: normal;
        font-weight: 300;
        line-height: 10.88px;
        line-height: normal;
        /* 13.6px - 20% */
        margin-left: 8px;
        margin-left: 5%;
        /* 10px - 20% */
    }

    .btn3 {
        width: auto;
        width: 150px;
        color: #00A539;
        font-family: Satoshi;
        font-size: 4.792px;
        /* 5.99px - 20% */
        font-size: 12.93px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.96px;
        /* 1.2px - 20% */
        letter-spacing: 2.586px;
        text-transform: uppercase;
        margin-top: 12.8px;
        /* 16px - 20% */
        margin-left: 8px;
        /* 10px - 20% */
        display: flex;
        margin-bottom: 9.6px;
        /* 12px - 20% */
        align-self: flex-start;
        cursor: pointer;
        margin-left: 50px;
   
    }

    .doc {
        width: 99.30432px;
        /* 124.1304px - 20% */
        height: 137.2384px;
        width: 380.022px;
        height: 256.047px;
        flex-shrink: 0;
        /* 171.548px - 20% */
        border: none;
        border-radius: 5px;
    }

    .btn-simple {
        width: 230px;
        /* 172.3104px - 20% */
        height: 24.0768px;
        width: 398.78px;
        height: 75px;
        /* 30.096px - 20% */
        flex-shrink: 0;
        display: flex;
        background-color: #f0f0f0;
        /* No change */
    }

    .svg2img {
        margin-top: 3px;
        width: 30px;
        margin-left: 30px;
    }

    .btn-text-active {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        line-height: normal;
        position: absolute;
        margin-left: 10px;
    
    }

    .btn-text-inactive {
        color: #000;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 10px;
    }

}
/* Mobile Devices (up to 480px) */
@media (max-width: 600px) {

    .ic {
        width: 22px;
    }
    .png-logoseacc {
        width: 4.16px;
        /* 5.2px - 20% */
    }

    .right {
        width: 300px;
        /* 309.1552px - 20% */
        height: 153.2896px;
        width: 398.776px;
        height: 585.206px;
        /* 191.612px - 20% */
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-items: start;
        flex-direction: column-reverse;
        fill: #FFF;
        stroke: #D4D1CD;
        border-radius: 5px;
        /* No change */
        border: 0.4px solid #ccc;
        /* Reduced border */
    }
    .right1 {
        width: 398.776px;
        height: 585.206px;
        /* 191.612px - 20% */
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
        align-items: start;
        flex-direction: column-reverse;
        fill: #FFF;
        stroke: #D4D1CD;
        border-radius: 5px;
        /* No change */
        border: 0.4px solid #ccc;
        /* Reduced border */
    }
    .MuiBox-root.css-0 {
        margin-top: 30px;
    }
    .dd {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        width: 64px;
        width: 100px;
        /* 80px - 20% */
    }

    .logo-icon-1 {
        margin-bottom: 4px;
        margin-left: 5%;
        /* 5px - 20% */
        width: 20.098px;
        /* 25.1224px - 20% */
        height: 20.098px;
        width: 54.199px;
        height: 54.199px;
        flex-shrink: 0;
        /* 25.1224px - 20% */
        align-self: flex-start;
        display: flex;
    }

    .h3ofdiv {
        width: 100%;
        color: #000;
        font-family: "Playfair Display";
        font-size: 10.88px;
        /* 13.6px - 20% */
        font-size: 29.34px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 8px;
        /* 10px - 20% */
        text-align: start;
        margin-left: 5%;
        margin-bottom: 16px;
        /* 20px - 20% */
    }

    .p3 {
        width: 107.32064px;
        /* 134.1508px - 20% */
        height: 41.6px;
        /* 52px - 20% */
        flex-shrink: 0;
        width: 335.997px;
        height: 88px;
        color: #000;
        text-align: start;
        font-family: Poppins;
        font-size: 5.12px;
        /* 6.4px - 20% */
        font-size: 13.807px;
        font-style: normal;
        font-weight: 300;
        line-height: 10.88px;
        line-height: normal;
        /* 13.6px - 20% */
        margin-left: 8px;
        margin-left: 5%;
        /* 10px - 20% */
    }

    .btn3 {
        width: auto;
        width: 150px;
        color: #00A539;
        font-family: Satoshi;
        font-size: 4.792px;
        /* 5.99px - 20% */
        font-size: 12.93px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.96px;
        /* 1.2px - 20% */
        letter-spacing: 2.586px;
        text-transform: uppercase;
        margin-top: 12.8px;
        /* 16px - 20% */
        margin-left: 8px;
        /* 10px - 20% */
        display: flex;
        margin-bottom: 9.6px;
        /* 12px - 20% */
        align-self: flex-start;
        cursor: pointer;
        margin-left: 50px;
   
    }

    .doc {
        width: 99.30432px;
        /* 124.1304px - 20% */
        height: 137.2384px;
        width: 380.022px;
        height: 256.047px;
        flex-shrink: 0;
        /* 171.548px - 20% */
        border: none;
        border-radius: 5px;
    }

    .btn-simple {
        width: 230px;
        /* 172.3104px - 20% */
        height: 24.0768px;
        width: 398.78px;
        height: 75px;
        /* 30.096px - 20% */
        flex-shrink: 0;
        display: flex;
        background-color: #f0f0f0;
        /* No change */
    }

    .svg2img {
        margin-top: 3px;
        width: 30px;
        margin-left: 30px;
    }

    .btn-text-active {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        line-height: normal;
        position: absolute;
        margin-left: 10px;
    
    }

    .btn-text-inactive {
        color: #000;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 10px;
    }

}