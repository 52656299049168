@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@font-face {
  font-family: 'Brush Script MT';
  src: url('./fonts/BrushScript/BrushScriptStd.otf') format('opentype'); /* Change path accordingly */
}

body {
  font-family: 'Brush Script MT', cursive; /* Fallback to cursive */
}

.about4btn{
  display: none;
}
.about4btn2{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 20px;
}
.left {
  display: inline;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btnofselectofsec2 {
  width: 430.776px;
  height: 75.24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-left: 0px;
  border-radius: 5px 0px 0px 5px;
  background-color: #fff;
  cursor: pointer;
}

.btn-simple {
  width: 430.776px;
  height: 75.24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-left: 0px;
  background-color: transparent;
  cursor: pointer;
}

.btn-simple {
  color: #000;
  font-family: "Playfair Display";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-text-active {
  color: #000;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  margin-left: 10px;

}

.btn-text-inactive {
  color: #000;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.btn-icon {
  margin-right: -10px;
}

.btn-textofdiv1file {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 130px;
}

.btn-arrow {
  margin-right: 40px;
}

.btn:hover {
  background-color: #f0f0f0;
}

.section8bg {
  background-image: url('./images/sec3bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.firstdivofSection8 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);

}

.firstSubdivofSection8 {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Aligns items to the left */
  justify-content: left;
}

.oneTextFieldsection8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.h5ofsection8 {
  text-align: start;
}

.h1ofsection8medask {
  color: #28156D;
}

.divh1ofsection8 {
  margin: 30px 0;
}

.right {
  width: 772.888px;
  height: 479.03px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  fill: #FFF;
  stroke: #D4D1CD;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.logo {
  margin-bottom: 10px;
  margin-left: 20px;
  width: 62.806px;
  height: 62.806px;
  flex-shrink: 0;
  fill: #FFF;

}

.h3ofdiv {
  color: #000;
  font-family: "Playfair Display";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  margin-bottom: 50px;
}

.p3 {
  width: 335.377px;
  height: 130px;
  flex-shrink: 0;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  margin-left: 20px;
}

.btn3 {
  color: #00A539;
  font-family: Satoshi;
  font-size: 14.984px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 2.997px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  margin-bottom: 30px;
}

.doc {
  width: 310.326px;
  height: 428.87px;
  border: none;
  border-radius: 10px;
  background: url('./images/doctor.png') lightgray 40% / cover no-repeat;
}

.MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.css-1qm1lh {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.css-19kzrtu {
  padding: 0px;
}

.MuiBox-root.css-19kzrtu {
  padding: 0%;
}

#tab-0.btn {
  padding: 0%;
}

#tab-1.btn {
  padding: 0%;
}

#tab-2.btn {
  padding: 0%;
}

#tab-3.btn {
  padding: 0%;
}

.formbglogo1{
  width: 100%;
  height: 40%;
  background-image: url('./images/formlogo.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
  opacity: 0.05;
  top: 15%;
  left: -2%;

}

@media (min-width: 1600px) {

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1600px;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
}
.h1ofhead2 {
  margin-top: 20px;
  color: #28156D !important;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 45px !important;
  font-weight: 400 !important;
  line-height: normal;
  margin-bottom: 20px;
}
.h2ofhead2 {
  margin-top: 20px;
  color: #000000 !important;
  text-align: center;
  font-family: 'playfair display';
  font-size: 45px !important;
  font-weight: 400 !important;
  line-height: normal;
  margin-bottom: 20px;
}

.ibrtitle1 {
  color: #000;
  text-align: center;
  font-size: 55px !important;
  font-style: normal;
  font-weight: 400 !important;
  font-family: 'Playfair Display';
  line-height: normal;
}

.subtitle {
  color: #000;
  text-align: center;
  font-size: 55px !important;
  font-style: normal;
  font-weight: 400 !important;
  font-family: 'Playfair Display';
  line-height: normal;
}

.ibrstart {
  text-align: start;
}

.App {
  text-align: center;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Makes sure the footer stays at the bottom */
}

.App-content {
  flex: 1;
  /* Ensures the content area grows to fill the space between the navbar and footer */
  padding: 20px;
  /* You can adjust this padding */
}

footer {
  margin-top: auto;
  /* Pushes the footer to the bottom of the page */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 50px;
  align-self: flex-start;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

nav {
  display: flex;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}

nav ul li {
  margin: 0 0px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

nav ul li a:hover {
  text-decoration: underline;
}

.Bgofallwebvector {
  background-image: url("../src/images/secondSecbgHome1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 1381px;
  display: flow-root;
  margin-top: -10%;
}

@media screen and (max-width: 1560px) {
  .Bgofallwebvector {
    background-image: url("../src/images/secondSecbgHome1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 1300px;
    display: flow-root;
    margin-top: -10%;
  }
}

@media screen and (max-width: 1208px) {
  .Bgofallwebvector {
    background-image: url("../src/images/secondSecbgHome1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 1450px;
    display: flow-root;
    margin-top: -10%;
  }

  .subtitle {
    color: #000;
    text-align: center;
    font-family: "Playfair Display" !important;
    font-size: 25px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }
}

@media screen and (max-width: 808px) {
  .Bgofallwebvector {
    background-image: url("../src/images/Section2bgMob.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 1359px;
    display: flow-root;
    margin-top: -10%;
  }

  .section5bgimg {
    background-image: url('../src/images/section5bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    /* margin-top: 820px; */
  }
}

@media screen and (max-width: 600px) {
  .section5bgimg {
    /* background-image: url('../src/images/section5bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
  }
}

@media screen and (min-width: 1600px) {
  .Bgofallwebvector {
    background-image: url('../src/images/secondSecbgHome1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 1381px;
    display: flow-root;
    margin-top: -10%;
  }
}

.heightofallcontainer {
  height: 1189.625px;
}

.section5bgimg {
  /* background-image: url('../src/images/section5bg.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 1049.625px;
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .section5bgimg {
    /* background-image: url('../src/images/section5bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
  }
}

.box12 {
  width: 1280px;
  height: 91px;
  flex-shrink: 0;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.10);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;

}

.center {
  display: flex;
  justify-content: center;
  margin-top: 125px;
  align-items: center;
}

.bottomSec4smallscreen {
  margin-top: 150px;
}

.text-boxsmallscreen {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px; */
  justify-content: center;
  display: none;
  /* width: 100px; */
  align-items: center;

}

.box13 {
  width: 270px;
  height: 91px;
  flex-shrink: 0;
  background-color: #29166F;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h12 {
  color: #FFF;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-box {
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: 'poppins';
  width: 800px;
}

.btn-g {
  width: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
}

.btn-box-1 {
  width: 37px;
  height: 37px;
  flex-shrink: 0;
  background-color: #00AA14;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-boxsmallscreendiv {
  display: none;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px;
  width: 90%;
  height: 320px;
}

.btn-box-2 {
  width: 37px;
  height: 37px;
  flex-shrink: 0;
  background-color: #29166F;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.m1 {
  rotate: 90%;
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .box12 {
    width: 896px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 7px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .center {
    display: flex;
    justify-content: center;
    margin-top: 8%;
    align-items: center;
  }

  .box13 {
    width: 189px;
    height: 69px;
    flex-shrink: 0;
    background-color: #29166F;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h12 {
    color: #FFF;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 19px;
    font-style: normal;
    font-weight: 420;
    line-height: normal;
  }

  .text-box {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 560px;
  }

  .btn-g {
    width: 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 35px;
    gap: 5px;
  }

  .btn-box-1 {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-box-2 {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    background-color: #29166F;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


}

@media (min-width: 769px) and (max-width: 1024px) {
  .box12 {
    width: 627px;
    height: 43px;
    flex-shrink: 0;
    border-radius: 7px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .center {
    display: flex;
    margin-top: 8%;
    justify-content: center;
    align-items: center;
  }

  .box13 {
    width: 138px;
    height: 47px;
    flex-shrink: 0;
    background-color: #29166F;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h12 {
    color: #FFF;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .text-box {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 390px;
  }

  .btn-g {
    width: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
    gap: 5px;
  }

  .btn-box-1 {
    width: 19px;
    height: 19px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-box-2 {
    width: 19px;
    height: 19px;
    flex-shrink: 0;
    background-color: #29166F;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-box p {
    font-size: 12px;
  }



}

@media (min-width: 601px) and (max-width: 768px) {
  .box12 {
    width: 438.9px;
    /* 627px - 30% */
    height: 30.1px;
    /* 43px - 30% */
    flex-shrink: 0;
    border-radius: 7px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8%;

  }

  .box13 {
    width: 96.6px;
    /* 138px - 30% */
    height: 32.9px;
    /* 47px - 30% */
    flex-shrink: 0;
    background-color: #29166F;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .h12 {
    color: #FFF;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 9.1px;
    /* 13px - 30% */
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .text-box {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 273px;
    /* 390px - 30% */
  }

  .btn-g {
    width: 28px;
    /* 40px - 30% */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
    gap: 5px;
  }

  .btn-box-1 {
    width: 13.3px;
    /* 19px - 30% */
    height: 13.3px;
    /* 19px - 30% */
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-box-2 {
    width: 13.3px;
    /* 19px - 30% */
    height: 13.3px;
    /* 19px - 30% */
    flex-shrink: 0;
    background-color: #29166F;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-box p {
    font-size: 8.4px;
    /* 12px - 30% */
  }

}

@media (max-width: 600px) {
  .box12 {
    width: 309.61px;
    /* 338.45px - 10% */
    height: 25.96px;
    /* 23.29px - 10% */
    flex-shrink: 0;
    border-radius: 7px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .center {
    display: none;
    justify-content: center;
    align-items: center;
    margin-top: 8%;
    margin-bottom: 17%;


  }

  .box13 {
    width: 72.87px;
    /* 74.30px - 10% */
    height: 26.76px;
    /* 25.29px - 10% */
    flex-shrink: 0;
    background-color: #29166F;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .firstbtnofbottomSec4smallscreen {
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 16px 18px;
    border-radius: 8px;
    font-weight: bold;
    margin: 10px;
    width: 380.46px;
    height: 87.22px;
    font-family: 'poppins';
    background-color: #29166F;
    cursor: pointer;
    content: fit-content;
  }

  .btn-textofsec4bottomsmallscreen {
    color: #fff;
    font-family: 'Poppins';
    font-size: 17px;
    font-style: normal;
    text-align: center;
    /* display: flex; */
    align-items: center;
    gap: 10px;
    font-weight: bold;
    line-height: normal;
  }

  .h12 {
    color: #FFF;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 6.33px;
    /* 7.03px - 10% */
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .bottomSec4smallscreen {
    margin-top: 150px;
    justify-content: center;
    display: flex;
  }

  .text-box {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 190.00px;
    /* 211.12px - 10% */
  }

  .text-boxsmallscreendiv {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px;
    width: 90%;
    height: 320px;
  }

  .text-boxsmallscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: inherit; */
    margin-top: 35px;
    font-size: 18px;
    line-height: 33px;
    font-family: 'Poppins';
    font-weight: 500;
    width: initial;

  }

  .btn-g {
    width: 19.50px;
    /* 21.67px - 10% */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
    gap: 36px;
    margin-top: 24px;
  }

  .btn-box-1 {
    width: 20px;
    /* 10.24px - 10% */
    height: 10px;
    /* 10.24px - 10% */
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgnonoe {
    display: none;
  }

  .imgnonoesmallscreen {
    display: flex;
  }

  .btn-box-2 {
    width: 15px;
    /* 10.24px - 10% */
    height: 10px;
    flex-shrink: 0;
    background-color: #29166F;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-box p {
    font-size: 6.85px;
    /* 6.50px - 10% */
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-box {
    transition: transform 0.5s ease-in-out;
    /* Transition for the text sliding effect */
  }

  .slide-left {
    transform: translateX(-300%);
    /* Move the text to the left */
    opacity: 0.3;
  }


}

/* Mobile Devices (up to 480px) */
@media (max-width: 429px) {
  .firstbtnofbottomSec4smallscreen {
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 16px 18px;
    border-radius: 8px;
    font-weight: bold;
    margin: 10px;
    width: 100%;
    height: 87.22px;
    font-family: 'poppins';
    background-color: #29166F;
    cursor: pointer;
    content: fit-content;
  }

  .text-boxsmallscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: inherit; */
    margin-top: 35px;
    font-size: 18px;
    line-height: 33px;
    font-family: 'Poppins';
    font-weight: 500;
    width: 300px;
  }

  .text-boxsmallscreen p {
    width: 300px !important;
  }

}

.h1ofhead1 {
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script MT' !important;
  font-size: 45px;
  margin-top: 10px;
  margin-bottom: 150px;
  /* font-style: normal; */
  font-weight: 400;
  line-height: normal;

}

.h2ofhead1 {
  color: #000;
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

@media (min-width: 1441px) {
  .h1ofhead1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 45.5px;
    /* Reduced by 10% */
    font-style: normal;
    margin-top: 150px;
    margin-bottom: 10px;
    font-weight: 400;
    line-height: normal;
  }

  .h2ofhead1 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display", serif;
    font-size: 49.5px;
    /* Reduced by 10% */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .h1ofhead1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 45px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 400;
    margin-top: 10%;
    margin-bottom: 10px;
    line-height: normal;
  }

  .h2ofhead1 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display", serif;
    font-size: 45px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .h1ofhead1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 31.68px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: normal;
  }

  .h2ofhead1 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display", serif;
    font-size: 31.68px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .h1ofhead1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 25.344px;
    /* Reduced by 20% */
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .h2ofhead1 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display", serif;
    font-size: 25.344px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .h1ofhead1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 20.2752px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: normal;
  }

  .h2ofhead1 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display", serif;
    font-size: 20.2752px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .headofsection3 {
    margin-top: 80px;
  }

}

.png-logoseacc {
  width: 10.4px;
  height: 75.24px;
  border-radius: '4px 0px 0px 4px';
  display: 'flex';
  align-self: 'start';
  justify-content: 'start';
}

.ic {
  width: 30px;
}

.MuiBox-root.css-0 {
  margin-top: 80px;
}

.div1bgcolor {
  background-color: #fff;
}

.right {
  width: 772.888px;
  height: 479.03px;
  /* 479.03px - 20% */
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  fill: #FFF;
  stroke: #D4D1CD;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  /* 200px - 20% */
}

.logo-icon-1 {
  margin-bottom: 10px;
  width: 50.2448px;
  /* 62.806px - 20% */
  height: 50.2448px;
  /* 62.806px - 20% */
  align-self: flex-start;
  display: flex;
  fill: #FFF;
  margin-left: 20px;
}

.h3ofdiv {
  color: #000;
  font-family: "Playfair Display";
  font-size: 27.2px;
  /* 34px - 20% */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  text-align: start;
  margin-bottom: 40px;
  /* 50px - 20% */
}

.p3 {
  width: 268.3016px;
  /* 335.377px - 20% */
  height: 104px;
  /* 130px - 20% */
  flex-shrink: 0;
  color: #000;
  text-align: start;
  font-family: Poppins;
  font-size: 12.8px;
  /* 16px - 20% */
  font-style: normal;
  font-weight: 300;
  line-height: 27.2px;
  /* 34px - 20% */
  margin-left: 20px;
}

.btn3 {
  width: auto;
  color: #00A539;
  font-family: Satoshi;
  font-size: 11.9872px;
  /* 14.984px - 20% */
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 2.3976px;
  /* 2.997px - 20% */
  text-transform: uppercase;
  margin-top: 32px;
  /* 40px - 20% */
  margin-left: 20px;
  display: flex;
  margin-bottom: 24px;
  /* 30px - 20% */
  align-self: flex-start;
  cursor: pointer;
}

.doc {
  width: 248.2608px;
  /* 310.326px - 20% */
  height: 343.096px;
  /* 428.87px - 20% */
  border: none;
  border-radius: 10px;
  background: url('./images/doctor.png') lightgray 40% / cover no-repeat;
}

.MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.css-1qm1lh {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.css-19kzrtu {
  padding: 0px;
}

.MuiBox-root.css-19kzrtu {
  padding: 0%;
}

#tab-0 {
  padding: 0%;
}

#tab-1 {
  padding: 0%;
}

#tab-2 {
  padding: 0%;
}

#tab-3 {
  padding: 0%;
}

* {
  box-sizing: border-box;
}

.left {
  display: inline;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

}

.btnofselectofsec2 {
  width: 430.776px;
  height: 75.24px;
  flex-shrink: 0;
  /* 75.24px - 20% */
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-left: 0px;
  border-radius: 5px 0px 0px 5px;
  background-color: #fff;
  cursor: pointer;
}

.btn-simple {
  width: 430.776px;
  height: 75.24px;
  flex-shrink: 0;
  /* 75.24px - 20% */
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-left: 0px;
  background-color: transparent;
  cursor: pointer;
}

.btn-icon {
  margin-right: -8px;
  /* -10px - 20% */
}

.btn-textofdiv1file {
  color: #000;
  font-family: Poppins;
  font-size: 14.4px;
  /* 18px - 20% */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* 130px - 20% */
}

.btn-text-active {
  color: #000;
  font-family: "Playfair Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  margin-left: 15px;

}

.btn-text-inactive {
  color: #000;
  font-family: "Playfair Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-left: 15px;
}

.btn-arrow {
  margin-right: 32px;
  /* 40px - 20% */
}

.btn:hover {
  background-color: #f0f0f0;
}

.png-logoseacc {
  width: 8.32px;
  height: 48.64px;
}


/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1209px) and (max-width: 5500px) {
  .ic {
    width: 25px;
  }

  .png-logoseacc {
    width: 15.4px;
    height: 75.24px;
    border-radius: '4px 0px 0px 4px';
    display: 'flex';
    align-self: 'start';
    justify-content: 'start';
  }

  .right {
    width: 772.89px;
    /* 772.888px - 20% */
    height: 479.03px;
    /* 479.03px - 20% */
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    fill: #FFF;
    stroke: #D4D1CD;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .dd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 190px;
    /* 200px - 20% */
  }

  .logo-icon-1 {
    margin-bottom: 10px;
    width: 50.2448px;
    /* 62.806px - 20% */
    height: 50.2448px;
    /* 62.806px - 20% */
    align-self: flex-start;
    display: flex;
    fill: #FFF;
    margin-left: 20px;
  }

  .h3ofdiv {
    color: #000;
    font-family: "Playfair Display";
    font-size: 27.2px;
    /* 34px - 20% */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20px;
    text-align: start;
    margin-bottom: 40px;
    /* 50px - 20% */
  }

  .p3 {
    width: 268.3016px;
    /* 335.377px - 20% */
    height: 104px;
    /* 130px - 20% */
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: Poppins;
    font-size: 12.8px;
    /* 16px - 20% */
    font-style: normal;
    font-weight: 300;
    line-height: 27.2px;
    /* 34px - 20% */
    margin-left: 20px;
  }

  .btn3 {
    width: auto;
    color: #00A539;
    font-family: Satoshi;
    font-size: 11.9872px;
    /* 14.984px - 20% */
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 2.3976px;
    /* 2.997px - 20% */
    text-transform: uppercase;
    margin-top: 32px;
    /* 40px - 20% */
    margin-left: 20px;
    display: flex;
    margin-bottom: 24px;
    /* 30px - 20% */
    align-self: flex-start;
    cursor: pointer;
  }

  .doc {
    width: 248.2608px;
    /* 310.326px - 20% */
    height: 343.096px;
    /* 428.87px - 20% */
    border: none;
    border-radius: 10px;
    background: url('./images/doctor.png') lightgray 40% / cover no-repeat;
  }

  .MuiBox-root {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    top: 20px;
  }

  .css-1qm1lh {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .css-19kzrtu {
    padding: 0px;
  }

  .MuiBox-root.css-19kzrtu {
    padding: 0%;
  }

  #tab-0 {
    margin-top: 10px;
    padding: 0%;
  }

  #tab-1 {
    padding: 0%;
  }

  #tab-2 {
    padding: 0%;
  }

  #tab-3 {
    padding: 0%;
  }

  * {
    box-sizing: border-box;
  }

  .left {
    display: inline;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .btnofselectofsec2 {
    width: 430.78px;
    /* 430.776px - 20% */
    height: 75.24px;
    /* 75.24px - 20% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-left: 0px;
    border-radius: 5px 0px 0px 5px;
    background-color: #fff;
    cursor: pointer;

  }

  .btn-simple {
    width: 430.78px;
    /* 430.776px - 20% */
    height: 75.24px;
    /* 75.24px - 20% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-left: 0px;
    background-color: transparent;
    cursor: pointer;

  }

  .btn-icon {
    margin-right: -8px;
    /* -10px - 20% */
  }

  .btn-textofdiv1file {
    color: #000;
    font-family: Poppins;
    font-size: 14.4px;
    /* 18px - 20% */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    right: 100%;
    /* 130px - 20% */
  }

  .btn-arrow {
    margin-right: 32px;
    /* 40px - 20% */
  }

  .btn:hover {
    background-color: #f0f0f0;
  }

  .png-logoseacc {
    width: 8.32px;
    height: 48.64px;
  }

  .btn-text-active {
    color: #000;
    font-family: "Playfair Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 15px;
    text-align: start;
    position: absolute;
    margin-left: 20px;

  }

  .btn-text-inactive {
    color: #000;
    font-family: "Playfair Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    margin-left: 20px;
  }

}

@media screen and (max-width: 1208px) {
  .MuiBox-root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 20px;
  }

  .ic {
    width: 25px;
  }

  .png-logoseacc {
    width: 15.4px;
    height: 75.24px;
    border-radius: '4px 0px 0px 4px';
    display: 'flex';
    align-self: 'start';
    justify-content: 'start';
  }

  .right {
    width: 772.89px;
    /* 772.888px - 20% */
    height: 479.03px;
    /* 479.03px - 20% */
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    fill: #FFF;
    stroke: #D4D1CD;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .dd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    /* 200px - 20% */
  }

  .logo-icon-1 {
    margin-bottom: 10px;
    width: 50.2448px;
    /* 62.806px - 20% */
    height: 50.2448px;
    /* 62.806px - 20% */
    align-self: flex-start;
    display: flex;
    fill: #FFF;
    margin-left: 20px;
  }

  .h3ofdiv {
    color: #000;
    font-family: "Playfair Display";
    font-size: 27.2px;
    /* 34px - 20% */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 20px;
    text-align: start;
    margin-bottom: 40px;
    /* 50px - 20% */
  }

  .p3 {
    width: 268.3016px;
    /* 335.377px - 20% */
    height: 104px;
    /* 130px - 20% */
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: Poppins;
    font-size: 12.8px;
    /* 16px - 20% */
    font-style: normal;
    font-weight: 300;
    line-height: 27.2px;
    /* 34px - 20% */
    margin-left: 20px;
  }

  .btn3 {
    width: auto;
    color: #00A539;
    font-family: Satoshi;
    font-size: 11.9872px;
    /* 14.984px - 20% */
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 2.3976px;
    /* 2.997px - 20% */
    text-transform: uppercase;
    margin-top: 32px;
    /* 40px - 20% */
    margin-left: 20px;
    display: flex;
    margin-bottom: 24px;
    /* 30px - 20% */
    align-self: flex-start;
    cursor: pointer;
  }

  .doc {
    width: 248.2608px;
    /* 310.326px - 20% */
    height: 343.096px;
    /* 428.87px - 20% */
    border: none;
    border-radius: 10px;
    background: url('./images/doctor.png') lightgray 40% / cover no-repeat;
  }

  .css-1qm1lh {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .css-19kzrtu {
    padding: 0px;
  }

  .MuiBox-root.css-19kzrtu {
    padding: 0%;
  }

  #tab-0 {
    margin-top: 10px;
    padding: 0%;
  }

  #tab-1 {
    padding: 0%;
  }

  #tab-2 {
    padding: 0%;
  }

  #tab-3 {
    padding: 0%;
  }

  * {
    box-sizing: border-box;
  }

  .left {
    display: inline;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .btnofselectofsec2 {
    width: 430.78px;
    /* 430.776px - 20% */
    height: 75.24px;
    /* 75.24px - 20% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-left: 0px;
    border-radius: 5px 0px 0px 5px;
    background-color: #fff;
    cursor: pointer;

  }

  .btn-simple {
    width: 430.78px;
    /* 430.776px - 20% */
    height: 75.24px;
    /* 75.24px - 20% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-left: 0px;
    background-color: transparent;
    cursor: pointer;

  }

  .btn-icon {
    margin-right: -8px;
    /* -10px - 20% */
  }

  .btn-textofdiv1file {
    color: #000;
    font-family: Poppins;
    font-size: 14.4px;
    /* 18px - 20% */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    right: 100%;
    /* 130px - 20% */
  }

  .btn-arrow {
    margin-right: 32px;
    /* 40px - 20% */
  }

  .btn:hover {
    background-color: #f0f0f0;
  }

  .png-logoseacc {
    width: 8.32px;
    height: 48.64px;
  }

  .btn-text-active {
    color: #000;
    font-family: "Playfair Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 15px;
    text-align: start;
    position: absolute;
    margin-left: 20px;

  }

  .btn-text-inactive {
    color: #000;
    font-family: "Playfair Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    margin-left: 20px;
  }

}


/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .ic {
    width: 20px;
  }

  .png-logoseacc {
    width: 7.28px;
    /* 10.4px - 30% */
    height: 42.56px;
    /* 60.8px - 30% */
    border-radius: 2.8px 0px 0px 2.8px;
    display: flex;
    align-self: start;
    justify-content: start;
  }

  .right {
    width: 432.8173px;
    /* 618.3104px - 30% */
    height: 268.2568px;
    /* 383.224px - 30% */
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    fill: #FFF;
    stroke: #D4D1CD;
    border-radius: 7px;
    /* 10px - 30% */
    border: 0.7px solid #ccc;
    /* Reduced border */
  }

  .dd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 112px;
    /* 160px - 30% */
  }

  .logo-icon-1 {
    margin-bottom: 7px;
    /* 10px - 30% */
    width: 35.1714px;
    /* 50.2448px - 30% */
    height: 35.1714px;
    /* 50.2448px - 30% */
    align-self: flex-start;
    display: flex;
    fill: #FFF;
    margin-left: 14px;
    /* 20px - 30% */
  }

  .h3ofdiv {
    color: #000;
    font-family: "Playfair Display";
    font-size: 19.04px;
    /* 27.2px - 30% */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 14px;
    /* 20px - 30% */
    text-align: start;
    margin-bottom: 28px;
    /* 40px - 30% */
  }

  .p3 {
    width: 187.8111px;
    /* 268.3016px - 30% */
    height: 72.8px;
    /* 104px - 30% */
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: Poppins;
    font-size: 8.96px;
    /* 12.8px - 30% */
    font-style: normal;
    font-weight: 300;
    line-height: 19.04px;
    /* 27.2px - 30% */
    margin-left: 14px;
    /* 20px - 30% */
  }

  .btn3 {
    width: auto;
    color: #00A539;
    font-family: Satoshi;
    font-size: 8.39px;
    /* 11.9872px - 30% */
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1.68px;
    /* 2.3976px - 30% */
    text-transform: uppercase;
    margin-top: 22.4px;
    /* 32px - 30% */
    margin-left: 14px;
    /* 20px - 30% */
    display: flex;
    margin-bottom: 16.8px;
    /* 24px - 30% */
    align-self: flex-start;
    cursor: pointer;
  }

  .doc {
    width: 173.7826px;
    /* 248.2608px - 30% */
    height: 240.1672px;
    /* 343.096px - 30% */
    border: none;
    border-radius: 7px;
    /* 10px - 30% */
    background: url('./images/doctor.png') lightgray 40% / cover no-repeat;
  }

  .MuiBox-root, .css-1gm1lh {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .left {
    display: inline;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
  }

  .btnofselectofsec2 {
    width: 241.2346px;
    /* 344.6208px - 30% */
    height: 42.1344px;
    /* 60.192px - 30% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.7px solid #ccc;
    /* 1px - 30% */
    border-left: 0px;
    border-radius: 3.5px 0px 0px 3.5px;
    /* 5px - 30% */
    background-color: #fff;
    cursor: pointer;
  }

  .btn-simple {
    width: 241.2346px;
    /* 344.6208px - 30% */
    height: 42.1344px;
    /* 60.192px - 30% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-left: 0px;
    background-color: transparent;
    cursor: pointer;
  }

  .btn-icon {
    margin-right: -5.6px;
    /* -8px - 30% */
  }

  .btn-textofdiv1file {
    color: #000;
    font-family: Poppins;
    font-size: 10.08px;
    /* 14.4px - 30% */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    right: 100%;
    /* 104px - 30% */
  }

  .btn-arrow {
    margin-right: 22.4px;
    /* 32px - 30% */
  }

  .btn:hover {
    background-color: #f0f0f0;
  }

  .svg2img {
    margin-top: 3px;
    width: 20px;
  }

  .btn-text-active {
    color: #000;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    margin-left: 10px;

  }

  .btn-text-inactive {
    color: #000;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 600px) and (max-width: 768px) {
  .ic {
    width: 15px;
  }

  .png-logoseacc {
    width: 5.2px;
    /* 10.4px - 50% */
    height: 30.4px;
    /* 60.8px - 50% */
    border-radius: 2px 0px 0px 2px;
    display: flex;
    align-self: start;
    justify-content: start;
  }

  .right {
    width: 309.1552px;
    /* 618.3104px - 50% */
    height: 191.612px;
    /* 383.224px - 50% */
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    fill: #FFF;
    stroke: #D4D1CD;
    border-radius: 5px;
    /* 10px - 50% */
    border: 0.5px solid #ccc;
    /* Reduced border */
  }

  .dd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    /* 160px - 50% */
  }

  .logo-icon-1 {
    margin-bottom: 5px;
    /* 10px - 50% */
    width: 25.1224px;
    /* 50.2448px - 50% */
    height: 25.1224px;
    /* 50.2448px - 50% */
    align-self: flex-start;
    display: flex;
    fill: #FFF;
    margin-left: 10px;
    /* 20px - 50% */
  }

  .h3ofdiv {
    color: #000;
    font-family: "Playfair Display";
    font-size: 13.6px;
    /* 27.2px - 50% */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
    /* 20px - 50% */
    text-align: start;
    margin-bottom: 20px;
    /* 40px - 50% */
  }

  .p3 {
    width: 134.1508px;
    /* 268.3016px - 50% */
    height: 52px;
    /* 104px - 50% */
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: Poppins;
    font-size: 6.4px;
    /* 12.8px - 50% */
    font-style: normal;
    font-weight: 300;
    line-height: 13.6px;
    /* 27.2px - 50% */
    margin-left: 10px;
    /* 20px - 50% */
  }

  .btn3 {
    width: auto;
    color: #00A539;
    font-family: Satoshi;
    font-size: 5.99px;
    /* 11.9872px - 50% */
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1.2px;
    /* 2.3976px - 50% */
    text-transform: uppercase;
    margin-top: 16px;
    /* 32px - 50% */
    margin-left: 10px;
    /* 20px - 50% */
    display: flex;
    margin-bottom: 12px;
    /* 24px - 50% */
    align-self: flex-start;
    cursor: pointer;
  }

  .doc {
    width: 124.1304px;
    /* 248.2608px - 50% */
    height: 171.548px;
    /* 343.096px - 50% */
    border: none;
    border-radius: 5px;
    /* 10px - 50% */
    background: url('./images/doctor.png') lightgray 40% / cover no-repeat;
  }

  .MuiBox-root,
  .css-1gm1lh {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left {
    display: inline;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
  }

  .btnofselectofsec2 {
    width: 250px;
    /* 344.6208px - 50% */
    height: 30.096px;
    /* 60.192px - 50% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #ccc;
    /* 1px - 50% */
    border-left: 0px;
    border-radius: 2.5px 0px 0px 2.5px;
    /* 5px - 50% */
    background-color: #fff;
    cursor: pointer;
  }

  .btn-simple {
    width: 250px;
    /* 344.6208px - 50% */
    height: 30.096px;
    /* 60.192px - 50% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-left: 0px;
    background-color: transparent;
    cursor: pointer;
  }

  .btn-icon {
    margin-right: -4px;
    /* -8px - 50% */
  }

  .btn-textofdiv1file {
    color: #000;
    font-family: Poppins;
    font-size: 7.2px;
    /* 14.4px - 50% */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    right: 100%;
    /* 104px - 50% */
  }

  .btn-arrow {
    margin-right: 16px;
    /* 32px - 50% */
  }

  .btn:hover {
    background-color: #f0f0f0;
  }

  .svg2img {
    margin-top: 3px;
    width: 15px;
  }

  .btn-text-active {
    color: #000;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    margin-left: 10px;

  }

  .btn-text-inactive {
    color: #000;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
  }


}

/* Mobile Devices (up to 480px) */
@media (max-width: 600px) {
  .ic {
    width: 22px;
  }

  .png-logoseacc {
    width: 4.16px;
    /* 5.2px - 20% */
    height: 24.32px;
    /* 30.4px - 20% */
    border-radius: 2px 0px 0px 2px;
    display: flex;
    align-self: start;
    justify-content: start;
    display: none;
  }

  .right {
    width: 398.776px;
    height: 585.206px;
    /* 191.612px - 20% */
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: start;
    flex-direction: column-reverse;
    fill: #FFF;
    stroke: #D4D1CD;
    border-radius: 5px;
    /* No change */
    border: 0.4px solid #ccc;
    /* Reduced border */
  }

  .right1 {
    width: 398.776px;
    height: 585.206px;
    /* 191.612px - 20% */
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: start;
    flex-direction: column-reverse;
    fill: #FFF;
    stroke: #D4D1CD;
    border-radius: 5px;
    /* No change */
    border: 0.4px solid #ccc;
    /* Reduced border */
  }

  .MuiBox-root.css-0 {
    margin-top: 30px;
  }

  .dd {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    /* 80px - 20% */
  }

  .logo-icon-1 {
    margin-bottom: 4px;
    margin-left: 5%;
    /* 5px - 20% */
    width: 54.199px;
    height: 54.199px;
    flex-shrink: 0;
    /* 25.1224px - 20% */
    align-self: flex-start;
    display: flex;
    fill: #FFF;
    margin-left: 8px;
    /* 10px - 20% */
  }

  .h3ofdiv {
    width: 100%;
    color: #000;
    font-family: "Playfair Display";
    font-size: 29.34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
    margin-bottom: 16px;
    /* 20px - 20% */
  }

  .p3 {
    width: 335.997px;
    height: 88px;
    color: #000;
    font-family: Poppins;
    font-size: 13.807px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    /* 13.6px - 20% */
    margin-left: 5%;
    /* 10px - 20% */
  }

  .btn3 {
    width: 150px;
    color: #00A539;
    font-family: Satoshi;
    font-size: 12.93px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 2.586px;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 50px;

  }


  .MuiBox-root,
  .css-1gm1lh {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left {
    display: inline;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
  }

  .btnofselectofsec2 {
    width: 250px;
    /* 344.6208px - 50% */
    height: 30.096px;
    /* 60.192px - 50% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #ccc;
    /* 1px - 50% */
    border-left: 0px;
    border-radius: 2.5px 0px 0px 2.5px;
    /* 5px - 50% */
    background-color: #fff;
    cursor: pointer;
  }

  .btn-simple {
    width: 250px;
    /* 344.6208px - 50% */
    height: 30.096px;
    /* 60.192px - 50% */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-left: 0px;
    background-color: transparent;
    cursor: pointer;
  }

  .btn-icon {
    margin-right: -4px;
    /* -8px - 50% */
  }

  .btn-textofdiv1file {
    color: #000;
    font-family: Poppins;
    font-size: 7.2px;
    /* 14.4px - 50% */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    right: 100%;
    /* 104px - 50% */
  }

  .btn-arrow {
    margin-right: 16px;
    /* 32px - 50% */
  }

  .btn:hover {
    background-color: #f0f0f0;
  }

  .svg2img {
    margin-top: 3px;
    width: 15px;
  }

  .btn-text-active {
    color: #000;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    margin-left: 10px;

  }

  .btn-text-inactive {
    color: #000;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
  }

}
.brand-slider-container {
  text-align: center;
  padding: 40px 0;
}

/* Text container */
.text-container {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 125px;
}

/* Styles for the heading "Area Of Brands" */
.main-heading {
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 1px;
  color: #28156D;
  font-family: 'Brush Script Mt', cursive;
}

/* Styles for the heading "Trusted By Industry Leaders" */
.secondary-heading {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Playfair Display", serif;
}

/* Paragraph styling */
.paragraph-text {
  font-size: 12px;
  color: #666;
  max-width: 603px;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
}

/* Slider container */
.brand-slider {
  margin: 50px auto;
  width: 100%;
  overflow: hidden;
  padding-bottom: 60px;
}

/* Ensure each brand slide is centered */
.brand-slide {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  /* Adjusted to ensure space for the box */
}

/* Styling for each brand box */
.brand-box {
  width: 240.39px;
  height: 147px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  padding-bottom: 5px;
}

/* Box with top border */
.box-top-border {
  border-top: 5px solid #28156D;
}

/* Box with bottom border */
.box-bottom-border {
  border-bottom: 5px solid #00AA14;
}

/* Brand logo image */
.brand-logo {
  width: 150px;
  height: 100px;
  object-fit: contain;
  margin: 0 auto;
  display: block;
}

/* Slider transition effects */
.slick-slide {
  transition: transform 0.5s ease;
}

.slick-slide img {
  max-width: 100%;
  max-height: 100%;
}

/* Disable transitions when interacting to prevent unwanted movement */
.slick-slide:focus,
.slick-slide:active {
  outline: none;
}

/* Dots styling */
.slick-dots {
  position: relative;
  bottom: -10px;
  margin-top: 20px;
}

/* Dots and arrow styles */
.slick-prev:before, .slick-next:before {
  color: black;
}

/* Large Screens (1441px and up) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .brand-slider-container {
    text-align: center;
    padding: 20px 0;
  }

  /* Text container */
  .text-container {
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;

  }

  /* Styles for the heading "Area Of Brands" */
  .main-heading {
    font-size: 45px;
    /* 30% of 24px */
    font-weight: 500;
    text-align: center;
    margin-bottom: 1px;
    color: #28156D;
    font-family: 'Brush Script Mt', cursive;
  }

  /* Styles for the heading "Trusted By Industry Leaders" */
  .secondary-heading {
    font-size: 40px;
    /* 30% of 30px */
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    font-family: "Playfair Display", serif;
  }

  /* Paragraph styling */
  .paragraph-text {
    font-size: 10.5px;
    /* 30% of 15px */
    color: #666;
    max-width: 406px;
    /* 30% of 580px */
    margin: 0 auto;
    font-family: "Poppins", sans-serif;
  }

  /* Slider container */
  .brand-slider {
    margin: 35px auto;
    /* 30% of 50px */
    width: 100%;
    overflow: hidden;
    padding-bottom: 42px;
    /* 30% of 60px */
  }

  /* Ensure each brand slide is centered */
  .brand-slide {
    padding: 7px;
    /* 30% of 10px */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 112px;
    /* 30% of 160px */
  }

  /* Styling for each brand box */
  .brand-box {
    width: 168.27px;
    /* 30% of 240.39px */
    height: 102.9px;
    /* 30% of 147px */
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    padding-bottom: 3.5px;
    /* 30% of 5px */
  }

  /* Box with top border */
  .box-top-border {
    border-top: 5px solid #28156D;
  }

  /* Box with bottom border */
  .box-bottom-border {
    border-bottom: 5px solid #00AA14;
  }

  /* Brand logo image */
  .brand-logo {
    width: 105px;
    /* 30% of 150px */
    height: 70px;
    /* 30% of 100px */
    object-fit: contain;
    margin: 0 auto;
    display: block;
  }

  /* Slider transition effects */
  .slick-slide {
    transition: transform 0.5s ease;
  }

  .slick-slide img {
    max-width: 100%;
    max-height: 100%;
  }

  /* Disable transitions when interacting to prevent unwanted movement */
  .slick-slide:focus,
  .slick-slide:active {
    outline: none;
  }

  /* Dots styling */
  .slick-dots {
    position: relative;
    bottom: -7px;
    /* 30% of -10px */
    margin-top: 14px;
    /* 30% of 20px */
  }

  /* Dots and arrow styles */
  .slick-prev:before, .slick-next:before {
    color: black;
  }

}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .brand-slider-container {
    text-align: center;
    padding: 20px 0;
  }

  /* Text container */
  .text-container {
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;
    margin-top: 30%;
  }

  /* Styles for the heading "Area Of Brands" */
  .main-heading {
    font-size: 45px;
    /* 30% of 24px */
    font-weight: 400;
    margin-bottom: 1px;
    color: #28156D;
    font-family: 'Brush Script Mt', cursive;
  }

  /* Styles for the heading "Trusted By Industry Leaders" */
  .secondary-heading {
    font-size: 35px;
    /* 30% of 30px */
    font-weight: 500;
    margin-bottom: 20px;
    font-family: "Playfair Display", serif;
  }

  /* Paragraph styling */
  .paragraph-text {
    font-size: 10.5px;
    /* 30% of 15px */
    color: #666;
    max-width: 406px;
    /* 30% of 580px */
    margin: 0 auto;
    font-family: "Poppins", sans-serif;
  }

  /* Slider container */
  .brand-slider {
    margin: 35px auto;
    /* 30% of 50px */
    width: 100%;
    overflow: hidden;
    padding-bottom: 42px;
    /* 30% of 60px */
  }

  /* Ensure each brand slide is centered */
  .brand-slide {
    padding: 7px;
    /* 30% of 10px */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    /* 30% of 160px */
  }

  /* Styling for each brand box */
  .brand-box {
    width: 168.27px;
    /* 30% of 240.39px */
    height: 102.9px;
    /* 30% of 147px */
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    padding-bottom: 3.5px;
    /* 30% of 5px */
  }

  /* Box with top border */
  .box-top-border {
    border-top: 5px solid #28156D;
  }

  /* Box with bottom border */
  .box-bottom-border {
    border-bottom: 5px solid #00AA14;
  }

  /* Brand logo image */
  .brand-logo {
    width: 105px;
    /* 30% of 150px */
    height: 70px;
    /* 30% of 100px */
    object-fit: contain;
    margin: 0 auto;
    display: block;
  }

  /* Slider transition effects */
  .slick-slide {
    transition: transform 0.5s ease;
  }

  .slick-slide img {
    max-width: 100%;
    max-height: 100%;
  }

  /* Disable transitions when interacting to prevent unwanted movement */
  .slick-slide:focus,
  .slick-slide:active {
    outline: none;
  }

  /* Dots styling */
  .slick-dots {
    position: relative;
    bottom: -7px;
    /* 30% of -10px */
    margin-top: 14px;
    /* 30% of 20px */
  }

  /* Dots and arrow styles */
  .slick-prev:before, .slick-next:before {
    color: black;
  }

  /* Reducing dimensions by 30% */
  .brand-slider-container {
    padding: 14px 0;
    /* 70% of 20px */
  }

  .text-container {
    padding: 14px;
    /* 70% of 20px */
    margin-bottom: 21px;
    /* 70% of 30px */
  }

  .brand-slide {
    height: 92px;
    /* Already reduced */
  }

  .brand-box {
    width: 117.79px;
    /* 70% of 168.27px */
    height: 72.03px;
    /* 70% of 102.9px */
    padding-bottom: 2.45px;
    /* 70% of 3.5px */
  }

  .brand-logo {
    width: 73.5px;
    /* 70% of 105px */
    height: 49px;
    /* 70% of 70px */
  }

}

/* Tablets (481px to 768px) */
@media (min-width: 550px) and (max-width: 768px) {
  .brand-slider-container {
    text-align: center;
    padding: 11.2px;
    /* 80% of 14px */
  }

  /* Text container */
  .text-container {
    text-align: center;
    padding: 11.2px;
    /* 80% of 14px */
    margin-bottom: 16.8px;
    /* 80% of 21px */
  }

  /* Styles for the heading "Area Of Brands" */
  .main-heading {
    font-size: 40.44px;
    /* 80% of 16.8px */
    font-weight: 500;
    margin-bottom: 1px;
    color: #28156D;
    font-family: 'Brush Script Mt', cursive;
  }

  /* Styles for the heading "Trusted By Industry Leaders" */
  .secondary-heading {
    font-size: 40.8px;
    /* 80% of 21px */
    font-weight: bold;
    margin-bottom: 20px;
    font-family: "Playfair Display", serif;
  }

  /* Paragraph styling */
  .paragraph-text {
    font-size: 8.4px;
    /* 80% of 10.5px */
    color: #666;
    max-width: 324.8px;
    /* 80% of 406px */
    margin: 0 auto;
    font-family: "Poppins", sans-serif;
  }

  /* Slider container */
  .brand-slider {
    margin: 19.6px auto;
    /* 80% of 24.5px */
    width: 100%;
    overflow: hidden;
    padding-bottom: 23.52px;
    /* 80% of 29.4px */
  }

  /* Ensure each brand slide is centered */
  .brand-slide {
    padding: 5.6px;
    /* 80% of 7px */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    /* Already reduced */
  }

  /* Styling for each brand box */
  .brand-box {
    width: 94.23px;
    /* 80% of 117.79px */
    height: 57.62px;
    /* 80% of 72.03px */
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    padding-bottom: 1.96px;
    /* 80% of 2.45px */
  }

  /* Box with top border */
  .box-top-border {
    border-top: 2.8px solid #28156D;
    /* Adjusted from 3.5px */
  }

  /* Box with bottom border */
  .box-bottom-border {
    border-bottom: 2.8px solid #00AA14;
    /* Adjusted from 3.5px */
  }

  /* Brand logo image */
  .brand-logo {
    width: 58.8px;
    /* 80% of 73.5px */
    height: 39.2px;
    /* 80% of 49px */
    object-fit: contain;
    margin: 0 auto;
    display: block;
  }

  /* Slider transition effects */
  .slick-slide {
    transition: transform 0.5s ease;
  }

  .slick-slide img {
    max-width: 100%;
    max-height: 100%;
  }

  /* Disable transitions when interacting to prevent unwanted movement */
  .slick-slide:focus,
  .slick-slide:active {
    outline: none;
  }

  /* Dots styling */
  .slick-dots {
    position: relative;
    bottom: -3.92px;
    /* 80% of -4.9px */
    margin-top: 7.84px;
    /* 80% of 9.8px */
  }

  /* Dots and arrow styles */
  .slick-prev:before, .slick-next:before {
    color: black;
  }


}

/* Mobile Devices (up to 480px) */
@media (max-width: 550px) {
  .brand-slider-container {
    text-align: center;
    padding: 6.27px;
    /* 80% of 7.84px */
    margin-top: 0%;
  }

  /* Text container */
  .text-container {
    text-align: center;
    padding: 6.27px;
    /* 80% of 7.84px */
    margin-bottom: 9.41px;
    /* 80% of 11.76px */
  }

  /* Styles for the heading "Area Of Brands" */
  .main-heading {
    font-size: 34px;
    /* 80% of 9.43px */
    font-weight: 400;
    margin-bottom: 1px;
    color: #28156D;
    font-family: 'Brush Script Mt', cursive;
  }

  /* Styles for the heading "Trusted By Industry Leaders" */
  .secondary-heading {
    font-size: 35px;
    /* 80% of 11.76px */
    font-weight: 400;
    margin-bottom: 20px;

    font-family: "Playfair Display", serif;
  }

  /* Paragraph styling */
  .paragraph-text {
    font-size: 16px;
    /* 80% of 5.88px */
    color: #000000;
    /* max-width: 181.88px; 80% of 227.36px */
    margin: 0;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    line-height: 33px;
    align-items: Center;
  }

  /* Slider container */
  .brand-slider {
    margin: 10.98px auto;
    /* 80% of 13.72px */
    width: 100%;
    overflow: hidden;
    padding-bottom: 13.17px;
    /* 80% of 16.46px */
  }

  /* Ensure each brand slide is centered */
  .brand-slide {
    padding: 3.14px;
    /* 80% of 3.92px */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    /* Already reduced */
    /* width: 223.1px; */
  }

  /* Styling for each brand box */
  .brand-box {
    width: 223.1px;
    /* 80% of 65.96px */
    height: 132.1px;
    /* 80% of 40.33px */
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    padding-bottom: 1.09px;
    /* 80% of 1.37px */
  }

  /* Box with top border */
  .box-top-border {
    border-top: 1.57px solid #28156D;
    /* Adjusted from 1.96px */
  }

  /* Box with bottom border */
  .box-bottom-border {
    border-bottom: 1.57px solid #00AA14;
    /* Adjusted from 1.96px */
  }

  /* Brand logo image */
  .brand-logo {
    width: 223.1px;
    /* 80% of 41.16px */
    height: 132.1px;
    /* 80% of 27.44px */
    object-fit: contain;
    margin: 0 auto;
    display: block;
  }

  /* Slider transition effects */
  .slick-slide {
    transition: transform 0.5s ease;
  }

  .slick-slide img {
    width: 223.1px;
    max-height: 132.1px;
  }

  /* Disable transitions when interacting to prevent unwanted movement */
  .slick-slide:focus,
  .slick-slide:active {
    outline: none;
  }

  /* Dots styling */
  .slick-dots {
    position: relative;
    bottom: -2.20px;
    /* 80% of -2.75px */
    margin-top: 4.39px;
    /* 80% of 5.49px */
  }

  /* Dots and arrow styles */
  .slick-prev:before, .slick-next:before {
    color: black;
  }

}

@media screen and (min-width: 1600px) {
  .brand-slider-container {
    margin-top: 0%;
  }
}

.Section4Bg {
  background-image: url('./images/sec3bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 1050px;
}

.Section4Content {
  display: flex;
  justify-content: center;
  padding: 0px 0;
  padding-top: 124px;
  margin: 20px 0;

}

.btnofsection {
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 16px 10px;
  border-radius: 16px;
  margin-bottom: 15px;
  background-image: linear-gradient(to left, #29166F 29%, #00A539 100%);
  cursor: pointer;
}

.btn-text {
  color: #fff;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
}

.Section4Images {
  display: flex;
  flex-direction: column;
}

.Section4Imagestop {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 5%;
}

.Section4Textright {
  margin: 36px;
  justify-content: start;
  display: flex;
  flex-direction: column;
}

.WhoWeAre {
  color: #28156D;
  text-align: start;
  font-size: 45px;
  font-family: 'Brush Script MT';
  font-weight: 500;
}

.EDMedaskMessage {
  text-align: start;
  font-family: 'Playfair Display';
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 400;
}

.EDMedaskMessagePara {
  text-align: start;
  line-height: 2;
  font-family: 'poppins';
  font-weight: 500;
}

.image2Sec3 {
  padding: 10px;
}

.Section4BottomBtns {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-bottom: 10%;
}

.firstbtnofbottomSec4 {
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 16px 18px;
  border-radius: 8px;
  font-weight: bold;
  font-family: 'poppins';
  background-color: #29166F;
  cursor: pointer;
  content: fit-content;
}

.Section4Contentsmallscreen {
  display: none;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  margin: 20px 0;
}

.SecondbtnofbottomSec4 {
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  font-weight: bold;
  font-family: 'poppins';
  border-radius: 8px;
  background-color: #00AA14;
  padding: 16px 18px;
  cursor: pointer;
  margin: 0 px;
}

.btn-textofsec4bottom {
  color: #fff;
  font-family: 'Poppins';
  font-size: 17px;
  font-style: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  line-height: normal;
}

.CheckCircleIcon {
  background-color: #29166F;
  border-radius: 50%;
  margin-right: 6px;
}

.Section4BottomBtnsSmallscreen {
  display: none;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  margin-bottom: 10%;
}

@media screen and (max-width: 1208px) {
  .Section4Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0;
    margin: 20px 0;
  }
  .Section4Bg {
    background-image: url('./images/sec3bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1650px;
  }
}

@media screen and (max-width: 768px) {
  .Section4Bg {
    background-image: url('./images/sec3bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1200px;
  }
  .firstbtnofbottomSec4 {
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 16px 18px;
    width: 200px;
    border-radius: 8px;
    font-weight: bold;
    font-family: 'poppins';
    background-color: #29166F;
    cursor: pointer;
    content: fit-content;
  }
  .SecondbtnofbottomSec4 {
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    font-weight: bold;
    font-family: 'poppins';
    border-radius: 8px;
    background-color: #00AA14;
    padding: 16px 18px;
    cursor: pointer;
    margin: 0 px;
    width: 200px;
  }
  .btn-textofsec4bottom {
    color: #fff;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    line-height: normal;
}
  .Section4Content {
    display: none;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0;
    margin: 20px 0;
  }

  .Section4Contentsmallscreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0;
    margin: 20px 0;
  }
  .Section4Images{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Section4Imagestop {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 5%;
  }

  .image2Sec3 {
    width: 290.2px;
    height: 170.39px;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .Section4ImageOne {
    width: 133.5px;
    height: 117.57px;
    object-fit: cover;
  }

  .Section4BottomBtns {
    display: none;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    margin-bottom: 10%;
  }

  .Section4BottomBtnsSmallscreen {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    margin-bottom: 10%;
  }

  .btnofsection {
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 16px 10px;
    border-radius: 16px;
    margin-bottom: 15px;
    background-image: linear-gradient(to left, #29166F 29%, #00A539 100%);
    cursor: pointer;
    width: 310px;
  }

  .EDMedaskMessagePara {
    text-align: start;
    line-height: 2;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 10px;
    margin-top: 10px;
  }

  .Section4Textright {
    margin: 0px; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .btn-text {
    color: #fff;
    font-family: 'Poppins';
    font-size: 10.52px;
    font-weight: 700;
    line-height: normal;
  }

  .EDMedaskMessage {
    text-align: start;
    font-family: 'Playfair Display';
    margin-bottom: 15px;
    font-size: 35px;
    font-weight: 400;
  }

  .WhoWeAre {
    color: #28156D;
    text-align: start;
    font-size: 42px;
    font-family: 'Brush Script MT';
    font-weight: 400;
  }

}

.h1ofvidhead1 {
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
  margin-bottom: 50px;
}

.h2ofvidhead2 {
  color: #000;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 50px;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .h1ofvidhead1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .h2ofvidhead2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .h1ofvidhead1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .h2ofvidhead2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .h1ofvidhead1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .h2ofvidhead2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .h1ofvidhead1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .h2ofvidhead2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.video-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
}

.main-video {
  display: flex;
  justify-content: center;
  position: relative;
}

.vidbacklogo {
  width: 800px;
  height: 420px;
  border-radius: 30px;
  filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.10));
  background-position: center;
  position: relative;
}

.mainvid {
  width: 620px;
  height: 400px;
  border-radius: 30px;
  background-color: #29166F;
  filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.10));
}

.small-video {
  width: 600px;
  height: 300px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  position: absolute;
  /* Ensure they overlay correctly */
}

.small-video.left {
  width: 371.258px;
  height: 299.83px;
  left: -150px;
  /* Show half off-screen to the left */
  top: 50%;
  /* Center vertically */
  transform: translateY(-50%);
  /* Adjust vertical centering */
}

.small-video.right {
  width: 371.258px;
  height: 299.83px;
  right: -150px;
  /* Show half off-screen to the right */
  top: 50%;
  /* Center vertically */
  transform: translateY(-50%);
  /* Adjust vertical centering */
}

.small-video:hover {
  opacity: 1;
  /* Full opacity on hover */
}

.controls {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 500px);
  pointer-events: none;
  z-index: 3;
}

.controls button {
  width: 35px;
  height: 35px;
  color: white;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  pointer-events: all;
  /* Allow button clicks */
  padding: 10px;
  border-radius: 50%;
  /* Rounded buttons */
  transition: background-color 0.3s ease;
  /* Smooth color transition */
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1100px) and (max-width: 1440px) {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .video-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
  }

  .main-video {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .vidbacklogo {
    width: 600px;
    height: 320px;
    border-radius: 30px;
    filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.10));
    background-position: center;
    position: relative;
  }

  .mainvid {
    width: 600px;
    height: 320px;
    border-radius: 30px;
    background-color: #29166F;
    filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.10));
  }

  .small-video {
    width: 200px;
    height: 120px;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    position: absolute;
    /* Ensure they overlay correctly */
  }

  .small-video.left {

    left: -150px;
    /* Show half off-screen to the left */
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust vertical centering */
  }

  .small-video.right {
    right: -150px;
    /* Show half off-screen to the right */
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust vertical centering */
  }

  .small-video:hover {
    opacity: 1;
    /* Full opacity on hover */
  }

  .controls {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 600px);
    pointer-events: none;
    z-index: 3;
  }

  .controls button {
    width: 35px;
    height: 35px;
    color: white;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    pointer-events: all;
    /* Allow button clicks */
    padding: 10px;
    border-radius: 50%;
    /* Rounded buttons */
    transition: background-color 0.3s ease;
    /* Smooth color transition */
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1099px) {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .video-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
  }

  .main-video {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .vidbacklogo {
    display: none;
    width: 150px;
    height: 80px;
    border-radius: 30px;
    filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.10));
    background-position: center;
    position: relative;
  }

  .mainvid {
    width: 500px;
    height: 280px;
    border-radius: 30px;
    background-color: #29166F;
    filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.10));
  }

  .small-video {
    display: none;
    width: 100px;
    height: 50px;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    position: absolute;
    /* Ensure they overlay correctly */
  }

  .small-video.left {
    display: none;
    left: -150px;
    /* Show half off-screen to the left */
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust vertical centering */
  }

  .small-video.right {
    display: none;
    right: -150px;
    /* Show half off-screen to the right */
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust vertical centering */
  }

  .small-video:hover {
    opacity: 1;
    /* Full opacity on hover */
  }

  .controls {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 250px);
    pointer-events: none;
    z-index: 3;
  }

  .controls button {
    width: 35px;
    height: 35px;
    color: white;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    pointer-events: all;
    /* Allow button clicks */
    padding: 10px;
    border-radius: 50%;
    /* Rounded buttons */
    transition: background-color 0.3s ease;
    /* Smooth color transition */
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 300px) and (max-width: 768px) {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .video-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
  }

  .main-video {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .vidbacklogo {
    display: none;
    width: 30px;
    height: 200px;
    border-radius: 30px;
    filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.10));
    background-position: center;
    position: relative;
  }

  .mainvid {
    width: 300px;
    height: 250px;
    border-radius: 30px;
    background-color: #29166F;
    filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.10));
  }

  .small-video {
    display: none;
    width: 100px;
    height: 50px;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    position: absolute;
    /* Ensure they overlay correctly */
  }

  .small-video.left {
    display: none;
    left: -150px;
    /* Show half off-screen to the left */
    top: 150%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust vertical centering */
  }

  .small-video.right {
    display: none;
    right: -150px;
    /* Show half off-screen to the right */
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust vertical centering */
  }

  .small-video:hover {
    opacity: 1;
    /* Full opacity on hover */
  }

  .controls {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 10px);
    pointer-events: none;
    z-index: 3;
  }

  .controls button {
    width: 35px;
    height: 35px;
    color: white;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    pointer-events: all;
    /* Allow button clicks */
    padding: 10px;
    border-radius: 50%;
    /* Rounded buttons */
    transition: background-color 0.3s ease;
    /* Smooth color transition */
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

* {
  padding: 0;
  margin: 0px;
  box-sizing: border-box;
}

.vidtext {
  margin-top: 50px;
  width: 849.61px;
  height: 143.796px;
  flex-shrink: 0;
  color: #000;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
}

.vidtitle {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-right: 5px;
}

.divcen {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1025px) and (max-width: 1440px) {
  * {
    padding: 0;
    margin: 0px;
    box-sizing: border-box;
  }

  .vidtext {
    margin-top: 15px;
    width: 749.613px;
    height: 143.796px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }

  .vidtitle {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-right: 5px;
  }

  .divcen {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  * {
    padding: 0;
    margin: 0px;
    box-sizing: border-box;
  }

  .vidtext {
    margin-top: 15px;
    width: 550px;
    height: 143.796px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }

  .vidtitle {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-right: 5px;
  }

  .divcen {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  * {
    padding: 0;
    margin: 0px;
    box-sizing: border-box;
  }

  .vidtext {
    margin-top: 15px;
    width: 350px;
    height: 143.796px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .vidtitle {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-right: 5px;
  }

  .divcen {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 480px) {
  * {
    padding: 0;
    margin: 0px;
    box-sizing: border-box;
  }

  .vidtext {
    margin-top: 15px;
    width: 300px;
    height: 143.796px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .vidtitle {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-right: 5px;
  }

  .divcen {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.div1ofdiv2 {
  width: 1080.949px;
  height: 87.711px;
  flex-shrink: 0;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 20px;
}

.radiodiv2 {
  width: 244.551px;
  height: 63.573px;
  flex-shrink: 0;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 50px;
  /* box-shadow: -1px 0px 1px rgba(0, 0, 0, 0.3); */
  margin-right: 10px;

}

.radiodiv2-active {
  width: 244.551px;
  height: 63.573px;
  flex-shrink: 0;
  background-color: #00AA14;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  padding-right: 50px;
  margin-right: 10px;
}

.labelofdiv2 {
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.labelofdiv2-active {
  color: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.divcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.radioofdiv2[type=radio] {
  background-color: #00AA14;
  cursor: default;
  box-sizing: border-box;
  margin: 3px 3px 0px 5px;
  padding: initial;
  border: 1px solid #00AA14;
  width: 23.859px;
  height: 23.859px;
  border-width: 0px;

}

.radioofdiv2[type=radio]:checked {
  accent-color: #00AA14;
  border-color: #00AA14;
  cursor: default;
  box-sizing: border-box;
  margin: 3px 3px 0px 5px;
  padding: initial;
  border: 1px solid #00AA14;
  width: 23.859px;
  height: 23.859px;

}

.divright {
  width: 630.677px;
  height: 421.282px;
  border-radius: 25px;
  background: lightgray 50% / cover no-repeat;
}

.divimg {
  width: 630.677px;
  height: 421.282px;
  border-radius: 25px;
  background: url('./images/image.png') lightgray 50% / cover no-repeat;
}

.div2h1 {
  color: #000;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  text-align: start;
  line-height: normal;
}

.divp {
  width: 552.885px;
  height: 200px;
  flex-shrink: 0;
  color: #000;
  text-align: start;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}

.divofdiv3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.backgrounddiv2 {
  width: 100%;
  height: 1089.625px;
  flex-shrink: 0;
  background-image: url('./images/sec3bg.png');
  display: flex;
  justify-content: center;
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

}



.svgdiv2 {
  width: 12.702px;
  height: 55.281px;
  flex-shrink: 0;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .div1ofdiv2 {
    width: 864.759px;
    height: 70.169px;
    flex-shrink: 0;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-top: 16px;
  }

  .radiodiv2 {
    width: 195.641px;
    height: 50.858px;
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 40px;
    margin-right: 8px;
  }

  .radiodiv2-active {
    width: 195.641px;
    height: 50.858px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 4px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 8px;
    padding-right: 40px;
    margin-right: 8px;
  }

  .labelofdiv2 {
    color: #000000;
    font-family: Poppins;
    font-size: 14.4px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .labelofdiv2-active {
    color: #FFF;
    font-family: Poppins;
    font-size: 14.4px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .divcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  .radioofdiv2[type=radio] {
    background-color: #00AA14;
    cursor: default;
    box-sizing: border-box;
    margin: 2.4px 2.4px 0px 4px;
    padding: initial;
    border: 0.8px solid #00AA14;
    width: 19.087px;
    height: 19.087px;
    border-width: 0px;
  }

  .radioofdiv2[type=radio]:checked {
    accent-color: #00AA14;
    border-color: #00AA14;
    cursor: default;
    box-sizing: border-box;
    margin: 2.4px 2.4px 0px 4px;
    padding: initial;
    border: 0.8px solid #00AA14;
    width: 19.087px;
    height: 19.087px;
  }

  .divright {
    width: 504.541px;
    height: 337.026px;
    border-radius: 20px;
    background: lightgray 50% / cover no-repeat;
  }

  .divimg {
    width: 504.541px;
    height: 337.026px;
    border-radius: 20px;
    background: url('./images/image.png') lightgray 50% / cover no-repeat;
  }

  .div2h1 {
    color: #000;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
    line-height: normal;
  }

  .divp {
    width: 442.308px;
    height: 160px;
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: Poppins;
    font-size: 14.4px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.2px;
  }

  .divofdiv3 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .backgrounddiv2 {
    width: 100%;
    height: 991.7px;
    flex-shrink: 0;
    background-image: url('./images/sec3bg.png');
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .divlogos {
    width: 441.994px;
    height: 216.382px;
    flex-shrink: 0;
    background-color: transparent;
    opacity: 0.3;
    margin-left: -80px;
    background: url('./images/b45c0c75cf4d09fe47cceb0393f6bca2.png') rgba(211, 211, 211, 0) 0px 0px / 100% 163.413% no-repeat;
  }

  .svgdiv2 {
    width: 10.162px;
    height: 44.225px;
    flex-shrink: 0;
  }

}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .div1ofdiv2 {
    width: 691.807px;
    height: 56.135px;
    flex-shrink: 0;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.4px;
    margin-top: 12.8px;
  }

  .radiodiv2 {
    width: 156.513px;
    height: 40.686px;
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 3.2px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 32px;
    margin-right: 6.4px;
  }

  .radiodiv2-active {
    width: 156.513px;
    height: 40.686px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 3.2px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 6.4px;
    padding-right: 32px;
    margin-right: 6.4px;
  }

  .labelofdiv2 {
    color: #000000;
    font-family: Poppins;
    font-size: 11.52px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .labelofdiv2-active {
    color: #FFF;
    font-family: Poppins;
    font-size: 11.52px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .divcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }

  .radioofdiv2[type=radio] {
    background-color: #00AA14;
    cursor: default;
    box-sizing: border-box;
    margin: 1.92px 1.92px 0px 3.2px;
    padding: initial;
    border: 0.64px solid #00AA14;
    width: 15.27px;
    height: 15.27px;
    border-width: 0px;
  }

  .radioofdiv2[type=radio]:checked {
    accent-color: #00AA14;
    border-color: #00AA14;
    cursor: default;
    box-sizing: border-box;
    margin: 1.92px 1.92px 0px 3.2px;
    padding: initial;
    border: 0.64px solid #00AA14;
    width: 15.27px;
    height: 15.27px;
  }

  .divright {
    width: 403.633px;
    height: 269.621px;
    border-radius: 16px;
    background: lightgray 50% / cover no-repeat;
  }

  .divimg {
    width: 403.633px;
    height: 269.621px;
    border-radius: 16px;
    background: url('./images/image.png') lightgray 50% / cover no-repeat;
  }

  .div2h1 {
    color: #000;
    font-family: Poppins;
    font-size: 22.4px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
    line-height: normal;
  }

  .divp {
    width: 353.846px;
    height: 128px;
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: Poppins;
    font-size: 11.52px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.76px;
  }

  .divofdiv3 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12.8px;
    padding: 45px;
  }

  .backgrounddiv2 {
    width: 100%;
    height: 697.36px;
    flex-shrink: 0;
    background-image: url('./images/sec3bg.png');
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .divlogos {
    width: 353.595px;
    height: 173.106px;
    flex-shrink: 0;
    background-color: transparent;
    opacity: 0.3;
    margin-left: -64px;
    background: url('./images/b45c0c75cf4d09fe47cceb0393f6bca2.png') rgba(211, 211, 211, 0) 0px 0px / 100% 163.413% no-repeat;
  }

  .svgdiv2 {
    width: 8.13px;
    height: 35.38px;
    flex-shrink: 0;
  }

}


@media (min-width: 650px) and (max-width: 768px) {
  .div1ofdiv2 {
    width: 553.446px;
    height: 44.908px;
    flex-shrink: 0;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5.12px;
    margin-top: 10.24px;
  }

  .radiodiv2 {
    width: 125.210px;
    height: 32.548px;
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 2.56px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 25.6px;
    margin-right: 5.12px;
  }

  .radiodiv2-active {
    width: 125.210px;
    height: 32.548px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 2.56px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5.12px;
    padding-right: 25.6px;
    margin-right: 5.12px;
  }

  .labelofdiv2 {
    color: #000000;
    font-family: Poppins;
    font-size: 9.216px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .labelofdiv2-active {
    color: #FFF;
    font-family: Poppins;
    font-size: 9.216px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .divcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25.6px;
  }

  .radioofdiv2[type=radio] {
    background-color: #00AA14;
    cursor: default;
    box-sizing: border-box;
    margin: 1.536px 1.536px 0px 2.56px;
    padding: initial;
    border: 0.512px solid #00AA14;
    width: 12.216px;
    height: 12.216px;
    border-width: 0px;
  }

  .radioofdiv2[type=radio]:checked {
    accent-color: #00AA14;
    border-color: #00AA14;
    cursor: default;
    box-sizing: border-box;
    margin: 1.536px 1.536px 0px 2.56px;
    padding: initial;
    border: 0.512px solid #00AA14;
    width: 12.216px;
    height: 12.216px;
  }

  .divleft {
    margin-top: 0px !important;
  }

  .divright {
    width: 322.906px;
    height: 215.697px;
    border-radius: 12.8px;
    background: lightgray 50% / cover no-repeat;
  }

  .divimg {
    width: 322.906px;
    height: 215.697px;
    border-radius: 12.8px;
    background: url('./images/image.png') lightgray 50% / cover no-repeat;
  }

  .div2h1 {
    color: #000;
    font-family: Poppins;
    font-size: 17.92px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
    line-height: normal;


  }

  .divp {
    width: 283.077px;
    height: 102.4px;
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: Poppins;
    font-size: 9.216px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.408px;
  }

  .divofdiv3 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column-reverse; */
    gap: 10.24px;
  }

  .backgrounddiv2 {
    width: 100%;
    height: 800px;
    flex-shrink: 0;
    background-image: url('./images/sec3bg.png');
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .divlogos {
    width: 282.396px;
    height: 141.285px;
    flex-shrink: 0;
    background-color: transparent;
    opacity: 0.3;
    margin-left: -51.2px;
    background: url('./images/b45c0c75cf4d09fe47cceb0393f6bca2.png') rgba(211, 211, 211, 0) 0px 0px / 100% 163.413% no-repeat;
  }

  .svgdiv2 {
    width: 6.504px;
    height: 28.304px;
    flex-shrink: 0;
  }


}

@media (max-width: 650px) {
  .div1ofdiv2 {
    width: 100%;
    height: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two equal-width columns */
    gap: 10px;
    /* Optional: spacing between grid items */
    background-color: transparent;
    margin-bottom: 20px;
  }

  .radiodiv2 {
    width: 130px;
    height: 53px;
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 1.792px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 17.92px;
    margin-right: 3.584px;
  }

  .radiodiv2-active {
    width: 130px;
    height: 53px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 1.792px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 3.584px;
    padding-right: 17.92px;
    margin-right: 3.584px;
  }

  .labelofdiv2 {
    color: #000000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    width: 80px;
    height: auto;
    font-weight: 300;
    line-height: normal;
    text-align: center;
  }

  .labelofdiv2-active {
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .divcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 17.92px;
  }

  .radioofdiv2[type=radio] {
    background-color: #00AA14;
    cursor: default;
    box-sizing: border-box;
    margin: 1.075px 1.075px 0px 1.792px;
    padding: initial;
    border: 0.358px solid #00AA14;
    width: 11.551px;
    height: 11.551px;
    border-width: 0px;
  }

  .radioofdiv2[type=radio]:checked {
    accent-color: #00AA14;
    border-color: #00AA14;
    cursor: default;
    box-sizing: border-box;
    margin: 1.075px 1.075px 0px 1.792px;
    padding: initial;
    border: 0.358px solid #00AA14;
    width: 11.551px;
    height: 11.551px;
  }

  .divright {
    width: 300px;
    height: 265.383px;
    flex-shrink: 0;
    margin-left: 10px;
    border-radius: 8.96px;
  }

  .divimg {
    width: 300px;
    height: 265.383px;
    flex-shrink: 0;
    border-radius: 8.96px;
    background: url('./images/image.png') lightgray 50% / cover no-repeat;
  }

  .div2h1 {
    color: #000;
    font-family: Poppins;
    font-size: 12.544px;
    font-style: normal;
    font-weight: 600;
    text-align: start;
    line-height: normal;
  }

  .divp {
    width: 300px;
    height: 239.877px;
    flex-shrink: 0;
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.186px;
  }

  .divofdiv3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 12px;
  }

  .backgrounddiv2 {
    width: 100%;
    height: 1100px;
    flex-shrink: 0;
    background-image: url('./images/sec3bg.png');
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .divlogos {
    width: 197.677px;
    height: 98.899px;
    flex-shrink: 0;
    background-color: transparent;
    opacity: 0.3;
    margin-left: -35.84px;
    background: url('./images/b45c0c75cf4d09fe47cceb0393f6bca2.png') rgba(211, 211, 211, 0) 0px 0px / 100% 163.413% no-repeat;
  }

  .svgdiv2 {
    width: 4.553px;
    height: 19.813px;
    flex-shrink: 0;
  }

  .divleft {
    margin-top: 30px;
    margin-left: 5%;
  }

}

/* Container for the entire news section */
.news-section {
  padding: 40px 80px;
  text-align: center;
}

.news-item {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

@media (max-width: 768px) {
  .news-section {
    padding: 20px 40px;
    /* Reduce padding on smaller screens */
    display: flex;
    flex-direction: column;
  }

  .news-heading, .news-subheading {
    font-size: 18px;
    /* Reduce font sizes on smaller screens */
  }

  .news-cards-container {
    display: flex;
    gap: 40px;
    justify-content: center;
    flex-direction: column;
  }

  .news-item {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
  }


}

/* Main heading of the news section */
.news-heading {
  font-size: 45px;
  font-weight: 400;
  color: #28156D;
  margin-bottom: 10px;
  font-family: 'Brush Script Mt', cursive;
  text-align: center;
}

/* Subheading */
.news-subheading {
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 40px;
  font-family: "Playfair Display", serif;
  text-align: center;
}



/* Cards container */
.news-cards-container {
  display: flex;
  gap: 40px;
  justify-content: center;
}

@media (max-width: 980px) {
  .news-cards-container {
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
  }

  .news-item {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
  }

  .news-image {
    height: 200px;
    /* Reduce image height for smaller screens */
  }

  .news-date {
    display: flex;
    position: relative;
    top: 27%;
    width: 100px;
    /* margin-left: -16%; */
    height: fit-content;
    /* transform: inherit; */
    /* padding: 8px 12px; */
    background-color: #28156D;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    border-radius: 10px;
    z-index: 1;
  }

  .news-description {
    font-size: 14px;
    /* Reduce font size */
  }

  .read-more-link {
    font-size: 12px;
    /* Reduce font size */
  }
}

@media (max-width: 480px) {
  .news-cards-container {
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
  }

  .news-item {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
  }

  .news-image {
    height: 200px;
    /* Reduce image height for smaller screens */
  }

  .news-date {
    display: flex;
    position: relative;
    top: 27%;
    width: 100px;
    /* margin-left: -16%; */
    height: fit-content;
    transform: inherit;
    /* padding: 8px 12px; */
    background-color: #28156D;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    border-radius: 10px;
    z-index: 1;
  }

  .ibrtitle1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }


  .subtitle {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .news-description {
    font-size: 14px;
    /* Reduce font size */
  }

  .read-more-link {
    font-size: 12px;
    /* Reduce font size */
  }
}

/* Individual news card */
.news-card {
  width: 100%;
  max-width: 363px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  text-align: left;
  position: relative;
}

/* News image container */
.news-image {
  width: 100%;
  height: 262px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
}



/* Date button styling */
.news-date {
  display: flex;
  position: relative;
  top: 27%;
  width: 100px;
  margin-left: -16%;
  transform: rotate(90deg);
  padding: 8px 12px;
  background-color: #28156D;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  border-radius: 10px;
  z-index: 1;
}



/* Title of the article */
.news-title {
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 50px;
  color: #181818;
  font-family: 'Poppins';
  position: relative;
  margin-top: 10px;
}

.news-hr {
  margin-top: 14px;
  margin-bottom: 14px;
}

/* .news-title::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #000000;
  bottom: 0;
  left: 0;
} */

/* Description text */
.news-description {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}



/* Read more link */
.read-more-link {
  font-size: 14px;
  color: #28156D;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s;
  position: relative;
  font-family: 'poppins';
  padding-right: 35px;

}

.read-more-link::after {
  content: '→';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #28156D;
  transition: color 0.3s;
}

.read-more-link:hover::after {
  color: #0056b3;
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.formbg {
  background-image: url('./images/formbg.png');
  width: 100%;
  max-width: 1600px;
  height: 1149.625px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px;
  margin-top: 100px;
  margin-bottom: -90px;
}

.formdiv {
  width: 1000px;
  height: 604.742px;
  flex-shrink: 0;
  background-color: #FFF;
  filter: drop-shadow(0px 3.19px 15.952px rgba(0, 0, 0, 0.10));
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  padding: 10px;
}

.formleft {
  position: relative;
  width: 613.92px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formbglogo {
  width: 613.92px;
  height: 300.549px;
  background-image: url('./images/formlogo.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  opacity: 0.05;
  left: 1%;
  top: 20%;
}

.formright {
  width: 441.229px;
  height: 559.278px;
  background-image: url('./images/formrightlogo.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.inplabel {
  width: auto;
  height: auto;
  flex-shrink: 0;
  color: #121935;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: start;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.inpform {
  width: 446.771px;
  height: 54.396px;
  flex-shrink: 0;
  fill: rgba(255, 255, 255, 0.80);
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.10);
  padding: 10px;
  border: 1px solid gainsboro;
  border-radius: 30px;
  color: #202020;
  font-size: 14px;
}

.inpform:focus {
  width: 446.771px;
  height: 54.396px;
  flex-shrink: 0;
  fill: rgba(255, 255, 255, 0.80);
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.10);
  padding: 10px;
  border: 1px solid gainsboro;
  border-radius: 30px;
  color: #202020;
  font-size: 16px;
  outline: none;
}

.formlab {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.formbtn {
  width: 446.771px;
  height: 54.396px;
  flex-shrink: 0;
  background-color: #29166F;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid gainsboro;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.formp {
  color: #FFF;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  /* 444.444% */
}

.arrdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageopop {
  width: 336px;
  height: 148px;
  flex-shrink: 0;
  background-color: #FFF;
  filter: drop-shadow(0px 3px 5.292px rgba(0, 0, 0, 0.35));
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgmaph1 {
  color: #29166F;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 236px;
  height: 23px;
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Hides the overflowed text */
  text-overflow: ellipsis;
  /* Adds '...' to indicate the overflow */
}

.imgmapp {
  color: #0E0728;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 236px;
}

.mapsvg {
  background-image: url('./images/mapsvg.svg');
  width: 55.779px;
  height: 55.779px;
  stroke-width: 7px;
  stroke: #FFF;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 310px;
  /* This moves it to the left */
  top: 25%;


}

.formright {
  display: flex;
  padding-left: 3%;
  padding-top: 3%;
}

.svgani {
  width: 9.6px;
  /* Reduced by 20% */
  height: 12.8px;
  /* Reduced by 20% */
  transition: transform 0.2s ease-in-out;
  animation: jiggle 0.5s ease-in-out infinite;
  /* Animation runs continuously */
}

.svgani:hover {
  animation: jiggle 0.5s ease-in-out infinite;
  /* Same animation on hover, but you can omit this if you want it to run continuously */
}

@keyframes jiggle {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.Contdiv1 {
  width: 320.484px;
  height: 172.607px;
  border-radius: 20px;
  flex-shrink: 0;
  position: relative;
  background-color: #FFF;
  margin-top: 65px;
}

.conth1 {
  padding-top: 20%;
  color: #29166F;
  text-align: center;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.conth2 {
  color: #121935;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;

  line-height: normal;
}

.conth3 {
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 24px;

}

.conth4 {
  color: #000;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 24px;

}

.arrow11 {
  width: 34.545px;
  flex-shrink: 0;
}

.contimg {
  width: 55.26px;
  height: 55.26px;
  flex-shrink: 0;
  border-radius: 15px;
  position: absolute;
  top: -20px;
  left: 41%;
}

.Contactcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 31px;
  margin-bottom: 55px;
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 850px) and (max-width: 1024px) {
  * {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }

  .formbg {
    background-image: url('./images/formbg.png');
    width: 100%;
    /* Reduced to 80% */
    height: 1250px;
    /* Reduced to 80% of 1149.625px */
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: -150px;
    flex-direction: column;
  }

  .formdiv {
    width: 866.45px;
    /* Reduced to 80% of 1083.061px */
    height: 483.79px;
    /* Reduced to 80% of 604.742px */
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3.19px 15.952px rgba(0, 0, 0, 0.10));
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    /* Reduced by 20% */
    padding: 8px;
    /* Reduced by 20% */
  }

  .formleft {
    width: 491.14px;
    /* Reduced to 80% of 613.92px */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .formbglogo {
    width: 491.14px;
    /* Reduced to 80% of 613.92px */
    height: 240.44px;
    /* Reduced to 80% of 300.549px */
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -1;
    opacity: 0.05;
    margin-bottom: 52px;
    /* Reduced by 20% */
  }

  .formright {
    width: 353px;
    /* Reduced to 80% of 441.229px */
    height: 447.42px;
    /* Reduced to 80% of 559.278px */
    background-image: url('./images/formrightlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .inplabel {
    width: auto;
    height: auto;
    flex-shrink: 0;
    color: #121935;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    margin-left: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    margin-top: 8px;
    /* Reduced by 20% */
  }

  .inpform {
    width: 357.42px;
    /* Reduced to 80% of 446.771px */
    height: 43.52px;
    /* Reduced to 80% of 54.396px */
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    color: #202020;
    font-size: 11.2px;
    /* Reduced by 20% */
  }

  .inpform:focus {
    width: 357.42px;
    height: 43.52px;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    border: 1px solid gainsboro;
    border-radius: 24px;
    color: #202020;
    font-size: 12.8px;
    /* Reduced by 20% */
    outline: none;
  }

  .formlab {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .formbtn {
    width: 357.42px;
    /* Reduced to 80% of 446.771px */
    height: 43.52px;
    /* Reduced to 80% of 54.396px */
    flex-shrink: 0;
    background-color: #29166F;
    margin-top: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    /* Reduced by 20% */
    padding-left: 16px;
    /* Reduced by 20% */
    padding-right: 16px;
    /* Reduced by 20% */
  }

  .formp {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    /* Reduced by 20% */
  }

  .arrdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageopop {
    width: 268.8px;
    /* Reduced to 80% of 336px */
    height: 118.4px;
    /* Reduced to 80% of 148px */
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3px 5.292px rgba(0, 0, 0, 0.35));
    border-radius: 8px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgmaph1 {
    color: #29166F;
    font-family: Poppins;
    font-size: 12px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 188.8px;
    /* Reduced by 20% */
    height: 18.4px;
    /* Reduced by 20% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imgmapp {
    color: #0E0728;
    font-family: Poppins;
    font-size: 12.8px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 188.8px;
    /* Reduced by 20% */
  }

  .mapsvg {
    background-image: url('./images/mapsvg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 44.62px;
    /* Reduced by 20% */
    height: 44.62px;
    /* Reduced by 20% */
    stroke-width: 7px;
    stroke: #FFF;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 248px;
    /* Reduced by 20% */
    top: 25%;
  }

  .formright {
    display: flex;
    padding-left: 2.4%;
    /* Reduced by 20% */
    padding-top: 2.4%;
    /* Reduced by 20% */
  }

  .svgani {
    width: 9.6px;
    /* Reduced by 20% */
    height: 12.8px;
    /* Reduced by 20% */
    transition: transform 0.2s ease-in-out;
    animation: jiggle 0.5s ease-in-out infinite;
    /* Animation runs continuously */
  }

  .svgani:hover {
    animation: jiggle 0.5s ease-in-out infinite;
    /* Same animation on hover, but you can omit this if you want it to run continuously */
  }

  @keyframes jiggle {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .arrow11 {
    width: 34.545px;
    flex-shrink: 0;
  }

  .Contdiv1 {
    width: 256.3872px;
    height: 138.0856px;

    border-radius: 20px;
    flex-shrink: 0;
    position: relative;
    background-color: #FFF;
    margin-top: 65px;
  }

  .Contactcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 55px;
  }

  .conth1 {
    padding-top: 20%;
    color: #29166F;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .conth2 {
    color: #121935;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;

    line-height: normal;
  }

  .conth3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 24px;

  }

  .conth4 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 24px;

  }

  .contimg {
    width: 55.26px;
    height: 55.26px;
    flex-shrink: 0;
    border-radius: 15px;
    position: absolute;
    top: -18px;
    left: 38%;
  }

  .Contactcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 31px;
    margin-bottom: 55px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 520px) and (max-width: 850px) {
  * {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }

  .formbg {
    background-image: url('./images/formbg.png');
    width: 100%;
    /* Reduced to 80% */
    height: 1400px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* Reduced by 20% */
    flex-direction: column;
  }

  .formdiv {
    width: 80%;
    height: 763.058px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3.19px 15.952px rgba(0, 0, 0, 0.10));
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    /* Reduced by 20% */
    padding: 8px;
    /* Reduced by 20% */
  }

  .formleft {
    width: 100%;
    /* Reduced to 80% of 613.92px */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .formbglogo {
    width: 201.14px;
    /* Reduced to 80% of 613.92px */
    height: 100.44px;
    /* Reduced to 80% of 300.549px */
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -1;
    opacity: 0.05;
    margin-bottom: 52px;
    /* Reduced by 20% */
  }

  .formright {
    width: 95%;
    height: 243.87px;
    flex-shrink: 0;
    border-radius: 20px;
    background-image: url('./images/formrightlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .inplabel {
    color: #121935;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    margin-left: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    margin-top: 8px;
    /* Reduced by 20% */
  }

  .inpform {
    width: 400px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    color: #202020;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .inpform:focus {
    width: 400px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    border: 1px solid gainsboro;
    border-radius: 24px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .formlab {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .formbtn {
    width: 400px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #29166F;
    margin-top: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    /* Reduced by 20% */
    padding-left: 16px;
    /* Reduced by 20% */
    padding-right: 16px;
    /* Reduced by 20% */
  }

  .formp {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    /* Reduced by 20% */
  }

  .arrdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageopop {
    width: 241.221px;
    height: 106.252px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3px 5.292px rgba(0, 0, 0, 0.35));
    border-radius: 8px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-top: 50px;
  }

  .imgmaph1 {
    color: #29166F;
    font-family: Poppins;
    font-size: 10.769px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 174px;
    /* Reduced by 20% */
    height: 18.4px;
    /* Reduced by 20% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imgmapp {
    color: #0E0728;
    font-family: Poppins;
    font-size: 8.8px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 174.8px;
    /* Reduced by 20% */
  }

  .mapsvg {
    background-image: url('./images/mapsvg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 40.045px;
    height: 40.045px;
    stroke-width: 7px;
    stroke: #FFF;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 215px;
    /* Reduced by 20% */
    top: 25%;
  }

  .formright {
    display: flex;
    padding-left: 2.4%;
    /* Reduced by 20% */
    padding-top: 2.4%;
    /* Reduced by 20% */
  }

  .svgani {
    width: 9.6px;
    /* Reduced by 20% */
    height: 12.8px;
    /* Reduced by 20% */
    transition: transform 0.2s ease-in-out;
    animation: jiggle 0.5s ease-in-out infinite;
    /* Animation runs continuously */
  }

  .svgani:hover {
    animation: jiggle 0.5s ease-in-out infinite;
    /* Same animation on hover, but you can omit this if you want it to run continuously */
  }

  @keyframes jiggle {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .arrow11 {
    width: 14.545px;
    flex-shrink: 0;
  }

  .contimg {
    width: 55.26px;
    height: 55.26px;
    flex-shrink: 0;
    border-radius: 15px;
    position: absolute;
    top: -20px;
    left: 41%;
  }

  .Contactcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 55px;
  }

  .conth3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .conth4 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 520px) {
  * {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }

  .formbg {
    background-image: url('./images/formbg.png');
    width: 100%;
    /* Reduced to 80% */
    height: 1100px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    /* Reduced by 20% */
    flex-direction: column;
  }

  .formdiv {
    width: 400.35px;
    height: 763.058px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3.19px 15.952px rgba(0, 0, 0, 0.10));
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    /* Reduced by 20% */
    padding: 8px;
    /* Reduced by 20% */
  }

  .formleft {
    width: 100%;
    /* Reduced to 80% of 613.92px */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .formbglogo {
    width: 201.14px;
    /* Reduced to 80% of 613.92px */
    height: 100.44px;
    /* Reduced to 80% of 300.549px */
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -1;
    opacity: 0.05;
    margin-bottom: 52px;
    /* Reduced by 20% */
  }

  .formright {
    width: 360.976px;
    height: 243.87px;
    flex-shrink: 0;
    border-radius: 20px;
    background-image: url('./images/formrightlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .inplabel {
    color: #121935;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    margin-left: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    margin-top: 8px;
    /* Reduced by 20% */
  }

  .inpform {
    width: 336.771px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    color: #202020;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .inpform:focus {
    width: 336.771px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    border: 1px solid gainsboro;
    border-radius: 24px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .formlab {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .formbtn {
    width: 336.771px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #29166F;
    margin-top: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    /* Reduced by 20% */
    padding-left: 16px;
    /* Reduced by 20% */
    padding-right: 16px;
    /* Reduced by 20% */
  }

  .formp {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    /* Reduced by 20% */
  }

  .arrdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageopop {
    width: 241.221px;
    height: 106.252px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3px 5.292px rgba(0, 0, 0, 0.35));
    border-radius: 8px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-top: 50px;
  }

  .imgmaph1 {
    color: #29166F;
    font-family: Poppins;
    font-size: 10.769px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 174px;
    /* Reduced by 20% */
    height: 18.4px;
    /* Reduced by 20% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imgmapp {
    color: #0E0728;
    font-family: Poppins;
    font-size: 8.8px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 174.8px;
    /* Reduced by 20% */
  }

  .mapsvg {
    background-image: url('./images/mapsvg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 40.045px;
    height: 40.045px;
    stroke-width: 7px;
    stroke: #FFF;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 215px;
    /* Reduced by 20% */
    top: 25%;
  }

  .formright {
    display: flex;
    padding-left: 2.4%;
    /* Reduced by 20% */
    padding-top: 2.4%;
    /* Reduced by 20% */
  }

  .svgani {
    width: 9.6px;
    /* Reduced by 20% */
    height: 12.8px;
    /* Reduced by 20% */
    transition: transform 0.2s ease-in-out;
    animation: jiggle 0.5s ease-in-out infinite;
    /* Animation runs continuously */
  }

  .svgani:hover {
    animation: jiggle 0.5s ease-in-out infinite;
    /* Same animation on hover, but you can omit this if you want it to run continuously */
  }

  @keyframes jiggle {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .arrow11 {
    width: 14.545px;
    flex-shrink: 0;
  }

  .contimg {
    width: 55.26px;
    height: 55.26px;
    flex-shrink: 0;
    border-radius: 15px;
    position: absolute;
    top: -20px;
    left: 41%;
  }

  .Contactcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 55px;
  }

  .conth3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .conth4 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.h1ofhead3 {
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

/* .rotate{
  transform: rotate(180deg);
} */
.h2ofhead3 {
  color: #000;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .h1ofhead3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .h2ofhead3 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .h1ofhead3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .h2ofhead3 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .h1ofhead3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .h2ofhead3 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .h1ofhead3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }

  .h2ofhead3 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
}

.formbgofcontct {
  background-image: url('./images/Formcontactbg.png');
  width: 100%;
  max-width: 1600px;
  height: 1350px;
  margin-top: -100px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px;
  margin-bottom: -90px;
}

.formdiv {
  width: 1030px;
  height: 604.742px;
  flex-shrink: 0;
  background-color: #FFF;
  filter: drop-shadow(0px 3.19px 15.952px rgba(0, 0, 0, 0.10));
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  padding: 10px;
}

.formleft {
  position: relative;
  width: 613.92px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formbglogo {
  width: 613.92px;
  height: 300.549px;
  background-image: url('./images/formlogo.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  opacity: 0.05;
  top: 14%;
  left: 0%;
}

.formright {
  width: 441.229px;
  height: 559.278px;
  background-image: url('./images/formrightlogo.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.inplabel {
  width: auto;
  height: auto;
  flex-shrink: 0;
  color: #121935;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: start;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.inpform {
  width: 446.771px;
  height: 54.396px;
  flex-shrink: 0;
  fill: rgba(255, 255, 255, 0.80);
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.10);
  padding: 10px;
  border: 1px solid gainsboro;
  border-radius: 30px;
  color: #202020;
  font-size: 14px;
}

.inpform:focus {
  width: 446.771px;
  height: 54.396px;
  flex-shrink: 0;
  fill: rgba(255, 255, 255, 0.80);
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.10);
  padding: 10px;
  border: 1px solid gainsboro;
  border-radius: 30px;
  color: #202020;
  font-size: 16px;
  outline: none;
}

.formlab {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.formbtn {
  width: 446.771px;
  height: 54.396px;
  flex-shrink: 0;
  background-color: #29166F;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid gainsboro;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.formp {
  color: #FFF;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  /* 444.444% */
}

.arrdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageopop {
  width: 336px;
  height: 148px;
  flex-shrink: 0;
  background-color: #FFF;
  filter: drop-shadow(0px 3px 5.292px rgba(0, 0, 0, 0.35));
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgmaph1 {
  color: #29166F;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 236px;
  height: 23px;
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Hides the overflowed text */
  text-overflow: ellipsis;
  /* Adds '...' to indicate the overflow */
}

.imgmapp {
  color: #0E0728;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 236px;
}

.mapsvg {
  background-image: url('./images/mapsvg.svg');
  width: 55.779px;
  height: 55.779px;
  stroke-width: 7px;
  stroke: #FFF;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 310px;
  /* This moves it to the left */
  top: 25%;


}

.formright {
  display: flex;
}

.svgani {
  width: 9.6px;
  /* Reduced by 20% */
  height: 12.8px;
  /* Reduced by 20% */
  transition: transform 0.2s ease-in-out;
  animation: jiggle 0.5s ease-in-out infinite;
  /* Animation runs continuously */
}

.svgani:hover {
  animation: jiggle 0.5s ease-in-out infinite;
  /* Same animation on hover, but you can omit this if you want it to run continuously */
}

@keyframes jiggle {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.Contdiv1 {
  width: 320.484px;
  height: 172.607px;
  border-radius: 20px;
  flex-shrink: 0;
  position: relative;
  background-color: #FFF;
  margin-top: 65px;
}

.conth1 {
  padding-top: 20%;
  color: #29166F;
  text-align: center;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.conth2 {
  color: #121935;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;

  line-height: normal;
}

.conth3 {
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 24px;

}

.conth4 {
  color: #000;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 24px;

}

.arrow11 {
  width: 34.545px;
  flex-shrink: 0;
}

.contimg {
  width: 55.26px;
  height: 55.26px;
  flex-shrink: 0;
  border-radius: 15px;
  position: absolute;
  top: -20px;
  left: 41%;
}

.Contactcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 31px;
  margin-bottom: 55px;
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 850px) and (max-width: 1024px) {
  * {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }

  .formbgofcontct {
    background-image: url('./images/Formcontactbg.png');
    width: 100%;
    /* Reduced to 80% */
    height: 1250px;
    /* Reduced to 80% of 1149.625px */
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -150px;
    flex-direction: column;
  }

  .formdiv {
    width: 866.45px;
    /* Reduced to 80% of 1083.061px */
    height: 483.79px;
    /* Reduced to 80% of 604.742px */
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3.19px 15.952px rgba(0, 0, 0, 0.10));
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    /* Reduced by 20% */
    padding: 8px;
    /* Reduced by 20% */
  }

  .formleft {
    width: 491.14px;
    /* Reduced to 80% of 613.92px */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .formbglogo {
    width: 491.14px;
    /* Reduced to 80% of 613.92px */
    height: 240.44px;
    /* Reduced to 80% of 300.549px */
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -1;
    opacity: 0.05;
    margin-bottom: 52px;
    /* Reduced by 20% */
  }

  .formright {
    width: 353px;
    /* Reduced to 80% of 441.229px */
    height: 447.42px;
    /* Reduced to 80% of 559.278px */
    background-image: url('./images/formrightlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .inplabel {
    width: auto;
    height: auto;
    flex-shrink: 0;
    color: #121935;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    margin-left: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    margin-top: 8px;
    /* Reduced by 20% */
  }

  .inpform {
    width: 357.42px;
    /* Reduced to 80% of 446.771px */
    height: 43.52px;
    /* Reduced to 80% of 54.396px */
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    color: #202020;
    font-size: 11.2px;
    /* Reduced by 20% */
  }

  .inpform:focus {
    width: 357.42px;
    height: 43.52px;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    border: 1px solid gainsboro;
    border-radius: 24px;
    color: #202020;
    font-size: 12.8px;
    /* Reduced by 20% */
    outline: none;
  }

  .formlab {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .formbtn {
    width: 357.42px;
    /* Reduced to 80% of 446.771px */
    height: 43.52px;
    /* Reduced to 80% of 54.396px */
    flex-shrink: 0;
    background-color: #29166F;
    margin-top: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    /* Reduced by 20% */
    padding-left: 16px;
    /* Reduced by 20% */
    padding-right: 16px;
    /* Reduced by 20% */
  }

  .formp {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    /* Reduced by 20% */
  }

  .arrdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageopop {
    width: 268.8px;
    /* Reduced to 80% of 336px */
    height: 118.4px;
    /* Reduced to 80% of 148px */
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3px 5.292px rgba(0, 0, 0, 0.35));
    border-radius: 8px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgmaph1 {
    color: #29166F;
    font-family: Poppins;
    font-size: 12px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 188.8px;
    /* Reduced by 20% */
    height: 18.4px;
    /* Reduced by 20% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imgmapp {
    color: #0E0728;
    font-family: Poppins;
    font-size: 12.8px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 188.8px;
    /* Reduced by 20% */
  }

  .mapsvg {
    background-image: url('./images/mapsvg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 44.62px;
    /* Reduced by 20% */
    height: 44.62px;
    /* Reduced by 20% */
    stroke-width: 7px;
    stroke: #FFF;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 248px;
    /* Reduced by 20% */
    top: 25%;
  }

  .formright {
    display: flex;
    padding-left: 2.4%;
    /* Reduced by 20% */
    padding-top: 2.4%;
    /* Reduced by 20% */
  }

  .svgani {
    width: 9.6px;
    /* Reduced by 20% */
    height: 12.8px;
    /* Reduced by 20% */
    transition: transform 0.2s ease-in-out;
    animation: jiggle 0.5s ease-in-out infinite;
    /* Animation runs continuously */
  }

  .svgani:hover {
    animation: jiggle 0.5s ease-in-out infinite;
    /* Same animation on hover, but you can omit this if you want it to run continuously */
  }

  @keyframes jiggle {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .arrow11 {
    width: 34.545px;
    flex-shrink: 0;
  }

  .Contdiv1 {
    width: 256.3872px;
    height: 138.0856px;

    border-radius: 20px;
    flex-shrink: 0;
    position: relative;
    background-color: #FFF;
    margin-top: 65px;
  }

  .Contactcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 55px;
  }

  .conth1 {
    padding-top: 20%;
    color: #29166F;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .conth2 {
    color: #121935;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;

    line-height: normal;
  }

  .conth3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 24px;

  }

  .conth4 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 24px;

  }

  .contimg {
    width: 55.26px;
    height: 55.26px;
    flex-shrink: 0;
    border-radius: 15px;
    position: absolute;
    top: -18px;
    left: 38%;
  }

  .Contactcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 31px;
    margin-bottom: 55px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 520px) and (max-width: 850px) {
  * {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }

  .formbgofcontct {
    background-image: url('./images/contactmblbg.png');
    width: 100%;
    /* Reduced to 80% */
    height: 2100px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    /* Reduced by 20% */
    flex-direction: column;
  }

  .formdiv {
    width: 80%;
    height: 763.058px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3.19px 15.952px rgba(0, 0, 0, 0.10));
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    /* Reduced by 20% */
    padding: 8px;
    /* Reduced by 20% */
  }

  .formleft {
    width: 100%;
    /* Reduced to 80% of 613.92px */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .formbglogo {
    width: 201.14px;
    /* Reduced to 80% of 613.92px */
    height: 100.44px;
    /* Reduced to 80% of 300.549px */
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -1;
    opacity: 0.05;
    margin-bottom: 52px;
    /* Reduced by 20% */
  }

  .formright {
    width: 95%;
    height: 243.87px;
    flex-shrink: 0;
    border-radius: 20px;
    background-image: url('./images/formrightlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .inplabel {
    color: #121935;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    margin-left: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    margin-top: 8px;
    /* Reduced by 20% */
  }

  .inpform {
    width: 400px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    color: #202020;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .inpform:focus {
    width: 400px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    border: 1px solid gainsboro;
    border-radius: 24px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .formlab {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .formbtn {
    width: 400px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #29166F;
    margin-top: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    /* Reduced by 20% */
    padding-left: 16px;
    /* Reduced by 20% */
    padding-right: 16px;
    /* Reduced by 20% */
  }

  .formp {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    /* Reduced by 20% */
  }

  .arrdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageopop {
    width: 241.221px;
    height: 106.252px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3px 5.292px rgba(0, 0, 0, 0.35));
    border-radius: 8px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-top: 50px;
  }

  .imgmaph1 {
    color: #29166F;
    font-family: Poppins;
    font-size: 10.769px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 174px;
    /* Reduced by 20% */
    height: 18.4px;
    /* Reduced by 20% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imgmapp {
    color: #0E0728;
    font-family: Poppins;
    font-size: 8.8px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 174.8px;
    /* Reduced by 20% */
  }

  .mapsvg {
    background-image: url('./images/mapsvg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 40.045px;
    height: 40.045px;
    stroke-width: 7px;
    stroke: #FFF;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 215px;
    /* Reduced by 20% */
    top: 25%;
  }

  .formright {
    display: flex;
    padding-left: 2.4%;
    /* Reduced by 20% */
    padding-top: 2.4%;
    /* Reduced by 20% */
  }

  .svgani {
    width: 9.6px;
    /* Reduced by 20% */
    height: 12.8px;
    /* Reduced by 20% */
    transition: transform 0.2s ease-in-out;
    animation: jiggle 0.5s ease-in-out infinite;
    /* Animation runs continuously */
  }

  .svgani:hover {
    animation: jiggle 0.5s ease-in-out infinite;
    /* Same animation on hover, but you can omit this if you want it to run continuously */
  }

  @keyframes jiggle {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .arrow11 {
    width: 14.545px;
    flex-shrink: 0;
  }

  .contimg {
    width: 55.26px;
    height: 55.26px;
    flex-shrink: 0;
    border-radius: 15px;
    position: absolute;
    top: -20px;
    left: 41%;
  }

  .Contactcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 55px;
  }

  .conth3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .conth4 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 520px) {
  * {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }

  .formbgofcontct {
    background-image: url('./images/contactmblbg.png');
    width: 100%;
    /* Reduced to 80% */
    height: 2100px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
    /* Reduced by 20% */
    flex-direction: column;
  }

  .formdiv {
    width: 400.35px;
    height: 763.058px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3.19px 15.952px rgba(0, 0, 0, 0.10));
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    /* Reduced by 20% */
    padding: 8px;
    /* Reduced by 20% */
  }

  .formleft {
    width: 100%;
    /* Reduced to 80% of 613.92px */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .formbglogo {
    width: 201.14px;
    /* Reduced to 80% of 613.92px */
    height: 100.44px;
    /* Reduced to 80% of 300.549px */
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -1;
    opacity: 0.05;
    margin-bottom: 52px;
    /* Reduced by 20% */
  }

  .formright {
    width: 360.976px;
    height: 243.87px;
    flex-shrink: 0;
    border-radius: 20px;
    background-image: url('./images/formrightlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .inplabel {
    color: #121935;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    margin-left: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    margin-top: 8px;
    /* Reduced by 20% */
  }

  .inpform {
    width: 336.771px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    color: #202020;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .inpform:focus {
    width: 336.771px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    border: 1px solid gainsboro;
    border-radius: 24px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .formlab {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .formbtn {
    width: 336.771px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #29166F;
    margin-top: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    /* Reduced by 20% */
    padding-left: 16px;
    /* Reduced by 20% */
    padding-right: 16px;
    /* Reduced by 20% */
  }

  .formp {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    /* Reduced by 20% */
  }

  .arrdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageopop {
    width: 241.221px;
    height: 106.252px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3px 5.292px rgba(0, 0, 0, 0.35));
    border-radius: 8px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-top: 50px;
  }

  .imgmaph1 {
    color: #29166F;
    font-family: Poppins;
    font-size: 10.769px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 174px;
    /* Reduced by 20% */
    height: 18.4px;
    /* Reduced by 20% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imgmapp {
    color: #0E0728;
    font-family: Poppins;
    font-size: 8.8px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 174.8px;
    /* Reduced by 20% */
  }

  .mapsvg {
    background-image: url('./images/mapsvg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 40.045px;
    height: 40.045px;
    stroke-width: 7px;
    stroke: #FFF;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 215px;
    /* Reduced by 20% */
    top: 25%;
  }

  .formright {
    display: flex;
    padding-left: 2.4%;
    /* Reduced by 20% */
    padding-top: 2.4%;
    /* Reduced by 20% */
  }

  .svgani {
    width: 9.6px;
    /* Reduced by 20% */
    height: 12.8px;
    /* Reduced by 20% */
    transition: transform 0.2s ease-in-out;
    animation: jiggle 0.5s ease-in-out infinite;
    /* Animation runs continuously */
  }

  .svgani:hover {
    animation: jiggle 0.5s ease-in-out infinite;
    /* Same animation on hover, but you can omit this if you want it to run continuously */
  }

  @keyframes jiggle {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .arrow11 {
    width: 14.545px;
    flex-shrink: 0;
  }

  .contimg {
    width: 55.26px;
    height: 55.26px;
    flex-shrink: 0;
    border-radius: 15px;
    position: absolute;
    top: -20px;
    left: 41%;
  }

  .Contactcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 55px;
  }

  .conth3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .conth4 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 430px) {
  * {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }

  .formbgofcontct {
    background-image: url('./images/contactmblbg.png');
    width: 100%;
    /* Reduced to 80% */
    height: 2100px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
    /* Reduced by 20% */
    flex-direction: column;
  }

  .formdiv {
    width: 310.976px;
    height: 763.058px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3.19px 15.952px rgba(0, 0, 0, 0.10));
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    /* Reduced by 20% */
    padding: 8px;
    /* Reduced by 20% */
  }

  .formleft {
    width: 100%;
    /* Reduced to 80% of 613.92px */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .formbglogo1 {
    width: 300px;
    /* Reduced to 80% of 613.92px */
    height: 200px;
    /* Reduced to 80% of 300.549px */
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -1;
    opacity: 0.05;
    margin-bottom: 52px;
    /* Reduced by 20% */
  }

  .formright {
    width: 300px;
    height: 243.87px;
    flex-shrink: 0;
    border-radius: 20px;
    background-image: url('./images/formrightlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .inplabel {
    color: #121935;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    margin-left: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    margin-top: 8px;
    /* Reduced by 20% */
  }

  .inpform {
    width: 300px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .inpform:focus {
    width: 300px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    fill: rgba(255, 255, 255, 0.80);
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
    padding: 8px;
    border: 1px solid gainsboro;
    border-radius: 24px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }

  .formlab {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .formbtn {
    width: 300px;
    height: 54.396px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #29166F;
    margin-top: 8px;
    /* Reduced by 20% */
    margin-bottom: 8px;
    /* Reduced by 20% */
    border: 1px solid gainsboro;
    border-radius: 24px;
    /* Reduced by 20% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    /* Reduced by 20% */
    padding-left: 16px;
    /* Reduced by 20% */
    padding-right: 16px;
    /* Reduced by 20% */
  }

  .formp {
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 12px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    /* Reduced by 20% */
  }

  .arrdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageopop {
    width: 241.221px;
    height: 106.252px;
    flex-shrink: 0;
    flex-shrink: 0;
    background-color: #FFF;
    filter: drop-shadow(0px 3px 5.292px rgba(0, 0, 0, 0.35));
    border-radius: 8px;
    /* Reduced by 20% */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-top: 50px;
  }

  .imgmaph1 {
    color: #29166F;
    font-family: Poppins;
    font-size: 10.769px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 174px;
    /* Reduced by 20% */
    height: 18.4px;
    /* Reduced by 20% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imgmapp {
    color: #0E0728;
    font-family: Poppins;
    font-size: 8.8px;
    /* Reduced by 20% */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 174.8px;
    /* Reduced by 20% */
  }

  .mapsvg {
    background-image: url('./images/mapsvg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 40.045px;
    height: 40.045px;
    stroke-width: 7px;
    stroke: #FFF;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 215px;
    /* Reduced by 20% */
    top: 25%;
  }

  .formright {
    display: flex;
    padding-left: 2.4%;
    /* Reduced by 20% */
    padding-top: 2.4%;
    /* Reduced by 20% */
  }

  .svgani {
    width: 9.6px;
    /* Reduced by 20% */
    height: 12.8px;
    /* Reduced by 20% */
    transition: transform 0.2s ease-in-out;
    animation: jiggle 0.5s ease-in-out infinite;
    /* Animation runs continuously */
  }

  .svgani:hover {
    animation: jiggle 0.5s ease-in-out infinite;
    /* Same animation on hover, but you can omit this if you want it to run continuously */
  }

  @keyframes jiggle {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .arrow11 {
    width: 14.545px;
    flex-shrink: 0;
  }

  .Contdiv1 {
    width: 300px;
  }

  .contimg {
    width: 55.26px;
    height: 55.26px;
    flex-shrink: 0;
    border-radius: 15px;
    position: absolute;
    top: -20px;
    left: 41%;
  }

  .Contactcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 55px;
  }

  .conth3 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .conth4 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.App-footer {
  background-image: url("./images/backgroundimagefooter.png");
  color: white;
  text-align: center;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Resetting default list styles */
.App-footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;
}

.App-footer ul li a {
  color: white;
  text-decoration: none;
}

.App-footer ul li a:hover {
  text-decoration: underline;
}

/* Container for footer sections */
.footer-uls {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 8%;
}

/* Logo styling */
.App-logo1 {
  height: 150px;
  width: 218px;
}

/* Style for each section of the footer */
.footer-ul1 {
  flex-direction: column;
  justify-content: start;
  text-align: left;
}

/* Links within each footer section */
.footer-links {
  padding: 6px;
  text-decoration: none;
  font-weight: 500;
  justify-content: start;
  text-align: left;
  font-size: 12px;
  font-family: 'Poppins';
  display: flow-root;
}

/* Style for icons */
.RectangleFooter {
  height: 7%;
  width: 7%;
  margin-right: 10px;
}

.footerheadlinks {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Playfair Display';
  margin-bottom: 14px;
}

.EmailTopImginftr {
  padding: 10px;
  background-color: white;
  border: 2px solid white;
  border-radius: 20px;
  align-items: center;
}

/* Responsive styles for tablet and mobile screens */
@media (max-width: 1024px) {
  .footer-uls {
    flex-direction: column;
    gap: 50px;
  }

  .footer-ul1 {
    text-align: center;
  }

  .hide-on-sm {
    display: none;
  }

  .App-logo1 {
    height: 120px;
    width: 170px;
  }
}

@media (max-width: 768px) {
  .App-footer {
    background-image: url("./images/backgroundimagefooter.png");
    color: white;
    text-align: center;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: -webkit-fill-available;
  }

  .footer-uls {
    flex-direction: column;
    gap: 30px;
    margin-top: 14%;
  }

  /* .App-footer {
      padding: 20px 10px;
  } */

  .App-logo1 {
    height: 100px;
    width: 150px;
  }

  .footer-links {
    padding: 4px;
  }

  .hide-on-sm {
    display: none;
  }
}

@media (max-width: 480px) {
  .App-footer {
    background-image: url("./images/backgroundimagefooter.png");
    color: white;
    text-align: center;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: -webkit-fill-available;
  }

  .hide-on-sm {
    display: none;
  }

  .footer-uls {
    flex-direction: column;
    gap: 20px;
    margin-top: 13%;
  }

  .App-logo1 {
    height: 80px;
    width: 120px;
  }

  .RectangleFooter {
    height: 10%;
    width: 10%;
  }
}

.head {
  width: 100%;
  height: 700px;
  position: relative;
  max-width: 1600px;
  flex-shrink: 0;
}

.head1bg1 {
  width: 100%;
  height: 735.548px;
  background-image: url('./images/head1.png');
  background-repeat: no-repeat;
  object-fit: fill;
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

}

.head1bg2 {
  width: 100%;
  height: 735.548px;
  background-image: url('./images/head3.png');
  background-repeat: no-repeat;
  object-fit: fill;
  position: absolute;
  z-index: 2;

}

.head1bg3 {
  width: 100%;
  height: 735.548px;
  background-image: url('./images/head2.png');
  background-repeat: no-repeat;
  object-fit: fill;
  position: absolute;
  z-index: 1;

}

.aboutussec1h1 {
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 15%;
}

.aboutussec1h2 {
  color: #000;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.hr-parent {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 20%;
}

.hr-line1 {
  width: 92.5%;
  margin-bottom: -13px;
}

.greenhr1 {
  width: 45.5%;
  margin-top: -12px;
}

.aboutussec1p1 {
  width: 60%;
  height: 69.033px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  line-height: normal;
}
@media (min-width: 1200px) and (max-width: 1400px) {

  .head {
    width: 100%;
    height: 700px;
    position: relative;
    max-width: 1600px;
    flex-shrink: 0;
  }
  
  .head1bg1 {
    width: 100%;
    height: 735.548px;
    background-image: url('./images/head1.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
  }
  
  .head1bg2 {
    width: 100%;
    height: 735.548px;
    background-image: url('./images/head3.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 2;
  
  }
  
  .head1bg3 {
    width: 100%;
    height: 735.548px;
    background-image: url('./images/head2.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 1;
  
  }
  
  .aboutussec1h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    margin-top: 0%;
  }
  
  .aboutussec1h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 65px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }
  
  .hr-parent {
    margin-bottom: 10px;
    margin-top: 10px;
    width: 20%;
  }
  
  .hr-line1 {
    width: 92.5%;
    margin-bottom: -13px;
  }
  
  .greenhr1 {
    width: 45.5%;
    margin-top: -12px;
  }
  
  .aboutussec1p1 {
    width: 80%;
    height: 69.033px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
    line-height: normal;
  }
}
@media (min-width:769px) and (max-width: 1199px) {
  .head1bg1 {
    width: 100%;
    height: 735.548px;
    background-image: url('./images/head1.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
  }
  .aboutussec1h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    margin-top: 0%;
  }
}
@media (min-width:550px) and (max-width: 1100px) {
  .aboutussec1p1 {
    width: 100%;
    height: 69.033px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
    line-height: normal;
  }
}
/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .head {
    width: 100%;
    height: 700px;
    position: relative;
    max-width: 1600px;
    flex-shrink: 0;
  }

  .head1bg1 {
    width: 100%;
    height: 735.548px;
    background-image: url('./images/head1.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

  .head1bg2 {
    width: 100%;
    height: 735.548px;
    background-image: url('./images/head3.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 2;

  }

  .head1bg3 {
    width: 100%;
    height: 735.548px;
    background-image: url('./images/head2.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 1;

  }

  .aboutussec1h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 39.713px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    margin-top: 0%;
  }

  .aboutussec1h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 43.022px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;

  }

  .hr-parent {
    margin-bottom: 10px;
    margin-top: 10px;
    width: 56%;
  }

  .hr-line1 {
    width: 90%;
    margin-bottom: -13px;
  }

  .greenhr1 {
    width: 34.5%;
    margin-top: -12px;
  }
  .aboutussec1p1 {
    width: 100%;
    height: 69.033px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
    line-height: normal;
  }

}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .head {
    width: 100%;
    height: 700px;
    position: relative;
    max-width: 1600px;
    flex-shrink: 0;
  }

  .head1bg1 {
    width: 100%;
    height: 650px;
    background-image: url('./images/mobilhead1.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

  .head1bg2 {
    width: 100%;
    height: 650px;
    background-image: url('./images/mobilhead2.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 2;

  }

  .head1bg3 {
    width: 100%;
    height: 650px;
    background-image: url('./images/mobilehead3.png');
    background-repeat: no-repeat;
    object-fit: fill;
    position: absolute;
    z-index: 1;

  }

  .aboutussec1h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 39.713px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    margin-top: 30%;
  }

  .aboutussec1h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 43.022px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }

  .hr-parent {
    margin-bottom: 10px;
    margin-top: 10px;
    width: 56%;
  }

  .hr-line1 {
    width: 90%;
    margin-bottom: -13px;
  }

  .greenhr1 {
    width: 34.5%;
    margin-top: -12px;
  }

  .aboutussec1p1 {
    width: 95%;
    height: 190px;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 15.885px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}

.about1bg {
  width: 100%;
  min-height: 1171px;
  flex-shrink: 0;
  background-image: url('./images/about1bg.png');
  max-width: 1600px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.h1about1 {
  color: #28156D;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-top: 50px;
}

.h2about1 {
  color: #000;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-top: 10px;

}

.pabout1 {
  width: 569px;
  flex-shrink: 0;
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  text-align: start;
  margin-top: 20px;
  position: relative;

}

.p2about1 {
  height: 407px;
  flex-shrink: 0;
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  text-align: start;
  padding-top: 15px;
  position: relative;
}

.about1right {
  width: 600px;
  height: 600px;
}

.parent {
  width: 600px;
  height: 600px;
  position: relative;
}


.gird1 {
  width: 517.872px;
  height: 441.37px;
  background-image: url('./images/gird1.png');
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;
  border-radius: 20px;
  z-index: 1;

}

.gird2 {
  width: 276.588px;
  height: 259.34px;
  background-image: url('./images/gird2.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 20px;
  border-right: 10px solid #F4F3F8;
  border-top: 10px solid #F4F3F8;
  top: 290px;
  left: -60px;
  z-index: 2;
}

.gird3 {
  width: 220.877px;
  height: 229.4px;
  background-image: url('./images/gird3.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: absolute;
  z-index: 3;
  border-radius: 20px;
  left: 220px;
  top: 460px;
}

.div4 {
  width: 121.044px;
  height: 103.48px;
  flex-shrink: 0;
  background-color: #00AA14;
  border-radius: 20px;
  margin-top: 457px;
  position: absolute;
  z-index: 1;
  left: -70px;
}

.formbglogo {
  width: 613.92px;
  height: 300.549px;
  background-image: url('./images/formlogo.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
  opacity: 0.05;
  top: 70%;
  left: 73%;
}

/* Large Screens (1441px and up) */
@media (min-width: 1100px) and (max-width: 1440px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .about1bg {
    width: 100%;
    min-height: 1171px;
    flex-shrink: 0;
    background-image: url('./images/about1bg.png');
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -200px;
    overflow: hidden;
    object-fit: contain;
    position: relative;
  }

  .h1about1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start !important;
    margin-top: 10px;
  }

  .h2about1 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start !important;
    margin-top: 10px;

  }

  .pabout1 {
    width: 569px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    text-align: start;
    margin-top: 30px;
    position: relative;

  }

  .p2about1 {
    height: 407px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-align: start;
    padding-top: 30px;
    position: relative;
  }

  .about1right {
    width: 469px;
    height: 469px;


  }

  .parent {
    width: 469px;
    position: relative;
  }


  .gird1 {
    width: 417.872px;
    height: 341.37px;
    background-image: url('./images/gird1.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 20px;
    z-index: 1;

  }

  .gird2 {
    width: 200px;
    height: 182px;
    background-image: url('./images/gird2.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 20px;
    border-right: 10px solid #F4F3F8;
    border-top: 10px solid #F4F3F8;
    top: 290px;
    left: -60px;
    z-index: 2;
  }

  .gird3 {
    width: 170px;
    height: 180px;
    background-image: url('./images/gird3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: absolute;
    z-index: 3;
    border-radius: 20px;
    left: 150px;
    top: 350px;
  }

  .div4 {
    width: 121.044px;
    height: 103.48px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 20px;
    margin-top: 380px;
    position: absolute;
    z-index: 1;
    left: -70px;
  }

  .formbglogo {
    width: 613.92px;
    height: 300.549px;
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 1;
    opacity: 0.05;
    top: 66%;
    left: 74%;
  }

}

/* Small Laptops (769px to 1024px) */
@media (min-width: 860px) and (max-width: 1100px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .about1bg {
    width: 100%;
    min-height: 100%;
    flex-shrink: 0;
    background-image: url('./images/about1tbl.png');
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .h1about1 {
    color: #28156D;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: start;
    margin-top: 10px;

  }

  .h2about1 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: start;
    margin-top: 10px;

  }


  .pabout1 {
    width: 469px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    text-align: start;
    margin-top: 30px;
    position: relative;

  }

  .p2about1 {
    width: 369px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-align: start;
    padding-top: 30px;
    position: relative;
  }

  .about1right {
    width: 390px;


  }

  .parent {
    width: 350px;
    position: relative;
  }

  .gird1 {
    width: 300.872px;
    height: 241.37px;
    background-image: url('./images/gird1.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 20px;
    z-index: 1;

  }

  .gird2 {
    width: 180px;
    height: 150px;
    background-image: url('./images/gird2.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 20px;
    border-right: 10px solid #F4F3F8;
    border-top: 10px solid #F4F3F8;
    top: 200px;
    left: -60px;
    z-index: 2;
  }

  .gird3 {
    width: 170px;
    height: 180px;
    background-image: url('./images/gird3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: absolute;
    z-index: 3;
    border-radius: 20px;
    left: 130px;
    top: 250px;
  }

  .div4 {
    width: 81.044px;
    height: 83.48px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 20px;
    margin-top: 275px;
    position: absolute;
    z-index: 1;
    left: -70px;
  }

  .formbglogo {
    width: 300.92px;
    height: 150.549px;
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 1;
    opacity: 0.05;
    top: 60%;
    left: 73%;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 600px) and (max-width: 859px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .about1bg {
    width: 100%;
    min-height: 700px;
    flex-shrink: 0;
    background-image: url('./images/about1tbl.png');
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    gap: 20px;
    position: relative;
  }

  .h1about1 {
    color: #28156D;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: start !important;
    margin-top: 10px;

  }

  .h2about1 {
    width: 380px;
    color: #28156D;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: start !important;
    margin-top: 10px;

  }

  .pabout1 {
    width: 330px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: start;
    margin-top: 10px;
    position: relative;

  }

  .p2about1 {
    width: 280px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-align: start;
    padding-top: 10px;
    position: relative;
  }

  .about1right {
    height: 350px;
    width: 300px;

  }

  .about1cen {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .parent {
    height: 350px;
    width: 300px;
    position: relative;

  }


  .gird1 {
    width: 200.872px;
    height: 141.37px;
    background-image: url('./images/gird1.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 10px;
    z-index: 1;
    left: 50px;

  }

  .gird2 {
    width: 150px;
    height: 120px;
    background-image: url('./images/gird2.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 10px;
    border-right: 4px solid #F4F3F8;
    border-top: 4px solid #F4F3F8;
    top: 100px;
    left: 10px;
    z-index: 2;
  }

  .gird3 {
    width: 170px;
    height: 180px;
    background-image: url('./images/gird3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    left: 130px;
    top: 150px;
  }

  .div4 {
    width: 70.044px;
    height: 53.48px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 10px;
    margin-top: 170px;
    position: absolute;
    z-index: 1;
    left: 7px;
  }

  .formbglogo {
    width: 300.92px;
    height: 150.549px;
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 1;
    opacity: 0.05;
    top: 60%;
    left: 73%;
  }
}

/* Tablets (481px to 768px) */
@media (max-width: 600px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .about1bg {
    width: 100%;
    min-height: 1171px;
    flex-shrink: 0;
    background-image: url('./images/about1bg.png');
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }

  .h1about1 {
    color: #28156D;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin-top: 10px;

  }

  .h2about1 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin-top: 10px;

  }

  .pabout1 {
    width: 300px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-top: 30px;
    position: relative;

  }

  .p2about1 {
    width: 300px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    padding-top: 30px;
    position: relative;
  }

  .about1right {
    width: 300px;



  }

  .about1cen {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .parent {
    height: 350px;
    width: 300px;
    position: relative;

  }


  .gird1 {
    width: 200.872px;
    height: 141.37px;
    background-image: url('./images/gird1.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 10px;
    z-index: 1;
    left: 50px;

  }

  .gird2 {
    width: 150px;
    height: 120px;
    background-image: url('./images/gird2.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 10px;
    border-right: 4px solid #F4F3F8;
    border-top: 4px solid #F4F3F8;
    top: 100px;
    left: 10px;
    z-index: 2;
  }

  .gird3 {
    width: 170px;
    height: 180px;
    background-image: url('./images/gird3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    left: 130px;
    top: 150px;
  }

  .div4 {
    width: 70.044px;
    height: 53.48px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 10px;
    margin-top: 170px;
    position: absolute;
    z-index: 1;
    left: 7px;
  }

  .formbglogo {
    width: 200.92px;
    height: 100.549px;
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 1;
    opacity: 0.05;
    top: 70%;
    left: 73%;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .about1bg {
    width: 100%;
    height: 1404.442px;
    flex-shrink: 0;
    background-image: url('./images/about1mblbg.png');
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    margin-top: -200px;
  }

  .h1about1 {
    color: #28156D;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    position: absolute;
    z-index: 2;
    top: 100px;

  }

  .h2about1 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin-top: 10px;
    position: absolute;
    z-index: 2;
    top: 150px;

  }

  .pabout1 {
    width: 300px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    margin-top: 139px;
    position: relative;

  }

  .p2about1 {
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    padding-top: 30px;
    position: relative;

  }

  .about1right {
    width: 300px;



  }

  .about1cen {
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .parent {
    height: 350px;
    width: 300px;
    position: relative;
    margin-top: 50px;

  }


  .gird1 {
    width: 354.072px;
    height: 301.767px;
    background-image: url('./images/gird1.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 10px;
    z-index: 1;
    left: -20px;

  }

  .gird2 {
    width: 189.105px;
    height: 177.312px;
    flex-shrink: 0;
    background-image: url('./images/gird2.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 10px;
    border-right: 4px solid #F4F3F8;
    border-top: 4px solid #F4F3F8;
    top: 207px;
    left: -30px;
    z-index: 2;
  }

  .gird3 {
    width: 151.015px;
    height: 156.842px;
    background-image: url('./images/gird3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    left: 170px;
    top: 320px;
  }

  .div4 {
    width: 82.759px;
    height: 70.75px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 10px;
    margin-top: 170px;
    position: absolute;
    z-index: 1;
    left: -40px;
    top: 155px;
  }

  .formbglogo {
    display: none;
    width: 200.92px;
    height: 100.549px;
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 1;
    opacity: 0.05;
    top: 70%;
    left: 73%;
  }
}

/* Mobile Devices (up to 480px) */
@media (min-width: 320px) and (max-width: 481px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .about1bg {
    width: 100%;
    height: 100vh;
    /* Change this value to a percentage or a specific height */
    flex-shrink: 0;
    background-image: url('./images/about1mblbg.png');
    background-size: cover;
    /* Ensures the image covers the entire container */
    background-position: bottom;
    /* Keeps the bottom part (curves) visible */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    margin-top: -200px;
  }

  .h1about1 {
    color: #28156D;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    position: absolute;
    z-index: 2;
    top: 100px;

  }

  .h2about1 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin-top: 10px;
    position: absolute;
    z-index: 2;
    top: 150px;

  }

  .pabout1 {
    width: 300px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    text-align: start;
    margin-top: 44px;

    position: relative;

  }

  .p2about1 {
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    padding-top: 30px;
    position: relative;

  }

  .about1right {
    width: 300px;



  }

  .about1cen {
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .parent {
    height: 350px;
    width: 300px;
    position: relative;
    margin-top: 120px;

  }


  .gird1 {
    width: 255.072px;
    height: 228.767px;
    background-image: url('./images/gird1.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 10px;
    z-index: 1;
    left: 28px;

  }

  .gird2 {
    width: 109.105px;
    height: 102.312px;
    flex-shrink: 0;
    background-image: url('./images/gird2.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    border-radius: 10px;
    border-right: 4px solid #F4F3F8;
    border-top: 4px solid #F4F3F8;
    top: 179px;
    left: 0px;
    z-index: 2;
  }

  .gird3 {
    width: 115.015px;
    height: 121.842px;
    background-image: url('./images/gird3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    left: 117px;
    top: 235px;
  }

  .div4 {
    width: 82.759px;
    height: 70.75px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 10px;
    margin-top: 170px;
    position: absolute;
    z-index: 1;
    left: -3px;
    top: 43px;

  }

  .formbglogo {
    display: none;
    width: 200.92px;
    height: 100.549px;
    background-image: url('./images/formlogo.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 1;
    opacity: 0.05;
    top: 70%;
    left: 73%;
  }
}

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

.phar2body {
  width: 100%;
  height: 1050px;
  background: #FFF;
  position: relative;
  margin-top: 100px;
}

.Phar2div1 {
  width: 538.901px;
  height: 598.901px;
  background-image: url('./images/phar1.jfif');
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  border-radius: 20px;
  position: absolute;
  left: 150px;
  z-index: 1;
}

.Phar2div2 {
  width: 876.003px;
  height: 318.901px;
  background-position-x: 50%;
  background-position-y: 80%;
  background-image: url('./images/phar2.jfif');
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  border-radius: 20px;
  position: absolute;
  top: 450px;
  left: 350px;
  z-index: 2;
}

.Phar2div3 {
  width: 268.42px;
  height: 252.711px;
  flex-shrink: 0;
  background-position-x: 50%;
  background-position-y: 80%;
  background-image: url('./images/phar3.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 420px;
  left: 1100px;
  z-index: 3;
  border: 10px solid #fff;
  border-radius: 20px;
}

.phar2h1 {
  color: #28156D;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  text-align: start;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Phar2div4 {
  width: 380px;
  display: block;
  text-align: start;
  border-radius: 20px;
  position: absolute;
  top: 50px;
  left: 750px;
  z-index: 4;
}

.phar2h2 {
  color: #000;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  text-align: start;
  font-weight: 400;
  line-height: normal;
  margin-top: -1px;
  margin-bottom: 12px;
}

.phar2p1 {
  width: 650px;
  height: 210px;
  flex-shrink: 0;
  color: #000;
  text-align: start;
  font-family: 'Poppins';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (min-width: 1240px) and (max-width: 1440px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar2body {
    width: 100%;
    height: 1050px;
    background: #FFF;
    position: relative;
    margin-top: 100px;
  }
  
  .Phar2div1 {
    width: 431.12px;
    height: 479.12px;
    
    background-image: url('./images/phar1.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 20px;
    position: absolute;
    left: 150px;
    z-index: 1;
  }
  
  .Phar2div2 {
    width: 700.80px;
    height: 255.12px;
    
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar2.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 20px;
    position: absolute;
    top: 450px;
    left: 350px;
    z-index: 2;
  }
  
  .Phar2div3 {
    width: 214.74px;
    height: 202.17px;    
    flex-shrink: 0;
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar3.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 420px;
    left: 1000px;
    z-index: 3;
    border: 10px solid #fff;
    border-radius: 20px;
  }
  
  .phar2h1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 55px;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .Phar2div4 {
    width: 380px;
    display: block;
    text-align: start;
    border-radius: 20px;
    position: absolute;
    top: 50px;
    left: 650px;
    z-index: 4;
  }
  
  .phar2h2 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    text-align: start;
    font-weight: 400;
    line-height: normal;
    margin-top: -1px;
    margin-bottom: 12px;
  }
  
  .phar2p1 {
    width: 550px;
    height: 210px;
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: 'Poppins';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
}


@media (min-width: 769px) and (max-width: 1240px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar2body {
    width: 100%;
    height: 900px;
    /* Reduced height for laptops */
    background: #FFF;
    position: relative;
    margin-top: 80px;
    /* Adjusted margin */
  }

  .Phar2div1 {
    width: 40%;
    height: 350px;

    /* Adjusted height */
    flex-shrink: 0;
    background-image: url('./images/phar1.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 15px;
    /* Slightly smaller border-radius */
    position: absolute;
    background-position: center;
    left: 40px;
    /* Adjusted for a better fit */
    z-index: 1;
    margin-top: 50px;
  }

  .Phar2div2 {
    width: 90%;
    height: 200px;
    border: 10px solid #fff;
    flex-shrink: 0;
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar2.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 20px;
    position: absolute;
    top: 400px;
    /* Adjusted top position */
    left: 30px;
    /* Adjusted left position */
    z-index: 2;
  }

  .Phar2div3 {
    width: 40%;
    height: 240px;

    flex-shrink: 0;
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar3.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 18%;
    /* Adjusted top position */
    left: 45%;
    /* Adjusted left position */
    z-index: 3;
    /* Reduced border size */
    border-radius: 20px;
  }

  .phar2h1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 32px;
    /* Slightly smaller font size */
    text-align: start;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: -120px;
    /* Adjusted top position */
  }

  .Phar2div4 {
    width: 350px;
    /* Adjusted width */
    display: block;
    text-align: start;
    border-radius: 15px;
    position: absolute;
    top: 40px;
    /* Adjusted top position */
    left: 40px;
    /* Adjusted left position */
    z-index: 4;
  }

  .phar2h2 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 32px;
    /* Slightly smaller font size */
    font-style: normal;
    text-align: start;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: -90px;
    /* Adjusted top position */
  }

  .phar2p1 {
    width: 700px;
    /* Adjusted width */
    height: 190px;
    /* Adjusted height */
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: 'Poppins';
    font-size: 18px;
    /* Slightly smaller font size */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 650px;
    /* Adjusted top position */
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar2body {
    width: 100%;
    height: 1000px;
    /* Reduced height for laptops */
    background: #FFF;
    position: relative;
    margin-top: 80px;
    /* Adjusted margin */
  }

  .Phar2div1 {
    width: 40%;
    height: 350px;

    /* Adjusted height */
    flex-shrink: 0;
    background-image: url('./images/phar1.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 15px;
    /* Slightly smaller border-radius */
    position: absolute;
    background-position: center;
    left: 40px;
    /* Adjusted for a better fit */
    z-index: 1;
    margin-top: 50px;
  }

  .Phar2div2 {
    width: 90%;
    height: 168px;
    border: 10px solid #fff;
    flex-shrink: 0;
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar2.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 20px;
    position: absolute;
    top: 400px;
    /* Adjusted top position */
    left: 30px;
    /* Adjusted left position */
    z-index: 2;
  }

  .Phar2div3 {
    width: 40%;
    height: 250px;

    flex-shrink: 0;
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar3.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 20%;
    /* Adjusted top position */
    left: 45%;
    /* Adjusted left position */
    z-index: 3;
    /* Reduced border size */
    border-radius: 20px;
  }

  .phar2h1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 32px;
    /* Slightly smaller font size */
    text-align: start;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: -120px;
    /* Adjusted top position */
  }

  .Phar2div4 {
    width: 350px;
    /* Adjusted width */
    display: block;
    text-align: start;
    border-radius: 15px;
    position: absolute;
    top: 40px;
    /* Adjusted top position */
    left: 40px;
    /* Adjusted left position */
    z-index: 4;
  }

  .phar2h2 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 32px;
    /* Slightly smaller font size */
    font-style: normal;
    text-align: start;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: -90px;
    /* Adjusted top position */
  }

  .phar2p1 {
    width: 450px;
    /* Adjusted width */
    height: 190px;
    /* Adjusted height */
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: 'Poppins';
    font-size: 18px;
    /* Slightly smaller font size */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 550px;
    /* Adjusted top position */
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar2body {
    width: 100%;
    height: 800px;
    background: #FFF;
    position: relative;
    margin-top: 100px;
  }

  .Phar2div1 {
    width: 317.084px;
    height: 352.387px;
    flex-shrink: 0;
    background-image: url('./images/phar1.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 20px;
    position: absolute;
    background-position: center;
    left: 50px;
    z-index: 1;
  }

  .Phar2div2 {
    width: 339.962px;
    height: 145.603px;
    flex-shrink: 0;
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar2.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 20px;
    position: absolute;
    top: 270px;
    left: 60px;
    z-index: 2;
  }

  .Phar2div3 {
    width: 136.343px;
    height: 128.364px;
    flex-shrink: 0;
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar3.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 200px;
    left: 280px;
    z-index: 3;
    border: 10px solid #fff;
    border-radius: 20px;
  }

  .phar2h1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 35px;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: -200px;
  }

  .Phar2div4 {
    width: 380px;
    display: block;
    text-align: start;
    border-radius: 20px;
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 4;
  }

  .phar2h2 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    text-align: start;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: -150px;
  }

  .phar2p1 {
    width: 360px;
    height: 203.124px;
    flex-shrink: 0;
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 390px;
  }


}

@media (min-width: 320px) and (max-width: 480px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar2body {
    width: 80%;
    height: 600px;
    background: #FFF;
    position: relative;
    margin-top: 50px;
  }

  .Phar2div1 {
    width: 50%;
    height: 249.387px;
    flex-shrink: 0;
    background-image: url('./images/phar1.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 20px;
    position: absolute;
    background-position: center;
    left: 50px;
    z-index: 1;
  }

  .Phar2div2 {
    width: 95%;
    height: 108.603px;
    flex-shrink: 0;
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar2.jfif');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border-radius: 20px;
    position: absolute;
    top: 200px;
    left: 60px;
    z-index: 2;
  }

  .Phar2div3 {
    width: 115.343px;
    height: 114.364px;
    flex-shrink: 0;
    background-position-x: 50%;
    background-position-y: 80%;
    background-image: url('./images/phar3.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 130px;
    left: 200px;
    z-index: 3;
    border: 4px solid #fff;
    border-radius: 20px;
  }

  .phar2h1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 35px;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: -200px;
  }

  .Phar2div4 {
    width: 88%;
    display: block;
    text-align: start;
    border-radius: 20px;
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 4;
  }

  .phar2h2 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    text-align: start;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: -150px;
  }

  .phar2p1 {
    width: 90%;
    height: 203.124px;
    flex-shrink: 0;
    flex-shrink: 0;
    color: #000;
    text-align: start ;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 290px;
    margin-left: -20px;
  }


}

.phar3bg {
  width: 100%;
  height: 1269.625px;
  display: block;
  background-image: url('./images/Phar3bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.phar3h1 {
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 150px;
}

.phar3h2 {
  color: #000;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
}

.phar3cen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.phar3gird {
  display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 20px;
    column-gap: 10px;
    justify-content: center; /* Centers items inside the grid horizontally */
    margin: 50px auto 0 auto; /* Centers the grid container itself */
}

.gird-div {
  width: 203.847px;
  height: 130.943px;
  flex-shrink: 0;
  display: block;
}

.gird-item {
  width: 203.847px;
  height: 130.943px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  display: block;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar3bg {
    width: 100%;
    height: 1469.625px;
    display: block;
    background-image: url('./images/Phar3bg.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .phar3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 100px;
  }

  .phar3h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }

  .phar3cen {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .phar3gird {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 6 equal columns */
    row-gap: 20px;
    column-gap: 10px;
    justify-content: center;
    margin-top: 50px;
    -ms-grid-column-align: center;
    -ms-grid-row-align: center;
    /* Adjust the gap between items */
  }

  .gird-div {
    width: 142px;
    height: 91px;
    flex-shrink: 0;
    display: block;
  }

  .gird-item {
    width: 203.847px;
    height: 130.943px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    display: block;
  }

  .ibrtitle1 {
    color: #28156D !important;
    text-align: left;
    font-family: 'Brush Script Mt';
    font-size: 55px !important;
    font-weight: 400 !important;
    line-height: normal;
  }

  .subtitle {
    color: #000;
    text-align: left;
    font-family: "Playfair Display" !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar3bg {
    width: 100%;
    height: 1969px;
    display: block;
    background-image: url('./images/phar3mbl.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .phar3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 100px;
  }

  .phar3h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }

  .phar3cen {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .phar3gird {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 6 equal columns */
    row-gap: 20px;
    column-gap: 10px;
    justify-content: center;
    margin-top: 50px;
    -ms-grid-column-align: center;
    -ms-grid-row-align: center;
    /* Adjust the gap between items */
  }

  .gird-div {
    width: 142px;
    height: 91px;
    flex-shrink: 0;
    display: block;
  }

  .gird-item {
    width: 203.847px;
    height: 130.943px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    display: block;
  }

  .ibrtitle1 {
    color: #28156D !important;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px !important;
    font-weight: 400 !important;
    line-height: normal;
  }

  .subtitle {
    color: #000;
    text-align: center;
    font-family: "Playfair Display" !important;
    font-size: 34px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }

}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar3bg {
    width: 100%;
    height: 2730px;
    display: block;
    background-image: url('./images/phar3mbl.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .phar3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 100px;
  }

  .phar3h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }

  .phar3cen {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .phar3gird {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 6 equal columns */
    row-gap: 20px;
    column-gap: 10px;
    justify-content: center;
    margin-top: 50px;
    -ms-grid-column-align: center;
    -ms-grid-row-align: center;
    /* Adjust the gap between items */
  }

  .gird-div {
    width: 142px;
    height: 91px;
    flex-shrink: 0;
    display: block;
  }

  .gird-item {
    width: 203.847px;
    height: 130.943px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    display: block;
  }

  .ibrtitle1 {
    color: #28156D !important;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px !important;
    font-weight: 400 !important;
    line-height: normal;
  }

  .subtitle {
    color: #000;
    text-align: center;
    font-family: "Playfair Display" !important;
    font-size: 34px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }

}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar3bg {
    width: 100%;
    height: 2730px;
    display: block;
    background-image: url('./images/phar3mbl.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .phar3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 70px;
  }

  .phar3h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }

  .phar3cen {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .phar3gird {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 6 equal columns */
    row-gap: 20px;
    column-gap: 10px;
    justify-content: center;
    margin-top: 50px;
    -ms-grid-column-align: center;
    -ms-grid-row-align: center;
    /* Adjust the gap between items */
  }

  .gird-div {
    width: 142px;
    height: 91px;
    flex-shrink: 0;
    display: block;
  }

  .gird-item {
    width: 203.847px;
    height: 130.943px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    display: block;
  }

  .ibrtitle1 {
    color: #28156D !important;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px !important;
    font-weight: 400 !important;
    line-height: normal;
  }

  .subtitle {
    color: #000;
    text-align: center;
    font-family: "Playfair Display" !important;
    font-size: 34px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }


}

/* Mobile Devices (up to 480px) */
@media (min-width: 320px) and (max-width: 480px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar3bg {
    width: 100%;
    height: 4500px;
    display: block;
    background-image: url('./images/phar3mbl.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .phar3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 110px;
  }

  .phar3h2 {
    width: 99%;
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }

  .phar3cen {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .phar3gird {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* 6 equal columns */
    row-gap: 10px;
    column-gap: 10px;
    justify-content: center;
    margin-top: 50px;
    -ms-grid-column-align: center;
    -ms-grid-row-align: center;
    /* Adjust the gap between items */
  }

  .gird-div {
    width: 142px;
    height: 91px;
    flex-shrink: 0;
    display: block;
  }

  .gird-item {
    width: 123.078px;
    height: 104.754px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    display: block;
  }

  .ibrtitle1 {
    color: #28156D !important;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px !important;
    font-weight: 400 !important;
    line-height: normal;
  }

  .subtitle {
    color: #000;
    text-align: center;
    font-family: "Playfair Display" !important;
    font-size: 34px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }


}

.about2bg {
  width: 100%;
  height: 950px;
  background: #FFF;
}

.about2h1 {
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5%;
}

.about2h2 {
  color: #000;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.aboutdiv1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two columns */
  gap: 20px;
  /* Space between the grid items */
}

.aboutdiv2 {
  width: 502px;
  height: 300px;
  border: 1px solid rgb(166, 164, 164);
  border-radius: 10px;
  padding: 20px;
  position: relative;

}

.aboutdiv2:hover {
  width: 502px;
  height: 300px;
  flex-shrink: 0;
  background-color: #28156D;
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.13);
  color: white;
  border: none;
}

.about2h3 {
  width: 364px;
  font-family: 'Poppins';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
}

.aboutp1 {
  width: 480px;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 35px;
  text-align: start;
  margin-top: 20px;
}

.aboutdiv2:hover .arrw {
  position: absolute;
  z-index: 99;
  width: 19px;
  height: 19px;
  background-image: url('./images/arrow1.png');
  background-repeat: no-repeat;
  background-position: center;
  bottom: 10px;
  right: 10px;
}

.arrw {
  position: absolute;
  z-index: 99;
  width: 19px;
  height: 19px;
  background-image: url('./images/arrow2.png');
  background-repeat: no-repeat;
  background-position: center;
  bottom: 10px;
  right: 10px;
}

.aboutdiv2:hover .icon1 {
  fill: #ffffff;
  width: 60px;
  height: 40px;
  margin-right: 90%;
}

.aboutdiv2:hover .about2h3 {
  width: 364px;
  color: white;
  font-family: 'Poppins';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
}

.icon1 {
  fill: #00AA14;
  width: 60px;
  height: 40px;
  margin-right: 90%;
}


.about2cen {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .about2bg {
    width: 100%;
    height: 750px;
    background: #FFF;
  }

  .about2h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .about2h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .aboutdiv1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
    gap: 20px;
    /* Space between the grid items */
  }

  .aboutdiv2 {
    width: 402px;
    height: 182px;
    border: 1px solid rgb(166, 164, 164);
    border-radius: 10px;
    padding: 20px;
    position: relative;

  }

  .aboutdiv2:hover {
    width: 402px;
    height: 182px;
    flex-shrink: 0;
    background-color: #28156D;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    color: white;
    border: none;
  }

  .about2h3 {
    width: 364px;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
  }

  .aboutp1 {
    width: 364px;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    text-align: start;
    margin-top: 20px;
  }


  .arrw {
    position: absolute;
    z-index: 99;
    width: 19px;
    height: 19px;
    background-image: url('./images/arrow1.png');
    background-repeat: no-repeat;
    background-position: center;
    bottom: 10px;
    right: 10px;
  }
  .icon1 {
    fill: #00AA14;
    width: 60px;
    height: 40px;
    margin-right: 90%;
  }

  .aboutdiv2:hover .icon1 {
    fill: #ffffff;
    width: 60px;
    height: 40px;
    margin-right: 90%;
  }

 
  .about2cen {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 550px) and (max-width: 1025px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .about2bg {
    width: 100%;
    height: 600px;
    background: #FFF;
  }

  .about2h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .about2h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .aboutdiv1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
    gap: 20px;
    /* Space between the grid items */
  }

  .aboutdiv2 {
    width: 302px;
    height: 200px;
    border: 1px solid rgb(166, 164, 164);
    border-radius: 10px;
    padding: 20px;
    position: relative;

  }

  .aboutdiv2:hover {
    width: 302px;
    height: 200px;
    flex-shrink: 0;
    background-color: #28156D;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    color: white;
    border: none;
  }

  .about2h3 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
  }

  .aboutp1 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: 10px;
    text-align: start;
    margin-top: 10px;
  }


  .arrw {
    position: absolute;
    z-index: 99;
    width: 10px;
    height: 10px;
    background-image: url('./images/arrow1.png');
    background-repeat: no-repeat;
    background-position: center;
    bottom: 10px;
    right: 10px;
  }

  .aboutdiv2:hover .icon1 {
    fill: #ffffff;
    width: 60px;
    height: 40px;
    margin-right: 90%;
  }

  .icon1 {
    fill: #00AA14;
    width: 60px;
    height: 40px;
    margin-right: 90%;
  }

  .about2cen {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .about2bg {
    width: 100%;
    height: 980px;
    background: #FFF;
  }

  .about2h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .about2h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .aboutdiv1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* Two columns */
    gap: 20px;
    /* Space between the grid items */
  }

  .aboutdiv2 {
    width: 302px;
    height: 200px;
    border: 1px solid rgb(166, 164, 164);
    border-radius: 10px;
    padding: 20px;
    position: relative;

  }

  .aboutdiv2:hover {
    width: 302px;
    height: 200px;
    flex-shrink: 0;
    background-color: #28156D;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    color: white;
    border: none;
  }

  .about2h3 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
  }

  .aboutp1 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: 10px;
    text-align: start;
    margin-top: 10px;
  }


  .arrw {
    position: absolute;
    z-index: 99;
    width: 15px;
    height: 15px;
    background-image: url('./images/arrow1.png');
    background-repeat: no-repeat;
    background-position: center;
    bottom: 10px;
    right: 10px;
  }

  .aboutdiv2:hover .icon1 {
    fill: #ffffff;
    width: 60px;
    height: 40px;
    margin-right: 90%;
  }

  .icon1 {
    fill: #00AA14;
    width: 60px;
    height: 40px;
    margin-right: 90%;
  }

  .about2cen {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .about2bg {
    width: 100%;
    height: 1290px;
    background: #FFF;
  }

  .about2h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .about2h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .aboutdiv1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* Two columns */
    gap: 20px;
    /* Space between the grid items */
  }

  .aboutdiv2 {
    width: 398px;
    height: 223.578px;
    border: 1px solid rgb(166, 164, 164);
    border-radius: 10px;
    padding: 20px;
    position: relative;

  }

  .aboutdiv2:hover {
    width: 398px;
    height: 223.578px;
    background-color: #28156D;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    color: white;
    border: none;
  }

  .aboutdiv2:hover .about2h3 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
  }

  .about2h3 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 17.442px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
  }

  .aboutp1 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 12.685px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: start;
    margin-top: 10px;
  }


  .aboutdiv2:hover .arrw {
    position: absolute;
    z-index: 99;
    width: 23.785px;
    height: 25.371px;
    flex-shrink: 0;
    background-image: url('./images/arrow1.png');
    background-repeat: no-repeat;
    background-position: center;
    bottom: 10px;
    right: 10px;
  }

  .arrw {
    position: absolute;
    z-index: 99;
    width: 23.785px;
    height: 25.371px;
    flex-shrink: 0;
    background-image: url('./images/arrow2.png');
    background-repeat: no-repeat;
    background-position: center;
    bottom: 10px;
    right: 10px;
  }

  .aboutdiv2:hover .icon1 {
    fill: #ffffff;
    width: 40px;
    height: 30px;
    margin-right: 90%;
  }

  .icon1 {
    fill: #00AA14;
    width: 40px;
    height: 30px;
    margin-right: 90%;
  }

  .about2cen {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Mobile Devices (up to 480px) */
@media (min-width: 320px) and (max-width: 480px) {
  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  .about2bg {
    width: 100%;
    height: 1200px;
    background: #FFF;
  }

  .about2h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .about2h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .aboutdiv1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* Two columns */
    gap: 20px;
    /* Space between the grid items */
  }

  .aboutdiv2 {
    width: 300px;
    height: 250px;
    border: 1px solid rgb(166, 164, 164);
    border-radius: 10px;
    padding: 20px;
    position: relative;

  }

  .aboutdiv2:hover {
    width: 300px;
    height: 300px;
    background-color: #28156D;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    color: white;
    border: none;
  }

  .aboutdiv2:hover .about2h3 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
  }

  .about2h3 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 17.442px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
  }

  .aboutp1 {
    width: 264px;
    font-family: 'Poppins';
    font-size: 12.685px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: start;
    margin-top: 10px;
  }


  .aboutdiv2:hover .arrw {
    position: absolute;
    z-index: 99;
    width: 17px;
    height: 17px;
    flex-shrink: 0;
    background-image: url('./images/arrow1.png');
    background-repeat: no-repeat;
    background-position: center;
    bottom: 10px;
    right: 10px;
  }

  .arrw {
    position: absolute;
    z-index: 99;
    width: 17px;
    height: 17px;
    flex-shrink: 0;
    background-image: url('./images/arrow2.png');
    background-repeat: no-repeat;
    background-position: center;
    bottom: 10px;
    right: 10px;
  }

  .aboutdiv2:hover .icon1 {
    fill: #ffffff;
    width: 40px;
    height: 30px;
    margin-right: 90%;
  }

  .icon1 {
    fill: #00AA14;
    width: 40px;
    height: 30px;
    margin-right: 90%;
  }

  .about2cen {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.about3btn {
  display: none;
}

.about3bg {
  width: 100%;
  height: 1089px;
  /* background-image: url('./images/about3bg.png'); */
  background-repeat: no-repeat;
  background-size: cover;
}

.about3h1 {
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5%;
}

.about3h2 {
  margin-top: 20px;
  color: #000;
  text-align: center;
  font-family: "Playfair Display , arial , sans-serif";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.about3p1 {
  margin-top: 30px;
  width: 1157.593px;
  height: 100px;
  color: #000;
  font-family: Mont, arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: 30px;
}

.about3p2 {
  margin-top: 30px;
  width: 1157.593px;
  height: 100px;
  color: #000;
  font-family: "Mont", arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: 30px;
}

.about3head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about3body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 190px;
}

.about3div1w {
  width: 382px;
  height: 532px;
  border-radius: 20px;
  display: block;
  background-color: #fff;
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.13);
  position: relative;
}

.about3img {
  margin-top: 10px;
  width: 348.762px;
  height: 243.103px;
  border-radius: 20px;
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.13);
}

.div2radio {
  width: 63.3px;
  height: 63.3px;
  background-color: #00AA14;
  display: block;
  position: absolute;
  stroke-width: 4px;
  stroke: #FFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: white;
  font-weight: 600;
  z-index: 11;
  top: 43%;
  left: 10%;
  border: 5px solid #fff;
}

.aboutbodytext {
  padding: 20px;
  text-align: start;
}

.about2h3 {
  color: #0D222F;
  font-family: Mont, arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
  margin-top: 15px
}

.about3p3 {
  width: 303.206px;
  height: 135px;
  color: #000;
  font-family: 'Poppins', arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}

.about1icon1 {
  fill: white;
  width: 30px;
  height: 30px;
}
.AddressSection{
  display: flex;
}



/* Large Screens (1441px and up) */
/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1150px) and (max-width: 1440px) {

  /* Styles for laptops and desktops */
  .about3bg {
    width: 100%;
    height: 1073.625px;
    /* background-image: url('./images//about3bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10%;
  }

  .about3h2 {
    margin-top: 10px;
    color: #000;
    text-align: center;
    font-family: "Playfair Display , arial , sans-serif";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .about3p1 {
    margin-top: 30px;
    width: 90%;
    height: 100px;
    color: #000;
    font-family: Mont, arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 30px;
  }

  .about3p2 {
    margin-top: 30px;
    width: 90%;
    height: 100px;
    color: #000;
    font-family: "Mont", arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 30px;
  }

  .about3head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .about3body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 150px;
  }

  .about3div1w {
    width: 352px;
    height: 502px;
    border-radius: 20px;
    display: block;
    background-color: #fff;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    position: relative;
  }

  .about3img {
    margin-top: 10px;
    width: 318.762px;
    height: 223.103px;
    border-radius: 20px;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
  }

  .div2radio {
    width: 60.3px;
    height: 60.3px;
    background-color: #00AA14;
    display: block;
    position: absolute;
    stroke-width: 4px;
    stroke: #FFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
    font-weight: 600;
    z-index: 11;
    top: 40%;
    left: 10%;
    border: 5px solid #fff;
  }

  .aboutbodytext {
    padding: 20px;
    text-align: start;
  }

  .about2h3 {
    color: #0D222F;
    font-family: Mont, arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 15px
  }

  .about3p3 {
    width: 303.206px;
    height: 135px;
    color: #000;
    font-family: 'Poppins', arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
  }

  .about1icon1 {
    fill: white;
    width: 25px;
    height: 25px;
  }

}

/* Small Laptops (769px to 1024px) */
@media (min-width: 851px) and (max-width: 1150px) {
  .about3bg {
    width: 100%;
    height: 100%;
    /* background-image: url('./images//about3bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20%;
  }

  .about3h2 {
    margin-top: 30px;
    color: #000;
    text-align: center;
    font-family: Mont;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 214.286% */
  }

  .about3p1 {
    margin-top: 30px;
    width: 90%;
    height: 100px;
    color: #000;
    font-family: "Mont", arial, sans-serif;
    font-size: 14px;
    text-align: center;
    font-style: normal;
    font-weight: 200;
    line-height: 30px;
  }

  .about3p2 {
    margin-top: 30px;
    width: 100%;
    height: 100px;
    color: #000;
    font-family: "Mont", arial, sans-serif;
    font-size: 14px;
    text-align: center;
    font-style: normal;
    font-weight: 200;
    line-height: 30px;
  }

  .about3head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .girdcen {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 200px;
    padding-top: 0;
  }

  .about3body {
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* or any desired column setup */
    gap: 30px;
    margin-top: 150px;
    width: 100%;
    /* The grid takes the full width of its container */
    max-width: 1200px;
    /* Optional: set a max width to control the grid size */
  }


  .about3div1w {
    width: 352px;
    height: 502px;
    border-radius: 20px;
    display: block;
    background-color: #fff;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    position: relative;
  }

  .about3img {
    margin-top: 10px;
    width: 318.762px;
    height: 223.103px;
    border-radius: 20px;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
  }

  .div2radio {
    width: 60.3px;
    height: 60.3px;
    background-color: #00AA14;
    display: block;
    position: absolute;
    stroke-width: 4px;
    stroke: #FFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
    font-weight: 600;
    z-index: 11;
    top: 40%;
    left: 10%;
    border: 5px solid #fff;
  }

  .aboutbodytext {
    padding: 20px;
    text-align: start;
  }

  .about2h3 {
    color: #0D222F;
    font-family: Mont, arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 15px
  }

  .about3p3 {
    width: 303.206px;
    height: 135px;
    color: #000;
    font-family: 'Poppins', arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
  }

  .about1icon1 {
    fill: white;
    width: 25px;
    height: 25px;
  }

  .about3btn {
    display: none !important;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 850px) {
  .about3bg {
    width: 100%;
    height: 1200px;
    /* background-image: url('./images/about3bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20%;
  }

  .about3h2 {
    margin-top: 30px;
    color: #000;
    text-align: center;
    font-family: "Playfair Display , arial , sans-serif";
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .about3p1 {
    width: 80%;
    margin-top: 50px;
    color: #000;
    font-family: Mont;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 20px;
  }

  .about3p2 {
    width: 100%;
    margin-top: 30px;
    color: #000;
    font-family: Mont;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 20px;
  }

  .about3head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


  .about3body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    gap: 120px;
    overflow: hidden;
    width: 100%;
    /* The grid takes the full width of its container */
  }


  .about3div1w {
    width: 80%;
    height: 502px;
    border-radius: 20px;
    display: block;
    background-color: #fff;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    position: relative;
  }

  .about3img {
    margin-top: 10px;
    width: 95%;
    height: 60%;
    object-fit: cover;
    height: 223.103px;
    border-radius: 20px;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
  }

  .div2radio {
    width: 60.3px;
    height: 60.3px;
    background-color: #00AA14;
    display: block;
    position: absolute;
    stroke-width: 4px;
    stroke: #FFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
    font-weight: 600;
    z-index: 11;
    top: 40%;
    left: 10%;
    border: 5px solid #fff;
  }

  .aboutbodytext {
    padding: 20px;
    text-align: start;
  }

  .about2h3 {
    color: #0D222F;
    font-family: Mont, arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 15px
  }

  .about3p3 {
    width: 90%;
    height: 135px;
    color: #000;
    font-family: 'Poppins', arial, sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
  }

  .about3btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 50px;
  }

  .about1icon1 {
    fill: white;
    width: 25px;
    height: 25px;
  }
}

/* Tablets (481px to 768px) */
/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .about3bg {
    width: 100%;
    height: 1472.159px;
    background-image: url('./images/about1mblbg.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20%;
  }

  .about3h2 {
    margin-top: 30px;
    color: #000;
    text-align: center;
    font-family: "Playfair Display , arial , sans-serif";
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .about3p1 {
    width: 80%;
    margin-top: 30px;
    color: #000;
    font-family: Mont;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    /* 214.286% */
  }

  .about3p2 {
    width: 80%;
    margin-top: 30px;
    color: #000;
    font-family: Mont;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    /* 214.286% */
  }

  .about3head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


  .about3body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    gap: 60px;
    overflow: hidden;
    width: 100%;
    /* The grid takes the full width of its container */
  }


  .about3div1w {
    width: 80%;
    height: 502px;
    border-radius: 20px;
    display: block;
    background-color: #fff;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    position: relative;
  }

  .about3img {
    margin-top: 10px;
    width: 95%;
    height: 60%;
    object-fit: cover;
    height: 223.103px;
    border-radius: 20px;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
  }

  .div2radio {
    width: 60.3px;
    height: 60.3px;
    background-color: #00AA14;
    display: block;
    position: absolute;
    stroke-width: 4px;
    stroke: #FFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
    font-weight: 600;
    z-index: 11;
    top: 40%;
    left: 10%;
    border: 5px solid #fff;
  }

  .aboutbodytext {
    padding: 20px;
    text-align: start;
  }

  .about2h3 {
    color: #0D222F;
    font-family: Mont, arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 15px
  }

  .about3p3 {
    width: 90%;
    height: 135px;
    color: #000;
    font-family: 'Poppins', arial, sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
  }

  .about3btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
  }

  .about1icon1 {
    fill: white;
    width: 25px;
    height: 25px;
  }
}

/* Mobile Devices (up to 480px) */
@media (min-width: 320px) and (max-width: 480px) {
  .about3bg {
    width: 100%;
    height: 1350px;
    background-image: url('./images/about1mblbg.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about3h1 {
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20%;
  }

  .about3h2 {
    margin-top: 30px;
    color: #000;
    text-align: center;
    font-family: "Playfair Display , arial , sans-serif";
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .about3p1 {
    width: 80%;
    margin-top: 30px;
    color: #000;
    font-family: Mont, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    /* 214.286% */
  }

  .about3p2 {
    width: 100%;
    margin-top: 30px;
    color: #000;
    font-family: Mont;
    font-size: 10px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    /* 214.286% */
  }

  .about3head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


  .about3body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    gap: 60px;
    overflow: hidden;
    width: 100%;
    /* The grid takes the full width of its container */
  }


  .about3div1w {
    width: 80%;
    height: 502px;
    border-radius: 20px;
    display: block;
    background-color: #fff;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
    position: relative;
  }

  .about3img {
    margin-top: 10px;
    width: 95%;
    height: 60%;
    object-fit: cover;
    height: 223.103px;
    border-radius: 20px;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.13);
  }

  .div2radio {
    width: 60.3px;
    height: 60.3px;
    background-color: #00AA14;
    display: block;
    position: absolute;
    stroke-width: 4px;
    stroke: #FFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
    font-weight: 600;
    z-index: 11;
    top: 40%;
    left: 10%;
    border: 5px solid #fff;
  }

  .aboutbodytext {
    padding: 20px;
    text-align: start;
  }

  .about2h3 {
    color: #0D222F;
    font-family: Mont, arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 15px
  }

  .about3p3 {
    width: 90%;
    height: 135px;
    color: #000;
    font-family: 'Poppins', arial, sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
  }

  .about3btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
  }

  .about1icon1 {
    fill: white;
    width: 25px;
    height: 25px;
  }
}

.about3btn {
  display: none;
}

.about4bg {
    background-image: url('./images/about3bg.png');
  min-width: 100%;
  height: 1000px;
  align-content: center;
  background-repeat: round;
}

.leftbntright {
  width: 51.828px;
  height: 51.828px;
}

.about4left1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  height: 322.018px;

}

.about4h1 {
  color: #28156D;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-left: 5%;
}

.about4h10 {
  color: #000;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-left: 5%;
}

.about4body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 150px;

}

.about4main {
  width: 665.065px;
  height: 342.018px;
  display: block;
  background-color: #FFF;
  border: 1px solid rgb(110, 110, 110);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

.about4mr {
  width: 127%;
  height: 100% !important;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.829);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.about4mr::before {

  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/review1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: black;
  opacity: 0.4;
  /* Apply opacity only to the background */
  border-radius: 20px;
  z-index: 1;
  /* Ensure it's behind the content */
}

.about4mr>* {
  position: relative;
  z-index: 2;
  /* Content stays on top of the background */
}

.about4h3 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 21.316px;
  font-style: normal;
  font-weight: 300;
  line-height: 85.262px;
  /* 400% */
  text-align: start;
}

.about4h4 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  text-align: start;
  /* 250% */
}

.about4p1 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 31.973px;
  text-align: start;
  letter-spacing: 1px;
  /* 375% */
}

.about4ml {
  width: 60%;
  height: 342.018px;
  padding: 20px;
}

.quotation {
  width: 70px;
  height: 70px;
  fill: #00AA14;
  display: block;
  font-size: 74px;
  font-style: normal;

}

.about4p2 {
  width: 351.243px;
  height: 182.111px;
  flex-shrink: 0;
  color: #000;
  font-family: "Open Sans";
  font-size: 15.987px;
  font-style: normal;
  text-align: start;
  font-weight: 600;
  line-height: 31.973px;
  /* 200% */
}

.qpar {
  width: 100%;

}

.leftmain {
  padding: 23px;
  width: 259.885px;
  height: 172.655px;
  flex-shrink: 0;
  border-radius: 21.316px;
  background: #121935;
}

.review2 {
  width: 62.18px;
  height: 62.18px;
  flex-shrink: 0;
  border-radius: 10.658px;
  object-fit: cover;
  float: left;
}

.about4h2 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 21.316px;
  font-style: normal;
  font-weight: 700;
  line-height: 85.262px;
  /* 400% */
  letter-spacing: -1.066px;
}

.leftmain1 {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.about4h5 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 12.789px;
  font-style: normal;
  font-weight: 700;
  text-align: start;
  line-height: 31.973px;
  /* 250% */
}

.about4p3 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 8.526px;
  font-style: normal;
  text-align: start;
  font-weight: 700;
}

.leftmain3 {
  width: 259.885px;
  height: 172.655px;
  padding: 23px;
  background-color: #000;
  border-radius: 21.316px;
  background-image: url('./images/review3.png');
}

.about4right {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 300px;
}


/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1200px) and (max-width: 1440px) {
 

.about4bg {
  min-width: 100%;
  height: 900px;
  margin-bottom: 50px;
  background-repeat: round;
  background-image: url('./images/about3bg.png');

}

.leftbntright {
  width: 51.828px;
  height: 51.828px;
}

.about4left1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  height: 322.018px;

}

.about4h1 {
  color: #28156D;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-left: 5%;
}

.about4h10 {
  color: #000;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-left: 5%;
}

.about4body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 150px;

}

.about4main {
  width: 665.065px;
  height: 342.018px;
  display: block;
  background-color: #FFF;
  border: 1px solid rgb(110, 110, 110);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

.about4mr {
  width: 127%;
  height: 342.018px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.829);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.about4mr::before {

  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/review1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: black;
  opacity: 0.4;
  /* Apply opacity only to the background */
  border-radius: 20px;
  z-index: 1;
  /* Ensure it's behind the content */
}

.about4mr>* {
  position: relative;
  z-index: 2;
  /* Content stays on top of the background */
}

.about4h3 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 21.316px;
  font-style: normal;
  font-weight: 300;
  line-height: 85.262px;
  /* 400% */
  text-align: start;
}

.about4h4 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  text-align: start;
  /* 250% */
}

.about4p1 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 31.973px;
  text-align: start;
  letter-spacing: 1px;
  /* 375% */
}

.about4ml {
  width: 60%;
  height: 342.018px;
  padding: 20px;
}

.quotation {
  width: 70px;
  height: 70px;
  fill: #00AA14;
  display: block;
  font-size: 74px;
  font-style: normal;

}

.about4p2 {
  width: 351.243px;
  height: 182.111px;
  flex-shrink: 0;
  color: #000;
  font-family: "Open Sans";
  font-size: 15.987px;
  font-style: normal;
  text-align: start;
  font-weight: 600;
  line-height: 31.973px;
  /* 200% */
}

.qpar {
  width: 100%;

}

.leftmain {
  padding: 23px;
  width: 259.885px;
  height: 172.655px;
  flex-shrink: 0;
  border-radius: 21.316px;
  background: #121935;
}

.review2 {
  width: 62.18px;
  height: 62.18px;
  flex-shrink: 0;
  border-radius: 10.658px;
  object-fit: cover;
  float: left;
}

.about4h2 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 21.316px;
  font-style: normal;
  font-weight: 700;
  line-height: 85.262px;
  /* 400% */
  letter-spacing: -1.066px;
}

.leftmain1 {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.about4h5 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 12.789px;
  font-style: normal;
  font-weight: 700;
  text-align: start;
  line-height: 31.973px;
  /* 250% */
}

.about4p3 {
  color: #FFF;
  font-family: "Plus Jakarta Sans";
  font-size: 8.526px;
  font-style: normal;
  text-align: start;
  font-weight: 700;
}

.leftmain3 {
  width: 259.885px;
  height: 172.655px;
  padding: 23px;
  background-color: #000;
  border-radius: 21.316px;
  background-image: url('./images/review3.png');
}

.about4right {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 300px;
}
}
@media (min-width: 769px) and (max-width: 1200px) {
  .about4bg {
    margin-top: 50px;
    min-width: 100%;
    height: 900px;
    background-repeat: round;
    background-image: url('./images/about3bg.png');  }

  .leftbntright {
    width: 51.828px;
    height: 51.828px;
    display: none;
  }

  .about4left1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    height: 322.018px;
    display: none;
  }

  .about4h1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
  }

  .about4h10 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
  }

  .about4body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
  }

  .about4main {
    width: 50%;
    height: auto;
    display: block;
    background-color: #FFF;
    border: 1px solid rgb(110, 110, 110);
    border-radius: 20px;
  }

  .about4mr {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.829);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .about4mr::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 250%;
    background-image: url('./images/review1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: black;
    opacity: 0.4;
    border-radius: 20px;
    z-index: 1;
  }

  .about4mr > * {
    position: relative;
    z-index: 2;
  }

  .about4h3 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 21.316px;
    font-style: normal;
    font-weight: 300;
    line-height: 85.262px;
    text-align: start;
  }

  .about4h4 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    text-align: start;
  }

  .about4p1 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 31.973px;
    text-align: start;
    letter-spacing: 1px;
  }

  .about4ml {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .quotation {
    width: 70px;
    height: 70px;
    fill: #00AA14;
    display: block;
    font-size: 74px;
    margin-right: 200px;
    font-style: normal;
  }

  .about4p2 {
    width: 100%;
    height: auto;
    color: #000;
    font-family: "Open Sans";
    font-size: 15.987px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.973px;
  }

  .qpar {
    width: 100%;
  }

  .leftmain {
    padding: 23px;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 21.316px;
    background: #121935;
  }

  .review2 {
    width: 62.18px;
    height: 62.18px;
    flex-shrink: 0;
    border-radius: 10.658px;
    object-fit: cover;
    float: left;
  }

  .about4h2 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 21.316px;
    font-style: normal;
    font-weight: 700;
    line-height: 85.262px;
    letter-spacing: -1.066px;
  }

  .leftmain1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .about4h5 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12.789px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    line-height: 31.973px;
  }

  .about4p3 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 8.526px;
    font-style: normal;
    text-align: start;
    font-weight: 700;
  }

  .leftmain3 {
    width: 100%;
    height: auto;
    padding: 23px;
    background-color: #000;
    border-radius: 21.316px;
    background-image: url('./images/review3.png');
  }

  .about4right {
    display: none;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 300px;
  }

  .about4btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
  }
  .about4btn2 {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
  }
}

/* Optional media query for widths greater than 1100px */
@media (min-width: 1101px) {
  .about4mr {
    height: auto; /* Adjust height for larger screens */
  }
}
/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .about4bg {
    margin-top: 50px;
    min-width: 100%;
    height: 800px;
    background: #FFF;
  }

  .leftbntright {
    width: 51.828px;
    height: 51.828px;
    display: none;
  }

  .about4left1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    height: 322.018px;
    display: none;

  }

  .about4h1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
  }

  .about4h10 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
  }

  .about4body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 110px;

  }

  .about4main {
    width: 398.595px;
    height: 500px;
    display: block;
    background-color: #FFF;
    border: 1px solid rgb(110, 110, 110);
    border-radius: 20px;
  }

  .about4mr {
    width: 398.595px;
    height: 342.018px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.829);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .about4mr::before {

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./images/review1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: black;
    opacity: 0.4;
    /* Apply opacity only to the background */
    border-radius: 20px;
    z-index: 1;
    /* Ensure it's behind the content */
  }

  .about4mr>* {
    position: relative;
    z-index: 2;
    /* Content stays on top of the background */
  }

  .about4h3 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 21.316px;
    font-style: normal;
    font-weight: 300;
    line-height: 85.262px;
    /* 400% */
    text-align: start;
  }

  .about4h4 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    text-align: start;
    /* 250% */
  }

  .about4p1 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 31.973px;
    text-align: start;
    letter-spacing: 1px;
    /* 375% */
  }

  .about4ml {
    width: 100%;
    height: 342.018px;
    padding: 20px;
  }

  .quotation {
    width: 70px;
    height: 70px;
    fill: #00AA14;
    display: block;
    font-size: 74px;
    margin-right: 200px;
    font-style: normal;

  }

  .about4p2 {
    width: 351.243px;
    height: 182.111px;
    color: #000;
    font-family: "Open Sans";
    font-size: 15.987px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.973px;
    /* 200% */
    /* 200% */
  }

  .qpar {
    width: 100%;

  }

  .leftmain {
    padding: 23px;
    width: 259.885px;
    height: 172.655px;
    flex-shrink: 0;
    border-radius: 21.316px;
    background: #121935;
  }

  .review2 {
    width: 62.18px;
    height: 62.18px;
    flex-shrink: 0;
    border-radius: 10.658px;
    object-fit: cover;
    float: left;
  }

  .about4h2 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 21.316px;
    font-style: normal;
    font-weight: 700;
    line-height: 85.262px;
    /* 400% */
    letter-spacing: -1.066px;
  }

  .leftmain1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .about4h5 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12.789px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    line-height: 31.973px;
    /* 250% */
  }

  .about4p3 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 8.526px;
    font-style: normal;
    text-align: start;
    font-weight: 700;
  }

  .leftmain3 {
    width: 259.885px;
    height: 172.655px;
    padding: 23px;
    background-color: #000;
    border-radius: 21.316px;
    background-image: url('./images/review3.png');
  }

  .about4right {
    display: none;
    display: none;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 300px;
  }

  .about4btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px !important;
  }
  .about4btn2 {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px !important;
  }
}


/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .about4bg {
    margin-top: 50px;
    min-width: 100%;
    height: 800px;
    background: #FFF;
  }

  .leftbntright {
    width: 51.828px;
    height: 51.828px;
    display: none;
  }

  .about4left1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    height: 322.018px;
    display: none;

  }

  .about4h1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
  }

  .about4h10 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
  }

  .about4body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;

  }

  .about4main {
    width: 397.191px;
    height: 674.63px;
    display: block;
    background-color: #FFF;
    border: 1px solid rgb(110, 110, 110);
    border-radius: 20px;
  }

  .about4mr {
    width: 398.595px;
    height: 342.018px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.829);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .about4mr::before {

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./images/review1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: black;
    opacity: 0.4;
    /* Apply opacity only to the background */
    border-radius: 20px;
    z-index: 1;
    /* Ensure it's behind the content */
  }

  .about4mr>* {
    position: relative;
    z-index: 2;
    /* Content stays on top of the background */
  }

  .about4h3 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 21.316px;
    font-style: normal;
    font-weight: 300;
    line-height: 85.262px;
    /* 400% */
    text-align: start;
  }

  .about4h4 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    text-align: start;
    /* 250% */
  }

  .about4p1 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 31.973px;
    text-align: start;
    letter-spacing: 1px;
    /* 375% */
  }

  .about4ml {
    width: 100%;
    height: 342.018px;
    padding: 20px;
  }

  .quotation {
    width: 70px;
    height: 70px;
    fill: #00AA14;
    display: block;
    font-size: 74px;
    margin-right: 200px;
    font-style: normal;

  }

  .about4p2 {
    width: 351.243px;
    height: 182.111px;
    color: #000;
    font-family: "Open Sans";
    font-size: 15.987px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.973px;
    /* 200% */
    /* 200% */
  }

  .qpar {
    width: 100%;

  }

  .leftmain {
    padding: 23px;
    width: 259.885px;
    height: 172.655px;
    flex-shrink: 0;
    border-radius: 21.316px;
    background: #121935;
  }

  .review2 {
    width: 62.18px;
    height: 62.18px;
    flex-shrink: 0;
    border-radius: 10.658px;
    object-fit: cover;
    float: left;
  }

  .about4h2 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 21.316px;
    font-style: normal;
    font-weight: 700;
    line-height: 85.262px;
    /* 400% */
    letter-spacing: -1.066px;
  }

  .leftmain1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .about4h5 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12.789px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    line-height: 31.973px;
    /* 250% */
  }

  .about4p3 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 8.526px;
    font-style: normal;
    text-align: start;
    font-weight: 700;
  }

  .leftmain3 {
    width: 259.885px;
    height: 172.655px;
    padding: 23px;
    background-color: #000;
    border-radius: 21.316px;
    background-image: url('./images/review3.png');
  }

  .about4right {
    display: none;
    display: none;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 300px;
  }

  .about3btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
  }

}

/* Tablets (481px to 768px) */
@media (min-width: 320px) and (max-width: 481px) {
  .about4bg {
    margin-top: 50px;
    min-width: 100%;
    height: 750px;
    background: #FFF;
  }

  .leftbntright {
    width: 51.828px;
    height: 51.828px;
    display: none;
  }

  .about4left1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    height: 322.018px;
    display: none;

  }

  .about4h1 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
  }

  .about4h10 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5%;
  }

  .about4body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    margin-top: 80px;

  }

  .about4main {
    width: 300px;
    height: 474.63px;
    display: block;
    background-color: #FFF;
    border: 1px solid rgb(110, 110, 110);
    border-radius: 20px;
  }

  .about4mr {
    width: 300px;
    height: 320px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.829);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .about4mr::before {

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    background-image: url('./images/review1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    background-color: black;
    opacity: 0.4;
    /* Apply opacity only to the background */
    border-radius: 20px;
    z-index: 1;
    /* Ensure it's behind the content */
  }

  .about4mr>* {
    position: relative;
    z-index: 2;
    /* Content stays on top of the background */
  }

  .about4h3 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 21.316px;
    font-style: normal;
    font-weight: 300;
    line-height: 85.262px;
    /* 400% */
    text-align: start;
  }

  .about4h4 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    text-align: start;
    /* 250% */
  }

  .about4p1 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 31.973px;
    text-align: start;
    letter-spacing: 1px;
    /* 375% */
  }

  .about4ml {
    width: 90%;
    height: 342.018px;
    padding: 20px;
  }

  .quotation {
    width: 70px;
    height: 70px;
    fill: #00AA14;
    display: block;
    font-size: 74px;
    margin-right: 200px;
    font-style: normal;

  }

  .about4p2 {
    width: 260.243px;
    height: 182.111px;
    color: #000;
    font-family: "Open Sans";
    font-size: 11.987px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.973px;
    /* 200% */
  }

  .qpar {
    width: 100%;

  }

  .leftmain {
    padding: 23px;
    width: 259.885px;
    height: 172.655px;
    flex-shrink: 0;
    border-radius: 21.316px;
    background: #121935;
  }

  .review2 {
    width: 62.18px;
    height: 62.18px;
    flex-shrink: 0;
    border-radius: 10.658px;
    object-fit: cover;
    float: left;
  }

  .about4h2 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 21.316px;
    font-style: normal;
    font-weight: 700;
    line-height: 85.262px;
    /* 400% */
    letter-spacing: -1.066px;
  }

  .leftmain1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .about4h5 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12.789px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    line-height: 31.973px;
    /* 250% */
  }

  .about4p3 {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 8.526px;
    font-style: normal;
    text-align: start;
    font-weight: 700;
  }

  .leftmain3 {
    width: 259.885px;
    height: 172.655px;
    padding: 23px;
    background-color: #000;
    border-radius: 21.316px;
    background-image: url('./images/review3.png');
  }

  .about4right {
    display: none;
    display: none;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 300px;
  }

  .about4btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
  }
  .about4btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
  }

}

.phar4bg {
  width: 100%;
  max-width: 1600px;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.pharcont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.phar4h1 {
  margin-top: 10px;
  color: #0D222F;
  text-align: center;
  font-family: Mont;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.phar4h2 {
  margin-top: 10px;
  width: 261.849px;
  height: 56px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  /* 200% */
}

.gird4mainimg {
  width: 196px;
  height: 100%;
  object-fit: cover;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar4bg {
    width: 100%;
    max-width: 1600px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pharcont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .phar4h1 {
    margin-top: 10px;
    color: #0D222F;
    text-align: center;
    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .phar4h2 {
    margin-top: 10px;
    width: 200px;
    height: 56px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    /* 200% */
  }

  .gird4mainimg {
    width: 86px;
    height: 100%;
    object-fit: cover;
  }

  .phar4img {
    width: 20px;
    height: auto;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar4bg {
    width: 100%;
    max-width: 1600px;
    height: 1500.625px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pharcont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .phar4h1 {
    margin-top: 10px;
    color: #0D222F;
    text-align: center;
    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .phar4h2 {
    margin-top: 10px;
    width: 261.849px;
    height: 56px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    /* 200% */
  }

  .gird4mainimg {
    width: 196px;
    height: 100%;
    object-fit: cover;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar4bg {
    width: 100%;
    max-width: 1600px;
    height: 1500.625px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pharcont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .phar4h1 {
    margin-top: 10px;
    color: #0D222F;
    text-align: center;
    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .phar4h2 {
    margin-top: 10px;
    width: 261.849px;
    height: 56px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    /* 200% */
  }

  .gird4mainimg {
    width: 196px;
    height: 100%;
    object-fit: cover;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar4bg {
    width: 100%;
    max-width: 1600px;
    height: 1500.625px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pharcont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .phar4h1 {
    margin-top: 10px;
    color: #0D222F;
    text-align: center;
    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .phar4h2 {
    margin-top: 10px;
    width: 261.849px;
    height: 56px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    /* 200% */
  }

  .gird4mainimg {
    width: 196px;
    height: 100%;
    object-fit: cover;
  }

}

@media (min-width: 320px) and (max-width: 480px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar4bg {
    width: 100%;
    max-width: 1600px;
    height: 1500.625px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pharcont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .phar4h1 {
    margin-top: 10px;
    color: #0D222F;
    text-align: center;
    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .phar4h2 {
    margin-top: 10px;
    width: 261.849px;
    height: 56px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    /* 200% */
  }

  .gird4mainimg {
    width: 196px;
    height: 100%;
    object-fit: cover;
  }

}

.phar5bg {
  width: 100%;
  max-width: 1600px;
  height: 969.625px;
  display: block;
  background-image: url('./images/phar5bg.png');
  object-fit: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  position: relative;

}

.phar5cen {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  gap: 30px;
  height: 450px;
  align-items: center;
  overflow: hidden;
}

.phar5h1 {
  margin-top: 50px;
  color: #28156D;
  text-align: center;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.phar5h2 {
  margin-top: 20px;
  color: #000;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.phar5div1 {
  width: 298px;
  height: 412px;
  flex-shrink: 0;
  background-color: #FFF;
  stroke-width: 2px;
  stroke: #5D61DF;
  filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
  border-radius: 15px;
}

.phar5div2 {
  width: 298px;
  height: 412px;
  flex-shrink: 0;
  background-color: #FFF;
  stroke-width: 2px;
  stroke: #5D61DF;
  filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
  border-radius: 15px;
}

.phar5div3 {
  width: 298px;
  height: 412px;
  flex-shrink: 0;
  background-color: #FFF;
  stroke-width: 2px;
  stroke: #5D61DF;
  filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
  border-radius: 15px;
}


.phar5h3 {
  color: #000;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 20px;
  text-align: start;
}


.phar5card3 {
  width: 261px;
  height: 199px;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.phar3p1 {
  color: #000;
  font-family: "Helvetica Now Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  /* 200% */
  text-align: start;
  margin-left: 20px;
  width: 233px;
  height: 44px;
}

.p1stroke11 {
  color: #000;
  font-family: "Helvetica Now Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.phar1btn {
  position: absolute;
  left: 20px;
  z-index: 6;
  bottom: 40%;


}

.phar2btn {
  position: absolute;
  right: 20px;
  z-index: 6;
  bottom: 40%;


}

.phar5div1,
.phar5div2,
.phar5div3 {
  width: 298px;
  height: 412px;
  flex-shrink: 0;
  background-color: #FFF;
  stroke-width: 2px;
  stroke: #5D61DF;
  filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
  border-radius: 15px;
  transition: background-color 0.3s ease, filter 0.3s ease;
  /* Add transition */
}

.phar5div1:hover {
  background-image: url('./images/phar5card1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(93, 97, 223, 0.1);
  /* Semi-transparent color */
}

.phar5div2:hover {
  background-image: url('./images/phar5card1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(93, 97, 223, 0.1);
  /* Semi-transparent color */
}

.phar5div3:hover {
  background-image: url('./images/phar3cardimg2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(93, 97, 223, 0.1);
  /* Semi-transparent color */
}

.phar5div1:hover .phar5card1,
.phar5div2:hover .phar5card2,
.phar5div3:hover .phar5card3 {
  display: none;
  /* Hide image on hover */
}

.phar5div3:hover .phar5h3 {
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 20px;
  text-align: start;
  margin-top: 80%;
}

.phar5div3:hover .phar3p1 {
  color: #ffffff;
  font-family: "Helvetica Now Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  text-align: start;
  margin-left: 20px;
  width: 233px;
  height: 44px;
}

.phar5div3:hover .p1stroke11 {
  color: #ffffff;
  font-family: "Helvetica Now Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.phar5h3 {
  color: #000;
  /* Default text color */
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 20px;
  text-align: start;
  transition: color 0.3s ease;
  /* Transition for text color */
}

.phar3p1 {
  color: #000;
  /* Default text color */
  font-family: "Helvetica Now Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  text-align: start;
  margin-left: 20px;
  width: 233px;
  height: 44px;
  transition: color 0.3s ease;
  /* Transition for text color */
}

.p1stroke11 {
  color: #000;
  /* Default text color */
  font-family: "Helvetica Now Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  transition: color 0.3s ease;
  /* Transition for text color */
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar5bg {
    width: 100%;
    max-width: 1600px;
    height: 969.625px;
    display: block;
    background-image: url('./images/phar5bg.png');
    object-fit: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    position: relative;

  }

  .phar5cen {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: 420px;
    margin-left: 33%;
    overflow: hidden;
  }

  .phar5h1 {
    margin-top: 50px;
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar5h2 {
    margin-top: 10px;
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar5div1 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }

  .phar5div2 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }

  .phar5div3 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }


  .phar5h3 {
    color: #000;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
  }


  .phar5card3 {
    width: 261px;
    height: 199px;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .phar3p1 {
    color: #000;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    /* 200% */
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
  }

  .p1stroke11 {
    color: #000;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .phar1btn {
    position: absolute;
    left: 20%;
    z-index: 6;
    bottom: 25%;


  }

  .phar2btn {
    position: absolute;
    right: 20%;
    z-index: 6;
    bottom: 25%;


  }

  .phar5div1,
  .phar5div2,
  .phar5div3 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
    transition: background-color 0.3s ease, filter 0.3s ease;
    /* Add transition */
  }

  .phar5div1:hover {
    background-image: url('./images/phar5card1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div2:hover {
    background-image: url('./images/phar5card1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div3:hover {
    background-image: url('./images/phar3cardimg2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div1:hover .phar5card1,
  .phar5div2:hover .phar5card2,
  .phar5div3:hover .phar5card3 {
    display: none;
    /* Hide image on hover */
  }

  .phar5div3:hover .phar5h3 {
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
    margin-top: 80%;
  }

  .phar5div3:hover .phar3p1 {
    color: #ffffff;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
  }

  .phar5div3:hover .p1stroke11 {
    color: #ffffff;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .phar5h3 {
    color: #000;
    /* Default text color */
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
    transition: color 0.3s ease;
    /* Transition for text color */
  }

  .phar3p1 {
    color: #000;
    /* Default text color */
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
    transition: color 0.3s ease;
    /* Transition for text color */
  }

  .p1stroke11 {
    color: #000;
    /* Default text color */
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    transition: color 0.3s ease;
    /* Transition for text color */
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar5bg {
    width: 100%;
    max-width: 1600px;
    height: 969.625px;
    display: block;
    background-image: url('./images/phar5bg.png');
    object-fit: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    position: relative;

  }

  .phar5cen {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: 420px;
    margin-left: 27%;
    overflow: hidden;
  }

  .phar5h1 {
    margin-top: 50px;
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar5h2 {
    margin-top: 10px;
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar5div1 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }

  .phar5div2 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }

  .phar5div3 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }


  .phar5h3 {
    color: #000;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
  }


  .phar5card3 {
    width: 261px;
    height: 199px;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .phar3p1 {
    color: #000;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    /* 200% */
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
  }

  .p1stroke11 {
    color: #000;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .phar1btn {
    position: absolute;
    left: 20%;
    z-index: 6;
    bottom: 25%;


  }

  .phar2btn {
    position: absolute;
    right: 20%;
    z-index: 6;
    bottom: 25%;


  }

  .phar5div1,
  .phar5div2,
  .phar5div3 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
    transition: background-color 0.3s ease, filter 0.3s ease;
    /* Add transition */
  }

  .phar5div1:hover {
    background-image: url('./images/phar5card1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div2:hover {
    background-image: url('./images/phar5card1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div3:hover {
    background-image: url('./images/phar3cardimg2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div1:hover .phar5card1,
  .phar5div2:hover .phar5card2,
  .phar5div3:hover .phar5card3 {
    display: none;
    /* Hide image on hover */
  }

  .phar5div3:hover .phar5h3 {
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
    margin-top: 80%;
  }

  .phar5div3:hover .phar3p1 {
    color: #ffffff;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
  }

  .phar5div3:hover .p1stroke11 {
    color: #ffffff;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .phar5h3 {
    color: #000;
    /* Default text color */
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
    transition: color 0.3s ease;
    /* Transition for text color */
  }

  .phar3p1 {
    color: #000;
    /* Default text color */
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
    transition: color 0.3s ease;
    /* Transition for text color */
  }

  .p1stroke11 {
    color: #000;
    /* Default text color */
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    transition: color 0.3s ease;
    /* Transition for text color */
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar5bg {
    width: 100%;
    max-width: 1600px;
    height: 969.625px;
    display: block;
    background-image: url('./images/phar5bg.png');
    object-fit: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    position: relative;

  }

  .phar5cen {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: 420px;
    margin-left: 15%;
    overflow: hidden;
  }

  .phar5h1 {
    margin-top: 50px;
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar5h2 {
    margin-top: 10px;
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar5div1 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }

  .phar5div2 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }

  .phar5div3 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }


  .phar5h3 {
    color: #000;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
  }


  .phar5card3 {
    width: 261px;
    height: 199px;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .phar3p1 {
    color: #000;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    /* 200% */
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
  }

  .p1stroke11 {
    color: #000;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .phar1btn {
    position: absolute;
    left: 130px;
    z-index: 6;
    bottom: 25%;


  }

  .phar2btn {
    position: absolute;
    right: 130px;
    z-index: 6;
    bottom: 25%;


  }

  .phar5div1,
  .phar5div2,
  .phar5div3 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
    transition: background-color 0.3s ease, filter 0.3s ease;
    /* Add transition */
  }

  .phar5div1:hover {
    background-image: url('./images/phar5card1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div2:hover {
    background-image: url('./images/phar5card1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div3:hover {
    background-image: url('./images/phar3cardimg2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div1:hover .phar5card1,
  .phar5div2:hover .phar5card2,
  .phar5div3:hover .phar5card3 {
    display: none;
    /* Hide image on hover */
  }

  .phar5div3:hover .phar5h3 {
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
    margin-top: 80%;
  }

  .phar5div3:hover .phar3p1 {
    color: #ffffff;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
  }

  .phar5div3:hover .p1stroke11 {
    color: #ffffff;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .phar5h3 {
    color: #000;
    /* Default text color */
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
    transition: color 0.3s ease;
    /* Transition for text color */
  }

  .phar3p1 {
    color: #000;
    /* Default text color */
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
    transition: color 0.3s ease;
    /* Transition for text color */
  }

  .p1stroke11 {
    color: #000;
    /* Default text color */
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    transition: color 0.3s ease;
    /* Transition for text color */
  }
}
@media (min-width:410px) and (max-width: 481px) {
.phar5cen {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  height: 420px;
  margin-left: 15% !important;
  overflow: hidden;
}
}

/* Mobile Devices (up to 480px) */
@media (min-width: 320px) and (max-width: 481px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar5bg {
    width: 100%;
    max-width: 1600px;
    height: 969.625px;
    display: block;
    background-image: url('./images/phar5bg.png');
    object-fit: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    position: relative;

  }

  .phar5cen {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: 420px;
    margin-left: 5%;
    overflow: hidden;
  }

  .phar5h1 {
    margin-top: 100px;
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar5h2 {
    margin-top: 10px;
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar5div1 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }

  .phar5div2 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }

  .phar5div3 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
  }


  .phar5h3 {
    color: #000;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
  }


  .phar5card3 {
    width: 261px;
    height: 199px;
    object-fit: cover;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .phar3p1 {
    color: #000;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    /* 200% */
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
  }

  .p1stroke11 {
    color: #000;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .phar1btn {
    position: absolute;
    left: 30px;
    z-index: 6;
    bottom: 25%;


  }

  .phar2btn {
    position: absolute;
    right: 30px;
    z-index: 6;
    bottom: 25%;


  }

  .phar5div1,
  .phar5div2,
  .phar5div3 {
    width: 298px;
    height: 412px;
    flex-shrink: 0;
    background-color: #FFF;
    stroke-width: 2px;
    stroke: #5D61DF;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.05));
    border-radius: 15px;
    transition: background-color 0.3s ease, filter 0.3s ease;
    /* Add transition */
  }

  .phar5div1:hover {
    background-image: url('./images/phar5card1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div2:hover {
    background-image: url('./images/phar5card1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div3:hover {
    background-image: url('./images/phar3cardimg2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(93, 97, 223, 0.1);
    /* Semi-transparent color */
  }

  .phar5div1:hover .phar5card1,
  .phar5div2:hover .phar5card2,
  .phar5div3:hover .phar5card3 {
    display: none;
    /* Hide image on hover */
  }

  .phar5div3:hover .phar5h3 {
    color: #ffffff;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
    margin-top: 80%;
  }

  .phar5div3:hover .phar3p1 {
    color: #ffffff;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
  }

  .phar5div3:hover .p1stroke11 {
    color: #ffffff;
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .phar5h3 {
    color: #000;
    /* Default text color */
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    text-align: start;
    transition: color 0.3s ease;
    /* Transition for text color */
  }

  .phar3p1 {
    color: #000;
    /* Default text color */
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    text-align: start;
    margin-left: 20px;
    width: 233px;
    height: 44px;
    transition: color 0.3s ease;
    /* Transition for text color */
  }

  .p1stroke11 {
    color: #000;
    /* Default text color */
    font-family: "Helvetica Now Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    transition: color 0.3s ease;
    /* Transition for text color */
  }
}

.phar6bg {
  width: 100%;
  height: 400.625px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phar6cen {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 90px;
}

.phar6h1 {
  color: #000;
  text-align: center;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.phar6h2 {
  color: #000;
  text-align: center;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.phar6div2 {
  width: 278px;
  height: 354px;
  flex-shrink: 0;
  background-color: #00AA14;
  border-radius: 20px;
  position: relative;
}

.phar5img2 {
  position: absolute;
  top: -35px;
  left: 35%;
}

.about3btn {
  display: none;
}

.phar6h3 {
  margin-top: 70px;
  color: #FDFEFF;
  text-align: center;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.phar6h4 {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.phar6p1 {
  margin-top: 30px;
  height: 100px;
  color: #FDFEFF;
  text-align: center;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.phar6p2 {
  color: #FDFEFF;
  text-align: center;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

.prago {
  width: 159px;
  height: 159px;
  flex-shrink: 0;
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar6bg {
    width: 100%;
    height: 500.625px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .phar6cen {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
  }

  .phar6h1 {
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .phar6h2 {
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar6div1 {
    display: none;
  }

  .phar6div2 {
    width: 278px;
    height: 354px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 20px;
    position: relative;
  }

  .phar5img2 {
    position: absolute;
    top: -35px;
    left: 35%;
  }

  .phar6h3 {
    margin-top: 70px;
    color: #FDFEFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .phar6h4 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar6p1 {
    margin-top: 30px;
    height: 100px;
    color: #FDFEFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .phar6p2 {
    color: #FDFEFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }

  .prago {
    width: 159px;
    height: 159px;
    flex-shrink: 0;
  }

  .about3btn {
    display: flex;
    gap: 30px;
    margin-top: 50px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar6bg {
    width: 100%;
    height: 500.625px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .phar6cen {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
  }

  .phar6h1 {
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .phar6h2 {
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar6div1 {
    display: none;
  }

  .phar6div2 {
    width: 278px;
    height: 354px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 20px;
    position: relative;
  }

  .phar5img2 {
    position: absolute;
    top: -35px;
    left: 35%;
  }

  .phar6h3 {
    margin-top: 70px;
    color: #FDFEFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .phar6h4 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar6p1 {
    margin-top: 30px;
    height: 100px;
    color: #FDFEFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .phar6p2 {
    color: #FDFEFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }

  .prago {
    width: 159px;
    height: 159px;
    flex-shrink: 0;
  }

  .about3btn {
    display: flex;
    gap: 30px;
    margin-top: 50px;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  * {
    padding: 0;
    margin: 0%;
    box-sizing: border-box;
  }

  .phar6bg {
    width: 100%;
    height: 500.625px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .phar6cen {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
  }

  .phar6h1 {
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .phar6h2 {
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar6div1 {
    display: none;
  }

  .phar6div2 {
    width: 278px;
    height: 354px;
    flex-shrink: 0;
    background-color: #00AA14;
    border-radius: 20px;
    position: relative;
  }

  .phar5img2 {
    position: absolute;
    top: -35px;
    left: 35%;
  }

  .phar6h3 {
    margin-top: 70px;
    color: #FDFEFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .phar6h4 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phar6p1 {
    margin-top: 30px;
    height: 100px;
    color: #FDFEFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .phar6p2 {
    color: #FDFEFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }

  .prago {
    width: 159px;
    height: 159px;
    flex-shrink: 0;
  }

  .about3btn {
    display: flex;
    gap: 30px;
    margin-top: 50px;
  }

}

/* Header section */
.header-section {
  text-align: center;
  /* position: relative; */
  /* margin-bottom: 0; */
  border: none;
  /* Remove any borders */
  box-shadow: none;
  /* Ensure no shadow */
}

.pharmaciesbgmaintop {
  background-image: url('./images/Otherpagetopbg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 1606px;
  height: 904.55px;
  position: relative;
  top: -254px;
  left: -3px;
  z-index: -2;

}

.brand-name {
  font-family: 'Cursive', sans-serif;
  font-size: 60px;
  color: #28156D;
  font-weight: 400px;
  text-align: center;
  font-style: normal;
  position: absolute;
  top: 250px;
  left: 700px;
  z-index: 1;
}

.page-title {
  position: relative;
  top: 300px;
  font-size: 60px;
}

.prg-pharmaciessecondsec {
  background-image: url('./images/phsectwo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1600px;
  /* Changed to make it responsive */
  min-width: 100%;
  height: 1099px;
  margin-top: -300px;
  display: flex;
  align-items: center;
}

.prg-distribution-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prg-text-section {
  width: 60%;
  text-align: start;
  padding-right: 100px;
}

.prg-brand-name-small2 {
  color: #28156D;
  font-family: 'Brush Script Mt';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.prg-section-title2 {
  color: #000;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: normal;
}

.prg-section-description {
  font-family: 'Poppins';
  font-size: 22px;
  line-height: 36px;
  width: 597.7px;
}

.prg-image-content {
  /* padding-left: 40px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}

.prg-main-image2 {
  width: 517.9px;
  /* Adjusted width */
  height: 568.9px;
  object-fit: contain;
  position: relative;
  overflow: hidden;
}

.prg-small-image2 {
  width: 260.39px;
  /* Adjusted smaller image width */
  height: 240.39;
  padding-top: 450px;
  margin-left: 50px;
  position: absolute;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .prg-pharmaciessecondsec {
    background-image: url('./images/phsectwo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1000px;
    /* Changed to make it responsive */
    min-width: 100%;
    height: 1099px;
    display: flex;
    align-items: center;
  }

  .prg-distribution-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .prg-text-section {
    width: 60%;
    text-align: start;
    padding-right: 100px;
  }

  .prg-brand-name-small2 {
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .prg-section-title2 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .prg-section-description {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 1.8;
  }

  .prg-image-content {
    /* padding-left: 40px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
  }

  .prg-main-image2 {
    width: 417.9px;
    /* Adjusted width */
    height: 468.9px;
    object-fit: contain;
    position: relative;
    overflow: hidden;
    padding: 0% !important;
    margin-right: 20px;
  }

  .prg-small-image2 {
    width: 160.39px;
    /* Adjusted smaller image width */
    height: 140.39;
    padding-top: 450px;
    margin-left: 50px;
    position: absolute;
  }

}

/* Small Laptops (769px to 1024px) */
@media (min-width: 550px) and (max-width: 1024px) {
  .prg-pharmaciessecondsec {
    background-image: url('./images/about1tbl.png');
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1600px;
    /* Changed to make it responsive */
    min-width: 100%;
    height: 1600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prg-distribution-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .prg-text-section {
    width: 60%;
    text-align: start;
    padding-right: 100px;
  }

  .prg-brand-name-small2 {
    margin-top: 10%;
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
  }

  .prg-section-title2 {
    width: 360px;
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
  }

  .prg-section-description {
    width:  90%;
    height: 415px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    position: absolute;
    padding-top: 650px;
  }

  .prg-image-content {
    /* padding-left: 40px; */
    width: 100%;
  }

  .prg-main-image2 {
    width: 100%;
    height: 585.3px;
    border-radius: 30px;
    flex-shrink: 0;
    object-fit: contain;
    position: relative;
    object-fit: cover;
  }

  .prg-small-image2 {
    width: 260.39px;
    /* Adjusted smaller image width */
    height: 240.39;
    padding-top: 450px;
    margin-left: 30px;
    position: absolute;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 550px) {
  .prg-pharmaciessecondsec {
    background-image: url('./images/phar2mbl.png');
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1600px;
    /* Changed to make it responsive */
    min-width: 100%;
    height: 1704.442px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prg-distribution-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .prg-text-section {
    width: 60%;
    text-align: start;
    padding-right: 100px;
  }

  .prg-brand-name-small2 {
    margin-top: 10%;
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .prg-section-title2 {
    width: 100%;
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
  }

  .prg-section-description {
    width: 480px;
    height: 415px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    position: absolute;
    padding-top: 600px;
  }

  .prg-image-content {
    /* padding-left: 40px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
  }

  .prg-main-image2 {
    width: 479.18px;
    /* Increased by 20% */
    height: 487.75px;
    /* Increased by 20% */
    flex-shrink: 0;
    object-fit: contain;
    position: relative;
  }

  .prg-small-image2 {
    width: 260.39px;
    /* Adjusted smaller image width */
    height: 240.39;
    padding-top: 450px;
    margin-left: 30px;
    position: absolute;
  }
}

@media screen and (max-width: 480px) {
  .prg-pharmaciessecondsec {
    background-image: url('./images/phar2mbl.png');
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1600px;
    margin-top: -500px;
    min-width: 100%;
    height: 1604.442px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prg-distribution-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .prg-text-section {
    width: 60%;
    text-align: start;
    padding-right: 100px;
  }

  .prg-brand-name-small2 {
    margin-top: 10%;
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .prg-section-title2 {
    width: 100%;
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .prg-section-description {
    width: 400px;
    height: 415px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    position: absolute;
    padding-top: 570px;
  }

  .prg-image-content {
    /* padding-left: 40px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
  }

  .prg-main-image2 {
    width: 399.317px;
    height: 406.462px;
    flex-shrink: 0;
    object-fit: contain;
    position: relative;
  }

  .prg-small-image2 {
    width: 260.39px;
    /* Adjusted smaller image width */
    height: 240.39;
    padding-top: 450px;
    margin-left: 20px;
    position: absolute;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .prg-pharmaciessecondsec {
    background-image: url('./images/phar2mbl.png');
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1600px;
    margin-top: -700px;
    min-width: 100%;
    height: 1750px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prg-distribution-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .prg-text-section {
    width: 100% !important;
    text-align: start;
  }

  .prg-brand-name-small2 {
    margin-top: 40%;
    width: 100% !important;
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .prg-section-title2 {
    width: 100% !important;
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .prg-section-description {
    width: 90%;
    height: 415px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    position: absolute;
    padding-top: 450px;
  }

  .prg-image-content {
    width: 100% !important;
    /* padding-left: 40px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .prg-main-image2 {
    width: 98%;
    height: 366.462px;
    flex-shrink: 0;
    object-fit: cover;
    position: relative;
    border-radius: 20px;
  }

  .prg-small-image2 {
    width: 164.39px;
    /* Adjusted smaller image width */
    height: 240.39;
    padding-top: 345px;
    margin-left: 23px;

    position: absolute;
  }
}

/* Container styling */
.prag-Phsection3 {
  background-image: url('./images/phsection3.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  /* Changed to make it responsive */
  height: 870px;
  max-width: 1600px;
  position: relative;
  display: flex;
  gap: 258px;
  padding: 40px;
  background-color: #fff;
  margin-top: 40px;
}

/* Image Section */
.prag-image-section3 {
  display: flex;
  flex-direction: column;
}

.prag-image-item13 {
  width: 491.16px;
  height: 356.15px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 40%;
}

.prag-image-item23 {
  width: 491.16px;
  height: 315.4px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 185px;
  bottom: 248.1px;
}

/* Text Section */
.prag-text-section3 {
  width: 597.7px;
  height: 299.53px;
  margin-top: 80px;
}

.prag-brand-name3 {
  font-family: 'Brush Script Mt';
  font-size: 55px;
  color: #28156D;
  width: 145px;
  justify-content: start;
  text-align: right;
  font-weight: 400;
  left: 960px;
  top: 340px;
}

.prag-section-title3 {
  font-family: "Playfair Display";
  font-size: 55px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  text-align: start;
}

.prag-section-description3 {
  font-family: 'Poppins';
  text-align: start;
  font-size: 18px;
  color: #000000;
  line-height: 1.7;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .prag-Phsection3 {
    background-image: url('./images/phsection3.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    height: 1100px;
    max-width: 1600px;
    position: relative;
    display: flex;
    gap: 258px;
    padding: 40px;
    background-color: #fff;
    margin-top: 40px;
  }

  /* Image Section */
  .prag-image-section3 {
    display: flex;
    flex-direction: column;
  }

  .prag-image-item13 {
    width: 567.46px;
    /* Increased by an additional 20% */
    height: 411.48px;
    /* Increased by an additional 20% */
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    left: 322px;
    bottom: -268px;
  }

  .prag-image-item23 {
    width: 472.88px;
    /* Increased by an additional 20% */
    height: 303.67px;
    /* Increased by an additional 20% */
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 100px !important;
    bottom: 300px !important;
  }

  /* Text Section */
  .prag-text-section3 {
    width: 390px;
    height: 299.53px;
    margin-top: 200px;
  }

  .prag-brand-name3 {
    width: 100%;
    color: #28156D;
    text-align: start !important;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -729px;

    margin-left: 5% !important;
  }

  .prag-section-title3 {
    width: 96%;
    color: #000;
    text-align: start !important;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 170px;
    margin-left: 1.5%;
  }

  .prag-section-description3 {
    width: 1000px;
    height: 299.529px;
    margin-left: 5%;
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    margin-top: 650px;
  }

}


@media screen and (max-width: 1200px) {
  .prag-Phsection3 {
    background-image: url('./images/phsection3.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    height: 1100px;
    flex-direction: column;
    max-width: 1600px;
    position: relative;
    display: flex;
    gap: 258px;
    padding: 0px;
    background-color: #fff;
    margin-top: 40px;
  }

  .prag-image-item23 {
    width: 491.16px;
    height: 315.4px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 185px;
    bottom: 800px;
  }

}

/* Small Laptops (769px to 1024px) */
@media (min-width: 550px) and (max-width: 1024px) {
  .prag-Phsection3 {
    background-image: url('./images/phsection3.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    height: 1200.55px;
    max-width: 1600px;
    position: relative;
    display: flex;
    gap: 258px;
    padding: 40px;
    background-color: #fff;
    margin-top: 40px;
  }

  /* Image Section */
  .prag-image-section3 {
    display: flex;
    flex-direction: column;
  }

  .prag-image-item13 {
    width: 70%;
    /* Increased by another 20% */
    height: 342.9px;
    /* Increased by another 20% */
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    left: 25%;
    border-radius: 30px;
    bottom: -300px;
  }

  .prag-image-item23 {
    width: 70%;
    /* Increased by 20% */
    height: 300px;
    /* Increased by 20% */
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 5%;
    bottom: 400px;
    border-radius: 30px;
  }

  /* Text Section */
  .prag-text-section3 {
    width: 100%;
    height: 299.53px;
    margin-top: 200px;
  }

  .prag-brand-name3 {
    width: 100%;
    color: #28156D;
    text-align: center !important;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -700px;
  }

  .prag-section-title3 {
    width: 90%;
    color: #000;
    text-align: center !important;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 190px;
  }

  .prag-section-description3 {
    width: 90%;
    height: 299.529px;
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    margin-top: 650px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 550px) {
  .prag-Phsection3 {
    background-image: url('./images/phsection3.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    height: 1200.55px;
    max-width: 1600px;
    position: relative;
    display: flex;
    gap: 258px;
    padding: 40px;
    background-color: #fff;
    margin-top: 40px;
  }

  /* Image Section */
  .prag-image-section3 {
    display: flex;
    flex-direction: column;
  }

  .prag-image-item13 {
    width: 394.07px;
    height: 285.75px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    left: 0px;
    bottom: -300px;
  }

  .prag-image-item23 {
    width: 328.395px;
    height: 210.88px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 50px;
    bottom: 500px;
  }

  /* Text Section */
  .prag-text-section3 {
    width: 390px;
    height: 299.53px;
    margin-top: 200px;
  }

  .prag-brand-name3 {
    width: 80%;
    color: #28156D;
    text-align: center !important;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -650px;
  }

  .prag-section-title3 {
    width: 80%;
    color: #000;
    text-align: center !important;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 190px;
  }

  .prag-section-description3 {
    width: 450px;
    height: 299.529px;
    flex-shrink: 0;
    color: #000;
    text-align: start;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    margin-top: 550px;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {

  /* Container styling */
  .prag-Phsection3 {
    background-image: url('./images/phsection3.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    height: 1200.55px;
    max-width: 1600px;
    position: relative;
    display: flex;
    gap: 258px;
    background-color: #fff;
    margin-top: 40px;
  }

  /* Image Section */
  .prag-image-section3 {
    display: flex;
    flex-direction: column;
  }

  .prag-image-item13 {
    width: 328.395px;
    height: 238.127px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    left: 55px;
    bottom: -300px;
  }

  .prag-image-item23 {
    width: 328.395px;
    height: 210.88px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 25px;
    bottom: 500px;
  }

  /* Text Section */
  .prag-text-section3 {
    width: 390px;
    height: 299.53px;
    margin-top: 200px;
  }

  .prag-brand-name3 {
    width: 80%;
    color: #28156D;
    text-align: center;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: normal;
    margin-top: -590px;
  }

  .prag-section-title3 {
    width: 80%;
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 190px;
  }

  .prag-section-description3 {
    width: 382.786px;
    height: 299.529px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    margin-top: 550px;
  }
}

/* Mobile Devices (up to 480px) */
@media (min-width: 320px) and (max-width: 480px) {

  /* Container styling */
  .prag-Phsection3 {
    background-image: url('./images/phsection3.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    height: 1100.55px;
    max-width: 1600px;
    position: relative;
    display: flex;
    gap: 258px;
    background-color: #fff;
    margin-top: 40px;
  }

  /* Image Section */
  .prag-image-section3 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .prag-image-item13 {
    width: 75%;
    height: 210.88px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    left: 20%;
    bottom: -227px;
  }

  .prag-image-item23 {
    width: 70%;
    height: 210.88px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 18px;
    bottom: 590px;
  }

  /* Text Section */
  .prag-text-section3 {
    width: 100%;
    height: 299.53px;
    margin-top: 200px;
  }

  .prag-brand-name3 {
    width: 100%;
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    text-align: center !important;
    line-height: normal;
    margin-top: -590px;
  }

  .prag-section-title3 {
    width: 100%;
    color: #000;
    text-align: center !important;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 115px;
  }

  .prag-section-description3 {
    width: 100%;
    height: 299.529px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    margin-top: 550px;
    padding-right: 10px ;
    padding-left: 10px;
  }
}

.pharmaciessecondsec4 {
  background-image: url('./images/phsection4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  /* Changed to make it responsive */
  max-width: 1600px;
  height: 937.62px;
  position: relative;
}

.distribution-section {
  display: flex;
  /* Use flexbox for layout */
  align-items: center;
  /* Center items vertically */
  justify-content: space-between;
  /* Space between text and images */
}

.distribution-sectionchng {
  display: flex;
  /* Use flexbox for layout */
  justify-content: center;
}

.text-contenph {
  max-width: 600px;
  /* Set max width for text content */
  height: 600px;
}

.brand-name-small {
  font-family: 'Brush Script Mt';
  font-size: 55px;
  color: #28156D;
  font-weight: 400;
  text-align: start;
}

.section-title {
  width: 597.699px;
  color: #000;
  font-family: "Playfair Display";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 73.32px;
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-description {
  width: 597.699px;
  height: 299.529px;
  font-size: 18px;
  color: #000000;
  line-height: 1.7;
  margin-bottom: 20px;
  text-align: start;
  font-family: 'Poppins';
  font-weight: 400;

}

.image-content {
  height: 600px;
}

.main-image {
  width: 558.9px;
  height: 558.9px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  /* Space between text and image */
}

.small-image {
  width: 260.385px;
  height: 240.385px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 20%;
  left: 58%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.phcenter {
  margin-top: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  height: 600px;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .pharmaciessecondsec4 {
    background-image: url('./images/phsection4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    max-width: 1600px;
    height: 937.62px;
    position: relative;
  }

  .distribution-section {
    display: flex;
    /* Use flexbox for layout */
    align-items: center;
    /* Center items vertically */
    justify-content: space-between;
    /* Space between text and images */
  }

  .distribution-sectionchng {
    display: flex;
    /* Use flexbox for layout */
    justify-content: center;
  }

  .text-contenph {
    max-width: 600px;
    /* Set max width for text content */
    height: 600px;
  }

  .brand-name-small {
    font-family: 'Brush Script Mt';
    font-size: 55px;
    color: #28156D;
    font-weight: 400;
    text-align: start !important;
    margin-left: 5%;
  }

  .section-title {
    width: 597.699px;
    color: #000;
    font-family: "Playfair Display";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: 73.32px;
    text-align: start !important;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 5%;
  }

  .section-description {
    width: 447.699px;
    height: 299.529px;
    font-size: 18px;
    color: #000000;
    line-height: 1.7;
    margin-bottom: 20px;
    text-align: start;
    font-family: 'Poppins';
    font-weight: 400;
    margin-left: 5%;

  }

  .image-content {
    height: 600px;
  }

  .main-image {
    width: 379.76px;
    height: 412.12px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: -105px;

    margin-top: 120px;
  }

  .small-image {
    width: 188.31px;
    height: 172.31px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 20%;
    /* Adjust to align with the center of the main image */
    left: 61%;
    /* Adjust to position it in front of the main image */
    transform: translate(-50%, -50%);
    /* Center the small image over the main image */
    z-index: 1;
    /* Ensure it appears above the main image */
  }

  .phcenter {
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    height: 600px;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .pharmaciessecondsec4 {
    background-image: url('./images//phar5bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    max-width: 1600px;
    height: 1037.62px;
    position: relative;
  }

  .distribution-section {
    display: flex;
    /* Use flexbox for layout */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
  }

  .distribution-sectionchng {
    display: flex;
    /* Use flexbox for layout */
    justify-content: center;
  }

  .text-contenph {
    max-width: 600px;
    /* Set max width for text content */
    height: 600px;
  }

  .brand-name-small {
    width: 100% !important;
    margin-top: 10%;
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
  }

  .section-title {
    width: 100% !important;
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .section-description {
    width: 95% !important;
    height: 215px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    position: absolute;
    left: 20px;
    top: 658px;
    text-align: center;

  }

  .image-content {
    height: 600px;
  }

  .main-image {
    width: 463.585px;
    height: 400.046px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    right: 100px;
    top: 233px;
    position: absolute;
  }

  .small-image {
    width: 183.912px;
    height: 168.938px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 562px;
    left: 38%;

    transform: translate(-50%, -50%);
    z-index: 1;
    position: absolute;
  }

  .phcenter {
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 60px;
    height: 600px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .pharmaciessecondsec4 {
    background-image: url('./images/phar5bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    max-width: 1600px;
    height: 1037.62px;
    position: relative;
  }

  .distribution-section {
    display: flex;
    /* Use flexbox for layout */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
  }

  .distribution-sectionchng {
    display: flex;
    /* Use flexbox for layout */
    justify-content: center;
  }

  .text-contenph {
    max-width: 600px;
    /* Set max width for text content */
    height: 600px;
  }

  .brand-name-small {
    width: 90% !important;
    margin-top: 10%;
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
  }

  .section-title {
    width: 90% !important;
    color: #000;
    font-family: "Playfair Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .section-description {
    width: 90% !important;
    height: 415px;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    position: absolute;
    left: 20px;
    top: 658px;
    text-align: center;

  }

  .image-content {
    height: 600px;
  }

  .main-image {
    width: 406.585px;
    height: 387.046px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    right: 100px;
    top: 260px;
    position: absolute;
  }

  .small-image {
    width: 183.912px;
    height: 168.938px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 540px;
    left: 25%;
    transform: translate(-50%, -50%);
    z-index: 1;
    position: absolute;
  }

  .phcenter {
    margin-top: 8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 60px;
    height: 600px;
  }
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .pharmaciessecondsec4 {
    background-image: url('./images/phar4mbl.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    max-width: 1600px;
    height: 1137.62px;
    position: relative;
  }

  .distribution-section {
    display: flex;
    /* Use flexbox for layout */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
  }

  .distribution-sectionchng {
    display: flex;
    /* Use flexbox for layout */
    justify-content: center;
  }

  .text-contenph {
    width: 100%;
    /* Set max width for text content */
    height: 600px;
  }

  .brand-name-small {
    width: 90% !important;
    margin-top: 10%;
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
  }

  .section-title {
    width: 100% !important;
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .section-description {
    width: 400px;
    height: 415px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 214.286% */
    position: absolute;
    left: 20px;
    top: 650px;
    text-align: center;

  }

  .image-content {
    height: 600px;
  }

  .main-image {
    width: 349.585px;
    height: 369.046px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    right: 20px;
    /* Space between text and image */
    top: 220px;
    position: absolute;
  }

  .small-image {
    width: 168.912px;
    height: 155.938px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 520px;
    left: 25%;
    /* Adjust to position it in front of the main image */
    transform: translate(-50%, -50%);
    /* Center the small image over the main image */
    z-index: 1;
    /* Ensure it appears above the main image */
    position: absolute;
  }

  .phcenter {
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 60px;
    height: 600px;
  }

}

/* Mobile Devices (up to 480px) */
@media (min-width: 320px) and (max-width: 480px) {
  .pharmaciessecondsec4 {
    background-image: url('./images/phar4mbl.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Changed to make it responsive */
    max-width: 1600px;
    height: 1137.62px;
    position: relative;
  }

  .distribution-section {
    display: flex;
    /* Use flexbox for layout */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
  }

  .distribution-sectionchng {
    display: flex;
    /* Use flexbox for layout */
    justify-content: center;
  }

  .text-contenph {
   width: 100% !important;
    /* Set max width for text content */
    height: 600px;
  }

  .brand-name-small {
    width: 100% !important;
    margin-top: 10%;
    color: #28156D;
    font-family: 'Brush Script Mt';
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center !important;
  }

  .section-title {
    width: 100% !important;
    color: #000;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center !important;
  }

  .section-description {
    width: 90%;
    height: 415px;
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    position: absolute;
    left: 20px;
    top: 650px;
    text-align: start;

  }

  .image-content {
    height: 600px;
  }

  .main-image {
    width: 85%;
    height: 296.046px;

    flex-shrink: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    right: 20px;
    /* Space between text and image */
    top: 220px;
    position: absolute;
  }

  .small-image {
    width: 40%;

    flex-shrink: 0;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 520px;
    left: 25%;
    /* Adjust to position it in front of the main image */
    transform: translate(-50%, -50%);
    /* Center the small image over the main image */
    z-index: 1;
    /* Ensure it appears above the main image */
    position: absolute;
  }

  .phcenter {
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 60px;
    width: 100%;
    height: 600px;
  }

}

.phsection5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  gap: 200px;
  margin: 0 auto;
  /* background-image: url('./images/formbg.png'); */
  background-size: cover;
  height: 820px;
  max-width: 1600px;
  width: 100%;

  z-index: 99;
  background-repeat: no-repeat;
}

/* Image Section */
.image-section5 {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
}

.image-item-wrap {
  position: relative;
}

.image-item-main {
  width: 528.005px;
  height: 566.154px;
  flex-shrink: 0;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 229.69px;
  z-index: 99;
}

.image-item-sub1 {
  width: 260.385px;
  height: 240.385px;
  position: absolute;
  top: 130px;
  left: -280px;
  z-index: 100;
}

.image-item-sub2 {
  width: 260.39;
  height: 240.39;
  position: absolute;
  right: 250px;
  bottom: -100px;
}

/* Text Section */
.text-section5 {
  width: 50%;
  margin-top: 100px;
  margin-left: 10px;
  text-align: start;
}

.brand-name5 {
  font-family: 'Brush Script MT';
  font-size: 55px;
  text-align: start;
  font-weight: 400;
  color: #3a0070;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.section-title5 {
  font-family: 'Playfair Display';
  font-size: 55px;
  font-weight: 500;
  color: #000000;

  margin-left: 10px;
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-description5 {
  width: 70%;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Poppins';
  color: #000000;
  margin-left: 10px;
  line-height: 1.7;
  width: 600px;
}

/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .phsection5 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    gap: 50px;
    margin: 0 auto;
    /* background-image: url('./images/formbg.png'); */
    background-size: cover;
    height: 550px;
    max-width: 1600px;
    width: 100%;

    z-index: 99;
    background-repeat: no-repeat;
  }

  .image-item-main {
    max-width: 400px;
    object-fit: cover;
    height: auto;
    left: 100px;
  }

  .image-item-sub1 {
    max-width: 170px;
    height: 150px;
    border: 10px;
    left: -180px;
  }

  .image-item-sub2 {
    max-width: 200px;
    object-fit: cover;
    right: 180px;
  }

  .text-section5 {
    margin-top: 30px;
    width: 40%;
    padding-left: 0;
  }

  .brand-name5 {
    font-size: 40px;
  }

  .section-title5 {
    margin-top: 10px;
    width: 80%;
    margin-bottom: 10px;
    font-size: 35px;
  }

  .section-description5 {
    width: 90%;
    font-size: 14px;
  }
}

/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .phsection5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    /* background-image: url('./images/formbg.png'); */
    background-size: cover;
    height: 1100px;
    max-width: 1600px;
    width: 100%;
    z-index: 99;
    background-repeat: no-repeat;
    position: relative;
  }

  /* Image Section */
  .image-section5 {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .image-item-wrap {
    position: relative;
  }

  .image-item-main {
    width: 501.628px;
    height: 459.071px;

    border-radius: 12px;
    object-fit: cover;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    top: 100px;
    left: 20px;
    z-index: 99;
  }

  .image-item-sub1 {
    width: 204.186px;
    height: auto;
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: -105px;
    z-index: 100;
  }

  .image-item-sub2 {
    width: 167.186px;
    height: auto;
    position: absolute;
    z-index: 1;
    bottom: 100px;
    left: -308px;

    /* right: 250px; */
    /* bottom: -100px;*/
    object-fit: cover;
  }

  /* Text Section */
  .text-section5 {
    width: 100%;
    text-align: start;
  }

  .brand-name5 {
    font-family: 'Brush Script MT';
    font-size: 55px;
    text-align: center;
    font-weight: 400;
    width: 90%;
    color: #3a0070;
    margin-bottom: 10px;
    position: absolute;
    top: 46px;
  }

  .section-title5 {
    width: 100%;
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center !important;
  }

  .section-description5 {
    width: 100%;
    height: 231.974px;
    flex-shrink: 0;
    color: #000;
    text-align: center !important;
    font-family: 'Poppins';
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .phsection5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    /* background-image: url('./images/formbg.png'); */
    background-size: cover;
    height: 1100px;
    max-width: 1600px;
    width: 100%;
    z-index: 99;
    background-repeat: no-repeat;
    position: relative;
  }

  /* Image Section */
  .image-section5 {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -250px;

  }

  .image-item-wrap {
    position: relative;
  }

  .image-item-main {
    width: 450.628px;
    height: 421.071px;
    border-radius: 12px;
    object-fit: cover;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    top: 320px;
    left: 20px;
    z-index: 99;
  }

  .image-item-sub1 {
    width: 204.186px;
    height: auto;
    position: absolute;
    bottom: 0px;
    top: 170px;
    left: -105px;
    z-index: 100;
  }

  .image-item-sub2 {
    width: 167.186px;
    height: 174.419px;
    position: absolute;
    z-index: 1;
    bottom: -45px;
    left: -275px;
    /* right: 250px; */
    /* bottom: -100px;*/
    object-fit: cover;
  }

  /* Text Section */
  .text-section5 {
    width: 100%;
    text-align: start;
  }

  .brand-name5 {
    width: 90%;
    font-family: 'Brush Script MT';
    font-size: 55px;
    text-align: start;
    font-weight: 400;
    color: #3a0070;
    margin-bottom: 10px;
    position: absolute;
    top: 26px;
  }

  .section-title5 {
    width: 99%;
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    margin-top: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .section-description5 {
    width: 100%;
    height: 231.974px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .phsection5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    /* background-image: url('./images/formbg.png'); */
    background-size: cover;
    height: 1100px;
    max-width: 1600px;
    width: 100%;
    z-index: 99;
    background-repeat: no-repeat;
    position: relative;
  }

  /* Image Section */
  .image-section5 {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -250px;

  }

  .image-item-wrap {
    position: relative;
  }

  .image-item-main {
    width: 370.628px;
    height: 321.071px;
    border-radius: 12px;
    object-fit: cover;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    top: 300px;
    left: 20px;
    z-index: 99;
  }

  .image-item-sub1 {
    width: 170px;
    height: auto;
    position: absolute;
    bottom: 0px;
    top: 170px;
    left: -105px;
    z-index: 100;
  }

  .image-item-sub2 {
    width: 167.186px;
    height: 174.419px;
    position: absolute;
    z-index: 1;
    bottom: -45px;
    left: -275px;
    /* right: 250px; */
    /* bottom: -100px;*/
    object-fit: cover;
  }

  /* Text Section */
  .text-section5 {
    width: 100%;
    text-align: start;
  }

  .brand-name5 {
    font-family: 'Brush Script MT';
    font-size: 55px;
    text-align: start;
    font-weight: 400;
    color: #3a0070;
    margin-bottom: 10px;
    position: absolute;
    top: 46px;
  }

  .section-title5 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 35px;
    margin-top: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .section-description5 {
    width: 100%;
    height: 231.974px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

}

/* Mobile Devices (up to 480px) */
@media (min-width: 320px) and (max-width: 480px) {

  /* Container styling */
  .phsection5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    margin: 0 auto;
    /* background-image: url('./images/formbg.png'); */
    background-size: cover;
    width: 100%;
    height: 1100px;
    z-index: 99;
    background-repeat: no-repeat;
    position: relative;
    padding-bottom: -200px ;
  }

  /* Image Section */
  .image-section5 {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -250px;
  }

  .image-item-wrap {
    position: relative;
  }

  .image-item-main {
    width: 100%;
    height: 309.071px;
    object-fit: contain;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    top: 350px;
    left: 0px;
    z-index: 99;
  }

  .image-item-sub1 {
    width: 128.186px !important;
    height: 116.419px !important;
    position: absolute;
    bottom: 0px;
    top: 250px;
    left: -105px;
    z-index: 100;
    object-fit: cover;
  }

  .image-item-sub2 {
    width: 106.186px !important;
    height: 97.419px !important;
    position: absolute;
    z-index: 1;
    bottom: -128px;
    left: -185px;
    /* right: 250px; */
    /* bottom: -100px;
    /* right: 250px; */
    /* bottom: -100px; */
  }

  /* Text Section */
  .text-section5 {
    width: 100%;
    text-align: start;
  }

  .brand-name5 {
    width: 70% !important;
    font-family: 'Brush Script MT';
    font-size: 55px;
    text-align: center;
    font-weight: 400;
    color: #3a0070;
    margin-bottom: 10px;
    position: absolute;
    top: 10px;
  }

  .section-title5 {
    color: #000;
    text-align: center !important;
    font-family: "Playfair Display";
    font-size: 25px !important; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    position: absolute;
    margin-top: -1064px;
  }

  .section-description5 {
    margin-top: -500px;
    width: 90%;
    height: 231.974px;
    flex-shrink: 0;
    color: #000;
    text-align: center !important;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  

}
.png-logoseacc {
  width: 10.4px;
  height: 60.8px;
  border-radius: '4px 0px 0px 4px';
  display: 'flex';
  align-self: 'start';
  justify-content: 'start';
}

.ic {
  width: 30px;
}

.MuiBox-root.css-0 {
  margin-top: 80px;
}

.div1bgcolor {
  background-color: #fff;
}
.right {
  width: 772.888px;
  height: 479.03px;
  line-height: normal;
  /* 130px - 20% */
}

.btn-text-active {
  color: #000;
  font-family: "Playfair Display";
  font-size: 18px;
  margin-left: 15px;

}

.btn-text-inactive {
  color: #000;
  font-family: "Playfair Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

margin-left: 15px;
  margin-left: 15px;
}


/* Laptops/Desktops (1025px to 1440px) */
@media (min-width: 1209px) and (max-width: 5500px) {

  .ic {
      width: 25px;
  }
  .png-logoseacc {
      width: 15.4px;
      height: 75.24px;
      height: 48.64px;
  }


  .btn-text-active {
      color: #000;
      font-family: "Playfair Display";
      font-size: 18px;
      text-align: start;
      position: absolute;
      margin-left: 20px;
  
  }

  .btn-text-inactive {
      color: #000;
      font-family: "Playfair Display";
      font-size: 18px;
  }

}
@media screen and (max-width: 1208px) {
  .MuiBox-root {
      display: flex;
      flex-direction: column;
      top: 20px;
  }

  .ic {
      width: 25px;
  }
  .png-logoseacc {
      width: 15.4px;
      height: 75.24px;
      height: 48.64px;
  }


  .btn-text-active {
      color: #000;
      font-family: "Playfair Display";
      font-size: 18px;
      text-align: start;
      position: absolute;
      margin-left: 20px;
  
  }

  .btn-text-inactive {
      color: #000;
      font-family: "Playfair Display";
      font-size: 18px;
  }
}
/* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {

  .ic {
      width: 20px;
  }
  .png-logoseacc {
      width: 7.28px;
      /* 10.4px - 30% */
  }
  .btn:hover {
      background-color: #f0f0f0;
  }

  .svg2img {
      margin-top: 3px;
      width: 20px;
  }

  .btn-text-active {
      color: #000;
      font-family: "Poppins";
      font-size: 18px;
      line-height: normal;
      position: absolute;
      margin-left: 10px;
  
  }

  .btn-text-inactive {
      color: #000;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
      
  }
  }

  /* Small Laptops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .Bgofallwebvector{
    height: 1700px !important;
  }
  .section5bgimg {
    /* background-image: url(../src/images/section5bg.png); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 620px;
}
.divlogos {
  width: 197.677px;
  height: 98.899px;
  flex-shrink: 0;
  background-color: transparent;
  opacity: 0.3;
  margin-left: -156.84px;
  margin-top: -100px;
}
.h1ofhead2{
  margin-top: 9%;
}
.left {
  display: inline;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btnofselectofsec2 {
  width: 390px !important;
  height: 75.24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-left: 0px;
  border-radius: 5px 0px 0px 5px;
  background-color: #fff;
  cursor: pointer;
}

.btn-simple {
  width: 390px !important;
  height: 75.24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-left: 0px;
  background-color: transparent !important;
  cursor: pointer;
}

.btn-simple {
  color: #000;
  font-family: "Playfair Display";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-text-active {
  color: #000;
  font-family: 'Poppins';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  margin-left: 10px;

}

.btn-text-inactive {
  color: #000;
  font-family: 'Poppins';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.btn-icon {
  margin-right: -10px;
}

.btn-textofdiv1file {
  color: #000;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 130px;
}

.btn-arrow {
  margin-right: 40px;
}

.btn:hover {
  background-color: #f0f0f0;
}

.section8bg {
  background-image: url('./images/sec3bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.firstdivofSection8 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);

}

.firstSubdivofSection8 {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Aligns items to the left */
  justify-content: left;
}

.oneTextFieldsection8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.h5ofsection8 {
  text-align: start;
}

.h1ofsection8medask {
  color: #28156D;
}

.divh1ofsection8 {
  margin: 30px 0;
}

.right {
  width: 480px !important;
  height: 680px !important;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column-reverse;
  fill: #FFF;
  stroke: #D4D1CD;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.logo {
  margin-bottom: 10px;
  margin-left: 20px;
  width: 62.806px;
  height: 62.806px;
  flex-shrink: 0;
  fill: #FFF;

}

.h3ofdiv {
  color: #000;
  font-family: "Playfair Display";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  margin-bottom: 50px;
}

.p3 {
  width: 450px !important;
  height: 130px;
  flex-shrink: 0;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  margin-left: 20px;
}

.btn3 {
  color: #00A539;
  font-family: Satoshi;
  font-size: 14.984px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 2.997px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  margin-bottom: 30px;
}

.doc {
  width: 480px !important;
  height: 340px !important;
  border: none;
  border-radius: 10px;
  background: url('./images/doctor.png') lightgray 40% / cover no-repeat;
}

.MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.css-1qm1lh {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.css-19kzrtu {
  padding: 0px;
}

.MuiBox-root.css-19kzrtu {
  padding: 0%;
}

#tab-0.btn {
  padding: 0%;
}

#tab-1.btn {
  padding: 0%;
}

#tab-2.btn {
  padding: 0%;
}

#tab-3.btn {
  padding: 0%;
}
}
/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .Bgofallwebvector{
    height: 1700px !important;
  }
  .section5bgimg {
    /* background-image: url(../src/images/section5bg.png); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 620px;
}
.divlogos {
  width: 197.677px;
  height: 98.899px;
  flex-shrink: 0;
  background-color: transparent;
  opacity: 0.3;
  margin-left: -156.84px;
  margin-top: -100px;
}
.h1ofhead2{
  margin-top: 11%;
}
.left {
  display: inline;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btnofselectofsec2 {
  width: 390px !important;
  height: 75.24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-left: 0px;
  border-radius: 5px 0px 0px 5px;
  background-color: #fff;
  cursor: pointer;
}

.btn-simple {
  width: 390px !important;
  height: 75.24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-left: 0px;
  background-color: transparent !important;
  cursor: pointer;
}

.btn-simple {
  color: #000;
  font-family: "Playfair Display";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-text-active {
  color: #000;
  font-family: 'Poppins';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  margin-left: 10px;

}

.btn-text-inactive {
  color: #000;
  font-family: 'Poppins';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.btn-icon {
  margin-right: -10px;
}

.btn-textofdiv1file {
  color: #000;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 130px;
}

.btn-arrow {
  margin-right: 40px;
}

.btn:hover {
  background-color: #f0f0f0;
}

.section8bg {
  background-image: url('./images/sec3bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.firstdivofSection8 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);

}

.firstSubdivofSection8 {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Aligns items to the left */
  justify-content: left;
}

.oneTextFieldsection8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.h5ofsection8 {
  text-align: start;
}

.h1ofsection8medask {
  color: #28156D;
}

.divh1ofsection8 {
  margin: 30px 0;
}

.right {
  width: 480px !important;
  height: 550px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  fill: #FFF;
  stroke: #D4D1CD;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.logo {
  margin-bottom: 10px;
  margin-left: 20px;
  width: 62.806px;
  height: 62.806px;
  flex-shrink: 0;
  fill: #FFF;

}

.h3ofdiv {
  color: #000;
  font-family: "Playfair Display";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  margin-bottom: 50px;
}

.p3 {
  width: 450px !important;
  height: 130px;
  flex-shrink: 0;
  color: #000;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  margin-left: 20px;
}

.btn3 {
  color: #00A539;
  font-family: Satoshi;
  font-size: 14.984px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 2.997px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  margin-bottom: 30px;
}

.doc {
  width: 480px !important;
  height: 340px !important;
  border: none;
  border-radius: 10px;
  background: url('./images/doctor.png') lightgray 40% / cover no-repeat;
}

.MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.css-1qm1lh {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.css-19kzrtu {
  padding: 0px;
}

.MuiBox-root.css-19kzrtu {
  padding: 0%;
}

#tab-0.btn {
  padding: 0%;
}

#tab-1.btn {
  padding: 0%;
}

#tab-2.btn {
  padding: 0%;
}

#tab-3.btn {
  padding: 0%;
}
}

/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .Bgofallwebvector{
    height: 1700px !important;
  }
  .section5bgimg {
    /* background-image: url(../src/images/section5bg.png); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 620px;
}
.divlogos {
  width: 197.677px;
  height: 98.899px;
  flex-shrink: 0;
  background-color: transparent ;
  opacity: 0.3;
  margin-left: -156.84px;
  margin-top: -100px;
}
.h1ofhead2{
  margin-top: 30%;
}
.left {
  display: inline;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btnofselectofsec2 {
  width: 290px;
  height: 75.24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-left: 0px;
  border-radius: 5px 0px 0px 5px;
  background-color: #fff;
  cursor: pointer;
}

.btn-simple {
  width: 290px !important;
  height: 75.24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-left: 0px;
  background-color: transparent !important;
  cursor: pointer;
}

.btn-simple {
  color: #000;
  font-family: "Playfair Display";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-text-active {
  color: #000;
  font-family: 'Poppins';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  margin-left: 10px;

}

.btn-text-inactive {
  color: #000;
  font-family: 'Poppins';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.btn-icon {
  margin-right: -10px;
}

.btn-textofdiv1file {
  color: #000;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 130px;
}

.btn-arrow {
  margin-right: 40px;
}

.btn:hover {
  background-color: #f0f0f0;
}

.section8bg {
  background-image: url('./images/sec3bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.firstdivofSection8 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);

}

.firstSubdivofSection8 {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Aligns items to the left */
  justify-content: left;
}

.oneTextFieldsection8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.h5ofsection8 {
  text-align: start;
}

.h1ofsection8medask {
  color: #28156D;
}

.divh1ofsection8 {
  margin: 30px 0;
}

.right {
  width: 290px !important;
  height: 479.03px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  fill: #FFF;
  stroke: #D4D1CD;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.dd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.logo {
  margin-bottom: 10px;
  margin-left: 20px;
  width: 62.806px;
  height: 62.806px;
  flex-shrink: 0;
  fill: #FFF;

}

.h3ofdiv {
  color: #000;
  font-family: "Playfair Display";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  margin-bottom: 50px;
}

.p3 {
  width: 270px !important;
  height: 130px;
  flex-shrink: 0;
  color: #000;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  margin-left: 20px;
}

.btn3 {
  color: #00A539;
  font-family: Satoshi;
  font-size: 14.984px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 2.997px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-left: 20px;
  display: flex;
  margin-bottom: 30px;
}

.doc {
  width: 290px !important;
  height: 428.87px;
  border: none;
  border-radius: 10px;
  background: url('./images/doctor.png') lightgray 40% / cover no-repeat;
}

.MuiBox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.css-1qm1lh {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.css-19kzrtu {
  padding: 0px;
}

.MuiBox-root.css-19kzrtu {
  padding: 0%;
}

#tab-0.btn {
  padding: 0%;
}

#tab-1.btn {
  padding: 0%;
}

#tab-2.btn {
  padding: 0%;
}

#tab-3.btn {
  padding: 0%;
}
}
